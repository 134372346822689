import React from "react";
import { v4 as uuidv4 } from "uuid";
import TreeItem from "@mui/lab/TreeItem";
import { Stack, Chip, Box } from "@mui/material";
import CopyButton from "./CopyButton";
import NetworkAdapterShow from "./deviceShow/NetworkAdapterShow";
import { useTranslation } from "react-i18next";
import SecurityShow from "./deviceShow/SecurityShow";
import { Link as RouterLink } from "react-router-dom";

const Item = ({ name, value, access }) => {
	const { t } = useTranslation();
	return (
		<div className="tree-node-item">
			<Stack direction="row" spacing={5}>
				<Box sx={{ flexGrow: 1, fontStyle: "italic", color: "#6F7E8C" }}>
					{t(name)}
				</Box>
				{access ? <Chip variant="outline" size="small" label={access} /> : ""}
				<CopyButton valueToCopy={JSON.stringify(value)} />
			</Stack>
			{JSON.stringify(value)}{" "}
		</div>
	);
};

const OnlyTitleItem = ({ name, access, value }) => {
	const { t } = useTranslation();
	return (
		<Stack direction="row" spacing={5}>
			<Box sx={{ flexGrow: 1, fontStyle: "italic", color: "#6F7E8C" }}>
				{t(name)}{" "}
			</Box>{" "}
			{access ? <Chip variant="outline" size="small" label={access} /> : ""}
			<CopyButton valueToCopy={JSON.stringify(value)} />
		</Stack>
	);
};

const ItemLink = ({ name, value, access }) => {
	const { t } = useTranslation();
	return (
		<div className="tree-node-item">
			<Stack direction="row" spacing={5}>
				<Box sx={{ flexGrow: 1, fontStyle: "italic", color: "#6F7E8C" }}>
					{t(name)}
				</Box>
				{access ? <Chip variant="outline" size="small" label={access} /> : ""}
				<CopyButton valueToCopy={JSON.stringify(value)} />
			</Stack>
			{value ? (
				<RouterLink to={`/devices/${value.replaceAll('"', "")}`}>
					{JSON.stringify(value)}
				</RouterLink>
			) : (
				<>"-"</>
			)}
		</div>
	);
};

const OnlyValueItem = ({ value }) => {
	const { t } = useTranslation();
	return <div className="tree-node-item">{value}</div>;
};
const OnlyLinkItem = ({ value }) => {
	const { t } = useTranslation();

	return (
		<div className="tree-node-item">
			<RouterLink to={`/devices/${value.replaceAll('"', "")}`}>
				{value}
			</RouterLink>
		</div>
	);
};
const TreeNode = ({ name, value, type, access, attributes }) => {
	switch (name) {
		case "network_adapters":
			return (
				<TreeItem
					nodeId={uuidv4()}
					label={<OnlyTitleItem name={name} access={access} value={value} />}
				>
					<NetworkAdapterShow
						name={name}
						access={access}
						networkAdapters={value}
						activeNetworkAdapters={attributes.fields.active_network_adapters}
					/>
				</TreeItem>
			);

		case "security":
			return (
				<TreeItem
					nodeId={uuidv4()}
					label={<OnlyTitleItem name={name} access={access} value={value} />}
				>
					<SecurityShow name={name} access={access} security={value} />
				</TreeItem>
			);

		case "parent":
			return (
				<TreeItem
					nodeId={uuidv4()}
					label={<OnlyTitleItem name={name} access={access} value={value} />}
				>
					{value === "-" ? (
						JSON.stringify(value)
					) : (
						<TreeItem
							key={uuidv4()}
							nodeId={uuidv4()}
							label={<OnlyLinkItem value={JSON.stringify(value)} />}
						/>
					)}
				</TreeItem>
			);
		case "digital_twin_instance_id":
			return (
				<TreeItem
					nodeId={uuidv4()}
					label={<ItemLink name={name} access={access} value={value} />}
				></TreeItem>
			);
		case "active_network_adapters":
			return <></>;
		default:
			if (value && typeof value === "object" && !Array.isArray(value)) {
				const key = uuidv4();
				return (
					<TreeItem
						key={key}
						nodeId={key}
						label={<OnlyTitleItem name={name} access={access} value={value} />}
					>
						{Object.keys(value).map((keyIndex) => (
							<TreeNode name={keyIndex} value={value[keyIndex]} />
						))}
					</TreeItem>
				);
			}
			if (Array.isArray(value)) {
				if (value.length === 0) {
					return (
						<TreeItem
							nodeId={uuidv4()}
							label={<Item name={name} access={access} value={[]} />}
						/>
					);
				}
				return (
					<TreeItem
						nodeId={uuidv4()}
						label={<OnlyTitleItem name={name} access={access} value={value} />}
					>
						{value.map((v) => {
							if (v && typeof v === "object" && !Array.isArray(v)) {
								return (
									<Box sx={{ border: 1, margin: 1, borderColor: "grey.500" }}>
										{Object.keys(v).map((k) => (
											<TreeNode key={uuidv4()} name={k} value={v[k]} />
										))}
									</Box>
								);
							} else {
								return (
									<TreeItem
										key={uuidv4()}
										nodeId={uuidv4()}
										label={<OnlyValueItem value={JSON.stringify(v)} />}
									/>
								);
							}
						})}
					</TreeItem>
				);
			} else {
				return (
					<TreeItem
						nodeId={uuidv4()}
						label={
							<Item access={access} type={type} name={name} value={value} />
						}
					></TreeItem>
				);
			}
	}
};
export default TreeNode;
