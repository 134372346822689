import { useEffect, useState } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setUpdating, setStatistic } from "../../redux/slices/statisticsSlice";
import IconButton from "@mui/material/IconButton";
import GroupsSelector from "./GroupsSelector";
import { useUpdateStatisticMutation } from "../../redux/slices/statisticApi";
import ParametersHandler from "./ParametersHandler";
import SqlHighliter from "./SqlHighliter";
import { useLazyGetStatisticQuery } from "../../redux/slices/statisticApi";
import { Box } from "@material-ui/core";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const StatisticShow = () => {
  const dispatch = useDispatch();
  const [updateStatistic, { isSuccess: updateSuccess }] =
    useUpdateStatisticMutation();
  const statistic = useSelector((state) => state?.statisticsSlice?.statistic);
  const [getStatistic] = useLazyGetStatisticQuery();
  const { id } = useParams();
  useEffect(() => {
    getStatistic(id);
  }, []);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const connectionTypeEnum = [{ const: "Postgres" }];
  const p = [{ const: "Postgress" }, { const: "popa" }];
  useEffect(() => {
    debugger;
    dispatch(setUpdating(true));
    return () => {
      dispatch(setUpdating(false));
      dispatch(setStatistic(null));
    };
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [updateSuccess]);
  const typeEnum = [{ const: "raw_sql" }];
  const schema = {
    title: "New statistic",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: statistic?.name,
      },
      description: {
        id: 1,
        type: "string",
        title: t("description"),
        default: statistic?.description,
      },
      connection_type: {
        id: 2,
        type: "string",
        title: t("connection_type"),
        oneOf: [...connectionTypeEnum],
        default: statistic?.connection_type,
      },
      type: {
        id: 3,
        type: "string",
        title: t("type"),
        oneOf: [...typeEnum],
        default: statistic?.type,
      },
      sql: {
        id: 4,
        type: "string",
        title: t("sql"),
        default: statistic?.sql,
      },
      connection_parameters: {
        id: 5,
        type: "object",
        title: t("connection_parameters"),
      },
      groups: {
        id: 6,
        type: "string",
        title: t("groups"),
      },
    },
    /*  required: [
      "name",
      "description",
      "connection_type",
      "type",
      "sql",
      "connection_parameters",
      "groups",
    ], */
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },

    sql: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    connection_parameters: {
      "ui:field": "parameters",
    },
    groups: {
      "ui:field": "gropus",
    },
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    "ui:order": [
      "name",
      "type",
      "groups",
      "connection_type",
      "description",
      "sql",
      "connection_parameters",
    ],
  };
  const fields = {
    parameters: ParametersHandler,
    gropus: GroupsSelector,
  };
  const onSubmitClick = (val) => {
    console.log("statistic vals", val);
  };
  const isObjectEmpty = (objectName) => {
    return (
      Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  };
  const customValidate = (formData, errors) => {
    if (!formData?.name) {
      errors?.name?.addError("is a required property");
    }
    if (!formData?.description) {
      errors?.description?.addError("is a required property");
    }
    if (!formData?.sql) {
      errors?.sql?.addError("is a required property");
    }
    if (!formData?.connection_type) {
      errors?.connection_type?.addError("is a required property");
    }
    if (!formData?.type) {
      errors?.type?.addError("is a required property");
    }
    if (formData?.groups?.length < 1) {
      errors?.groups?.addError("is a required property");
    }
    if (isObjectEmpty(formData?.connection_parameters)) {
      errors?.connection_parameters?.addError("is a required property");
    }

    return errors;
  };
  return (
    <>
      {/* <SqlHighliter /> */}
      {statistic && (
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          customValidate={customValidate}
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={(values) => {
            updateStatistic({ attributes: values.formData, id: statistic.id });
          }}
          validator={validator}
          noHtml5Validate
          uiSchema={uiSchema}
        />
      )}
    </>
  );
};
export default StatisticShow;
