import { createSlice } from "@reduxjs/toolkit";
import { trilliantApi } from "./trilliantApi";

const initialState = {
  meters: null,
  count: null,
};
const trilliantSlice = createSlice({
  name: "trilliantSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      trilliantApi.endpoints.getTrilliantMeters.matchFulfilled,
      (state, action) => {
        state.meters = action.payload.data.attributes.data;
        state.count = action.payload.data.attributes.meta.count;
      }
    );
  },
});
export default trilliantSlice.reducer;
