import { actions } from "./authSlice";
import { meterApi } from "./meterApi";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  commands: null,
};

const meterSlice = createSlice({
  name: "meterSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      meterApi.endpoints.getCommands.matchFulfilled,
      (state, action) => {
        state.commands = action.payload.data.filter(
          (item) => item.attributes.access === "action"
        );
      }
    );
  },
});

export default meterSlice.reducer;
