import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { meterReadingGraphApi } from "./meterReadingGraphApi";

const initialState = { data: null };

const meterReadingSlice = createSlice({
  name: "plugiSlice",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    clearData(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      meterReadingGraphApi.endpoints.getMeterReadingGraph.matchFulfilled,
      (state, action) => {
        state.data = action.payload.reverse();
      }
    );
  },
});
export const { setData, clearData } = meterReadingSlice.actions;

export default meterReadingSlice.reducer;
