import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import DraftsIcon from "@mui/icons-material/Drafts";
import TreeView from "@mui/lab/TreeView";
import MuiDrawer from "@mui/material/Drawer";
import WidgetsIcon from "@mui/icons-material/Widgets";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AlarmIcon from "@mui/icons-material/Alarm";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import Divider from "@mui/material/Divider";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import TreeItem from "@mui/lab/TreeItem";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import RadarIcon from "@mui/icons-material/Radar";
import ExploreIcon from "@mui/icons-material/Explore";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import { v4 as uuidv4 } from "uuid";
import BoltIcon from "@mui/icons-material/Bolt";
import FolderIcon from "@mui/icons-material/Folder";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SpeedIcon from "@mui/icons-material/Speed";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import MapIcon from "@mui/icons-material/Map";
import { setHeaderAppBar } from "../redux/slices/utilsSlice";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import SecurityIcon from "@mui/icons-material/Security";
import CampaignIcon from "@mui/icons-material/Campaign";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import KeyIcon from "@mui/icons-material/Key";
import { useDispatch, useSelector } from "react-redux";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import MailIcon from "@mui/icons-material/Mail";
import { useSignoutMutation } from "../redux/slices/authApi";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Email } from "@material-ui/icons";
import { actions } from "../redux/slices/authSlice";
import { WindowSharp } from "@mui/icons-material";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import InsightsIcon from "@mui/icons-material/Insights";
import AppsIcon from "@mui/icons-material/Apps";

const drawerWidth = 260;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0",
  [theme.breakpoints.up("sm")]: {
    width: `0`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const logged = useSelector((state) => state?.authSlice?.logged);
  const [signout, { isSuccess, data: logoutData }] = useSignoutMutation();
  const [logo, setLogo] = useState("");
  const redyForSignout = useSelector((state) => state?.authSlice?.signout);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useAuth();
  const location = useLocation();
  let navigate = useNavigate();
  /*  const [open, setOpen] = useState(false); */
  const open = useSelector((state) => state?.utilsSlice?.headerAppBarOpen);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([]);
  useEffect(() => {
    if (redyForSignout) {
      signout();
    }
  }, [redyForSignout]);
  useEffect(() => {
    if (isSuccess) {
      debugger;
      window.location.replace(
        `${logoutData.logout_url}?id_token_hint=${logoutData.id_token}&post_logout_redirect_uri=${window.LOGOUT_URL}`
      );
    }
  }, [isSuccess]);

  const [items] = useState({
    devicesUUID: uuidv4(),
    digitalTwinsUUID: uuidv4(),
    groupsUUID: uuidv4(),
    districtsUUID: uuidv4(),
    codecsUUID: uuidv4(),
    aegisUUID: uuidv4(),
    usersUUID: uuidv4(),
    securityProvider: uuidv4(),
    userGroups: uuidv4(),
    middleware: uuidv4(),
    networkAdapters: uuidv4(),
    applicationDesignUUID: uuidv4(),
    developmentUUID: uuidv4(),
    applicationDesign: uuidv4(),
    custumerEndpoints: uuidv4(),
    dashboard: uuidv4(),
    meters: uuidv4(),
    metersList: uuidv4(),
    createMeter: uuidv4(),
    groups: uuidv4(),
    gis: uuidv4(),
    importExport: uuidv4(),
    alarm: uuidv4(),
    network: uuidv4(),
    dex: uuidv4(),
    userManagment: uuidv4(),
    customerConfig: uuidv4(),
    campaign: uuidv4(),
    createCampaign: uuidv4(),
    campaignList: uuidv4(),
    position: uuidv4(),
    positionList: uuidv4(),
    createPostition: uuidv4(),
    users: uuidv4(),
    createUser: uuidv4(),
    userList: uuidv4(),
    import: uuidv4(),
    importAdmin: uuidv4(),
    importReadings: uuidv4(),
    export: uuidv4(),
    exportRedings: uuidv4(),
    exportAdmin: uuidv4(),
    synteticData: uuidv4(),
    synteticCampaignList: uuidv4(),
    campaignGenerator: uuidv4(),
    districtMetering: uuidv4(),
    districtSetup: uuidv4(),
    districtImport: uuidv4(),
    districtExport: uuidv4(),
    manageLPWAN: uuidv4(),
    eventsAlarms: uuidv4(),
    messages: uuidv4(),
    livMessages: uuidv4(),
    groupCreate: uuidv4(),
    groupList: uuidv4(),
    group: uuidv4(),
    dataServices: uuidv4(),
    statistics: uuidv4(),
    statisticList: uuidv4(),
    createStatistic: uuidv4(),
    widgets: uuidv4(),
    widgetList: uuidv4(),
    widgetCreate: uuidv4(),
    addLPWAN: uuidv4(),
    exportReadings: uuidv4(),
    exportAdmin: uuidv4(),
    digitalTwins: uuidv4(),
    digitalTwinList: uuidv4(),
    connections: uuidv4(),
    connectionsList: uuidv4(),
    connectionCreate: uuidv4(),
    trilliant: uuidv4(),
  });

  const handleDrawerOpen = () => {
    dispatch(setHeaderAppBar());
  };

  const handleDrawerClose = () => {
    dispatch(setHeaderAppBar());
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const menuItems = [
    {
      name: "groups",
      expanded: [items.digitalTwinsUUID, items.groupsUUID],
      selected: items.groupsUUID,
      children: [
        {
          name: "list_groups",
          expanded: [items.digitalTwinsUUID, items.groupsUUID],
          selected: null,
          to: "/groups",
        },
        {
          name: "create_groups",
          expanded: [items.digitalTwinsUUID, items.groupsUUID],
          selected: null,
          to: "/groups/create",
        },
      ],
    },
    {
      name: "devices",
      expanded: [items.digitalTwinsUUID, items.devicesUUID],
      selected: items.devicesUUID,
      children: [
        {
          name: "list_devices",
          expanded: [items.digitalTwinsUUID, items.devicesUUID],
          selected: null,
          to: "/devices",
        },
        {
          name: "create_devices",
          expanded: [items.digitalTwinsUUID, items.devicesUUID],
          selected: null,
          to: "/devices/create",
        },
      ],
    },
    {
      name: "districts",
      expanded: [items.digitalTwinsUUID, items.districtsUUID],
      selected: items.districtsUUID,
      children: [
        {
          name: "list_districts",
          expanded: [items.digitalTwinsUUID, items.districtsUUID],
          selected: null,
          to: "/districts",
        },
        {
          name: "create_districts",
          expanded: [items.digitalTwinsUUID, items.districtsUUID],
          selected: null,
          to: "/districts/create",
        },
      ],
    },
    {
      name: "middleware",
      expanded: [items.digitalTwinsUUID, items.codecsUUID],
      selected: items.codecsUUID,
      children: [
        {
          name: "list_middleware",
          expanded: [items.digitalTwinsUUID, items.codecsUUID],
          selected: null,
          to: "/codecs",
        },
        {
          name: "create_codecs",
          expanded: [items.digitalTwinsUUID, items.codecsUUID],
          selected: null,
          to: "/codecs/create",
        },
      ],
    },
  ];

  const resolveActive = () => {
    const item = findLocation(menuItems, location);
  };

  const findLocation = (list, location) => {
    return list.find((item) => {
      if (item.children) {
        const result = findLocation(item.children, location);
        if (result) {
          setExpanded(result.expanded);
          setSelectedMenu(result);
          setSelected(result.selected);
          return result;
        }
      }
      return item.to === location.pathname;
    });
  };

  useEffect(() => {
    resolveActive();
  }, [location]);
  const path = window.location.href;

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box display={"flex"} sx={{ flexGrow: 1 }} alignItems="center">
            <img
              style={{
                height: "30px",
                marginRight: 20,
                marginTop: 5,
              }}
              src={window.LOGO}
            />
            <Typography variant="h6" noWrap component="div">
              Swarm
            </Typography>
          </Box>

          {!auth.user() && (
            <Button color="inherit" to="/login" component={RouterLink}>
              Login
            </Button>
          )}
          {auth.user() && (
            <div>
              {t("welcome")} {auth.user().display_name}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => dispatch(actions.signout())}>
                  {t("logout")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/me");
                    handleClose();
                  }}
                >
                  {t("myAccount")}
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <TreeView
          aria-label="file system navigator"
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          sx={{ height: 240, flexGrow: 1, maxWidth: 410, overflowY: "auto" }}
        >
          <List>
            <TreeItem
              sx={{ mb: 1 }}
              nodeId={items.dashboard}
              label={
                <ListItem
                  sx={{ paddingLeft: 0 }}
                  button
                  /*  component={RouterLink}
                  to="/metrics" */
                  onClick={() => (window.location.href = "/metrics")}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("dashboard")} />
                </ListItem>
              }
            />
            <TreeItem
              nodeId={items.devicesUUID}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("devices")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.meters}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <GasMeterIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("meters")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.trilliant}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/trilliant"
                    >
                      <ListItemIcon>
                        <AssessmentIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("Trilliant")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.metersList}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/meters"
                    >
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("meters_list")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.createMeter}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/meters/create"
                    >
                      <ListItemIcon>
                        <AddCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("meters_create")} />
                    </ListItem>
                  }
                />
              </TreeItem>
              <TreeItem
                nodeId={items.import}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <ArrowDownwardIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("data_import")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.importAdmin}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      /*  to="/meters" */
                    >
                      <ListItemIcon>
                        <AdminPanelSettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("administrative")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.importReadings}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/import"
                      /*     onClick={() => (window.location.href = "/import")} */
                    >
                      <ListItemIcon>
                        <AutoStoriesIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("meters_readings")} />
                    </ListItem>
                  }
                />
              </TreeItem>
              <TreeItem
                nodeId={items.export}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <ArrowUpwardIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("data_export")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.exportAdmin}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                    >
                      <ListItemIcon>
                        <AdminPanelSettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("administrative")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.exportReadings}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      /* to="/meters/create" */
                    >
                      <ListItemIcon>
                        <AutoStoriesIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("meters_readings")} />
                    </ListItem>
                  }
                />
              </TreeItem>
            </TreeItem>
            <TreeItem
              nodeId={items.campaign}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <CampaignIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("campaign")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.synteticData}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("synthetic_data")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.campaignList}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/campaigns"
                    >
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("campaign_list")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.createCampaign}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/campaign/create"
                    >
                      <ListItemIcon>
                        <BoltIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("campaign_generator")} />
                    </ListItem>
                  }
                />
              </TreeItem>
              <TreeItem
                nodeId={items.districtMetering}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <ElectricMeterIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("district_metering")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.districtSetup}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      /*  to="/meters" */
                    >
                      <ListItemIcon>
                        <BrightnessLowIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("district_setup")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.districtImport}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      /* to="/meters/create" */
                    >
                      <ListItemIcon>
                        <ArrowDownwardIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("district_import")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.districtExport}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      /* to="/meters/create" */
                    >
                      <ListItemIcon>
                        <ArrowUpwardIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("district_export")} />
                    </ListItem>
                  }
                />
              </TreeItem>
            </TreeItem>
            <TreeItem
              nodeId={items.digitalTwins}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button component={RouterLink}>
                  <ListItemIcon>
                    <MemoryOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("digital_twins")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.exportReadings}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/digitaltwins"
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("digital_twin_list")} />
                  </ListItem>
                }
              />
            </TreeItem>
            <TreeItem
              nodeId={items.users}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("users")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.userList}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/users"
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("user_list")} />
                  </ListItem>
                }
              />
              <TreeItem
                nodeId={items.createUser}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/user/create"
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("user_create")} />
                  </ListItem>
                }
              />
            </TreeItem>
            <TreeItem
              nodeId={items.groupsUUID}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <AppsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("groups")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.groupList}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/groups"
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("groups_list")} />
                  </ListItem>
                }
              />
              <TreeItem
                nodeId={items.groupCreate}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/groups/create"
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("groups_create")} />
                  </ListItem>
                }
              />
            </TreeItem>

            <TreeItem
              nodeId={items.districtsUUID}
              disabled
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <MapIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("districts")} />
                </ListItem>
              }
            >
              {/*   <List sx={{ paddingLeft: open ? 2 : 0 }}>
                <ListItem
                  selected={"/districts" === selectedMenu.to}
                  button
                  key={t("list_groups")}
                  to="/ddistricts"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("list_districts")} />
                </ListItem>
                <ListItem
                  selected={"/districts/create" === selectedMenu.to}
                  button
                  key={t("create_district")}
                  to="/device/create"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("create_district")} />
                </ListItem>
              </List> */}
              <Divider />
            </TreeItem>
            <TreeItem
              disabled
              nodeId={items.gis}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <FmdGoodIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("gis")} />
                </ListItem>
              }
            />
            {/*      <TreeItem
              disabled
              nodeId={items.importExport}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <ImportExportIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("import_export_CMPE")} />
                </ListItem>
              }
            /> */}
            <TreeItem
              disabled
              nodeId={items.alarm}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <AlarmIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("alarms")} />
                </ListItem>
              }
            />
            <TreeItem
              nodeId={items.network}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <RssFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("network")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.connections}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <LeakAddIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("connections")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.connectionsList}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/connections"
                    >
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("connection_list")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.connectionCreate}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/connections/create"
                    >
                      <ListItemIcon>
                        <AddCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("connection_create")} />
                    </ListItem>
                  }
                />
              </TreeItem>
              <TreeItem
                nodeId={items.manageLPWAN}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/gateways"
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("manage_lpwan")} />
                  </ListItem>
                }
              />
              <TreeItem
                nodeId={items.addLPWAN}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/gateways/create"
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("add_lpwan")} />
                  </ListItem>
                }
              />
              <TreeItem
                nodeId={items.eventsAlarms}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/messages"
                  >
                    <ListItemIcon>
                      <NotificationImportantIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("events_alarms")} />
                  </ListItem>
                }
              />
              <TreeItem
                nodeId={items.livMessages}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/messages/live"
                  >
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("live")} />
                  </ListItem>
                }
              />
            </TreeItem>
            <TreeItem
              nodeId={items.dataServices}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("data_services")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.dex}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    button
                    component={RouterLink}
                    to="/dex"
                  >
                    <ListItemIcon>
                      <ExploreIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("dex")} />
                  </ListItem>
                }
              />
              <TreeItem
                nodeId={items.statistics}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("statistics")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.statisticList}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/statistics"
                    >
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("manage")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.createStatistic}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/statistics/create"
                    >
                      <ListItemIcon>
                        <AddCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("add_statistic")} />
                    </ListItem>
                  }
                />
              </TreeItem>
              <TreeItem
                nodeId={items.widgets}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <WidgetsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("widgets")} />
                  </ListItem>
                }
              >
                <TreeItem
                  nodeId={items.widgetList}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/widgets"
                    >
                      <ListItemIcon>
                        <FormatListBulletedIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("manage")} />
                    </ListItem>
                  }
                />
                <TreeItem
                  nodeId={items.widgetCreate}
                  label={
                    <ListItem
                      sx={{ paddingLeft: 0 }}
                      button
                      component={RouterLink}
                      to="/widgets/create"
                    >
                      <ListItemIcon>
                        <AddCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={t("add_widget")} />
                    </ListItem>
                  }
                />
              </TreeItem>
            </TreeItem>

            {/*  <TreeItem
              disabled
              nodeId={items.userManagment}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("user_managment")} />
                </ListItem>
              }
            /> */}
            <TreeItem
              disabled
              nodeId={items.customerConfig}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <PermDataSettingIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("customer_config")} />
                </ListItem>
              }
            />

            {/*    <TreeItem
              nodeId={items.digitalTwinsUUID}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <ConnectWithoutContactIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("digital_twins")} />
                </ListItem>
              }
            >
              <TreeItem
                nodeId={items.devicesUUID}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("devices")} />
                  </ListItem>
                }
              >
                <List sx={{ paddingLeft: 0 }}>
                  <ListItem
                    button
                    selected={"/devices" === selectedMenu.to}
                    key={t("list_devices")}
                    to="/devices"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("list_devices")} />
                  </ListItem>
                  <ListItem
                    button
                    selected={"/devices/create" === selectedMenu.to}
                    key={t("create_device")}
                    to="/devices/create"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("create_device")} />
                  </ListItem>
                </List>
                <Divider />
              </TreeItem>
              <TreeItem
                nodeId={items.groupsUUID}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <WorkspacesIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("groups")} />
                  </ListItem>
                }
              >
                <List sx={{ paddingLeft: open ? 2 : 0 }}>
                  <ListItem
                    selected={"/groups" === selectedMenu.to}
                    button
                    key={t("list_groups")}
                    to="/groups"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("list_groups")} />
                  </ListItem>
                  <ListItem
                    selected={"/groups/create" === selectedMenu.to}
                    button
                    key={t("create_group")}
                    to="/groups/create"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("create_group")} />
                  </ListItem>
                </List>
                <Divider />
              </TreeItem>
              <TreeItem
                nodeId={items.districtsUUID}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <MapIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("districts")} />
                  </ListItem>
                }
              >
                <List sx={{ paddingLeft: open ? 2 : 0 }}>
                  <ListItem
                    selected={"/districts" === selectedMenu.to}
                    button
                    key={t("list_groups")}
                    to="/districts"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("list_districts")} />
                  </ListItem>
                  <ListItem
                    selected={"/districts/create" === selectedMenu.to}
                    button
                    key={t("create_district")}
                    to="/districts/create"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("create_district")} />
                  </ListItem>
                </List>
                <Divider />
              </TreeItem>
              <TreeItem
                nodeId={items.codecsUUID}
                label={
                  <ListItem sx={{ paddingLeft: 0 }} button>
                    <ListItemIcon>
                      <PlumbingIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("middlewares")} />
                  </ListItem>
                }
              >
                <List sx={{ paddingLeft: open ? 2 : 0 }}>
                  <ListItem
                    selected={"/network_adapter" === selectedMenu.to}
                    button
                    key={t("list_network_adapeters")}
                    to="/network_adapter"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <FormatListBulletedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("list_network_adapeters")} />
                  </ListItem>
                  <ListItem
                    selected={"/network_adapter/create" === selectedMenu.to}
                    button
                    key={t("create_codecs")}
                    to="/network_adapter/create"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("create_codecs")} />
                  </ListItem>
                </List>
                <Divider />
              </TreeItem>
              <TreeItem
                nodeId={"asdasd"}
                label={
                  <ListItem
                    sx={{ paddingLeft: 0 }}
                    selected={"/codecs" === selectedMenu.to}
                    button
                    key={t("dex")}
                    to="/dex"
                    component={RouterLink}
                  >
                    <ListItemIcon>
                      <SettingsSuggestIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("dex")} />
                  </ListItem>
                }
              ></TreeItem>
            </TreeItem> */}
          </List>
          {/*   <TreeItem
            nodeId={items.developmentUUID}
            label={
              <ListItem sx={{ paddingLeft: 0 }} button>
                <ListItemIcon>
                  <IntegrationInstructionsIcon />
                </ListItemIcon>
                <ListItemText primary={t("development")} />
              </ListItem>
            }
          >
            <TreeItem
              nodeId={items.applicationDesignUUID}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("application_design")} />
                </ListItem>
              }
            >
              <List sx={{ paddingLeft: 0 }}>
                <ListItem
                  button
                  selected={"/devices" === selectedMenu.to}
                  key={t("application_design")}
                  to="ade"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("application_design")} />
                </ListItem>
              </List>
              <Divider />
            </TreeItem>
          </TreeItem> */}
          {/*   <TreeItem
            nodeId={items.aegisUUID}
            label={
              <ListItem sx={{ paddingLeft: 0 }} button>
                <ListItemIcon>
                  <KeyIcon />
                </ListItemIcon>
                <ListItemText primary={t("aegis")} />
              </ListItem>
            }
          >
            <TreeItem
              nodeId={items.usersUUID}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <SupervisedUserCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("users")} />
                </ListItem>
              }
            >
              <List sx={{ paddingLeft: 0 }}>
                <ListItem
                  button
                  selected={"/users" === selectedMenu.to}
                  key={t("list")}
                  to="/users"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("list")} />
                </ListItem>
                <ListItem
                  button
                  selected={"/users/create" === selectedMenu.to}
                  key={t("create_users")}
                  to="/users/create"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("create")} />
                </ListItem>
              </List>
              <Divider />
            </TreeItem>
            <TreeItem
              nodeId={items.usersUUID}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <SecurityIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("security provider")} />
                </ListItem>
              }
            >
              <List sx={{ paddingLeft: 0 }}>
                <ListItem
                  button
                  selected={"/security_provider" === selectedMenu.to}
                  key={t("list")}
                  to="/security_provider"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("list")} />
                </ListItem>
                <ListItem
                  button
                  selected={"/security_provider/create" === selectedMenu.to}
                  key={t("security_provider")}
                  to="/security_provider/create"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("create")} />
                </ListItem>
              </List>
              <Divider />
            </TreeItem>
            <TreeItem
              nodeId={items.usersUUID}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("groups")} />
                </ListItem>
              }
            >
              <List sx={{ paddingLeft: 0 }}>
                <ListItem
                  button
                  selected={"/groups" === selectedMenu.to}
                  key={t("list")}
                  to="/groups"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("list")} />
                </ListItem>
                <ListItem
                  button
                  selected={"/groups/create" === selectedMenu.to}
                  key={t("groups")}
                  to="/groups/create"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("create")} />
                </ListItem>
              </List>
              <Divider />
            </TreeItem>
            <TreeItem
              nodeId={items.custumerEndpoints}
              label={
                <ListItem sx={{ paddingLeft: 0 }} button>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("custumer_endpoints")} />
                </ListItem>
              }
            >
              <List sx={{ paddingLeft: 0 }}>
                <ListItem
                  button
                  selected={"/custumer_endpoints" === selectedMenu.to}
                  key={t("list")}
                  to="/custumer_endpoints"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("list")} />
                </ListItem>
                <ListItem
                  button
                  selected={"/custumer_endpoints/create" === selectedMenu.to}
                  key={t("custumer_endpoints")}
                  to="/custumer_endpoints/create"
                  component={RouterLink}
                >
                  <ListItemIcon>
                    <AddCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("create")} />
                </ListItem>
              </List>
              <Divider />
            </TreeItem>
          </TreeItem> */}
        </TreeView>
        <Box sx={{ padding: 1, color: "#6F7E8C" }}>
          Version: {process.env.REACT_APP_VERSION}
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
