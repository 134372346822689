import React, { useEffect, useState } from "react";
import { Polygon, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "@bopen/leaflet-area-selection/dist/index.css";
import { useTranslation } from "react-i18next";
import { DrawAreaSelection } from "@bopen/leaflet-area-selection";
import { useDeviceDistrict } from "../DeviceDistrictProvider";

import DeviceSvg from "../../statics/icons/device.svg";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { polygon } from "@turf/turf";

const defaultLocation = [45.40667650588728, 11.879199098463717];
const DeviceDistrictMapComponent = () => {
  const { polygons, devices, drawPolygon, setDrawPolygon, setCurrentPolygon } =
    useDeviceDistrict();
  const { t } = useTranslation();

  const [location, setLocation] = useState(defaultLocation);
  const map = useMap();

  const deviceIcon = () =>
    new L.icon({
      iconUrl: DeviceSvg,
      iconSize: 20,
    });

  const onPolygonReady = (polygon) => {
    setCurrentPolygon(polygon);
  };

  let areaSelection;
  useEffect(() => {
    console.log("DRAWPO", drawPolygon);
  }, [drawPolygon]);
  useEffect(() => {
    if (
      map &&
      location == defaultLocation &&
      polygons &&
      polygons.length > 0 &&
      polygons[0].polygon.geom
    ) {
      const coords = polygons[0].polygon.geom.coordinates[0][0];
      map.flyTo(coords);
      setLocation(coords);
    }
  }, [polygons, map]);

  useEffect(() => {
    if (map && !drawPolygon && !areaSelection) {
      areaSelection = new DrawAreaSelection(
        {
          onPolygonReady: onPolygonReady,
        },
        false
      );
      map.addControl(areaSelection);
      setDrawPolygon(areaSelection);
    }
  }, [map]);
  useEffect(() => {
    console.log("devices", devices);
  }, [devices]);

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {devices && (
        <MarkerClusterGroup chunkedLoading maxClusterRadius={20}>
          {devices.data.map((device) => (
            <Marker
              icon={deviceIcon()}
              key={device.id}
              position={[
                device.attributes.coordinates.coordinates[1],
                device.attributes.coordinates.coordinates[0],
              ]}
            >
              <Popup>{device.attributes.serial_number}</Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      )}

      {polygons.map(({ pathOptions, positions }) => {
        return (
          <Polygon pathOptions={{ ...pathOptions }} positions={positions} />
        );
      })}
    </>
  );
};

export default DeviceDistrictMapComponent;
