import { api } from "./api";
import { prepareParams } from "../../utils/api_params";
import { prepareBody } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";
const deviceType = "device_district";

const preparePolygonBody = ({ positions }) => {
  return {
    data: {
      type: "device_polygon",
      attributes: {
        geom: {
          coordinates: [[...positions, positions[0]]],
          type: "Polygon",
        },
      },
    },
  };
};
export const deviceDistrictApi = api.injectEndpoints({
  endpoints: (build) => ({
    createDistrict: build.mutation({
      query: (attributes) => ({
        url: "v1/device_district",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, deviceType)),
      }),
    }),
    updateDistrict: build.mutation({
      query: ({ attributes, id }) => ({
        url: `v1/device_district/${id}`,
        method: "PATCH",
        body: JSON.stringify(prepareBody(attributes, id, deviceType)),
      }),
    }),
    createPolygon: build.mutation({
      query: ({ id, body }) => ({
        url: `v1/device_district/${id}/polygon`,
        method: "POST",
        body: JSON.stringify(preparePolygonBody(body)),
      }),
    }),
    getPolygonDevices: build.query({
      query: (item) => {
        return `v1/altior/device?in_polygon=${JSON.stringify(item)}`;
      },
    }),
    getDistrict: build.query({
      query: (id) => {
        return `v1/device_district/${id}`;
      },
    }),
    getPolygons: build.query({
      query: (id) => {
        return `v1/device_district/${id}/polygon`;
      },
    }),
    getDistricts: build.query({
      query: (params) => {
        return `v1/device_district?${prepareParams(params)}`;
      },
    }),
    deleteDistrict: build.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `v1/device_district/${id}`,
      }),
    }),
    deletePolygon: build.mutation({
      query: ({ districtId, polygonId }) => ({
        method: "DELETE",
        url: `v1/device_district/${districtId}/polygon/${polygonId}`,
      }),
    }),
    downloadDevices: build.mutation({
      query: (item) => {
        return {
          url: `api/v1/altior/device/export?in_polygon=${JSON.stringify(item)}`,
          method: "POST",
          responseHandler: async (response) =>
            window.location.assign(
              window.URL.createObjectURL(await response.blob())
            ),
        };
      },
      transformErrorResponse: (response) => {
        return response.data.attributes.status;
      },
    }),
  }),
});

export const {
  useCreateDistrictMutation,
  useCreatePolygonMutation,
  useLazyGetPolygonDevicesQuery,
  useLazyGetDistrictQuery,
  useLazyGetPolygonsQuery,
  useLazyGetDistrictsQuery,
  useDeleteDistrictMutation,
  useUpdateDistrictMutation,
  useDeletePolygonMutation,
  useDownloadDevicesMutation,
} = deviceDistrictApi;
