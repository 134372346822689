import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { secondsToDhms } from "../../utils/api_params";
const GatewayMessages = ({ page, setPage, perPage }) => {
  const messages = useSelector((state) => state?.gatewaySlice?.messages);
  const messagesMeta = useSelector(
    (state) => state?.gatewaySlice?.messagesMeta
  );

  const { t } = useTranslation();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "modem_mode",
      headerName: t("modem_mode"),
      type: "number",
      sortable: false,
      editable: true,
      flex: 1,
    },
    {
      field: "modem_operator",
      headerName: t("modem_operator"),
      type: "number",
      sortable: false,
      editable: true,
      flex: 2,
    },

    {
      field: "modem_rssi",
      headerName: t("modem_rssi"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
    },

    {
      field: "uptime",
      headerName: t("uptime"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "version_hw",
      headerName: t("version_hw"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "version_sw",
      headerName: t("version_sw"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "message_date",
      headerName: t("message_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
    },
    /*  {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 4,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton disabled sx={{ float: "right" }}>
              <DeleteIcon sx={{ ml: 2 }} />
            </IconButton>
            <Link to={`/gateways/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    }, */
  ];

  const tableRows = messages?.map((item, i) => ({
    id: i,
    modem_mode: item?.attributes?.values?.values.modem_mode,
    modem_operator: item?.attributes?.values?.values.modem_operator,
    modem_rssi: item?.attributes?.values?.values.modem_rssi,
    uptime: secondsToDhms(item?.attributes?.values?.values.uptime),
    version_hw: item?.attributes?.values?.values.version_hw,
    version_sw: item?.attributes?.values?.values.version_sw,
    message_date: t("dt", { val: new Date(item?.attributes?.message_date) }),
  }));
  return (
    <Box sx={{ height: "60vh", width: "99%" }}>
      <DataGrid
        disableColumnMenu
        rows={tableRows}
        columns={columns}
        rowCount={messagesMeta}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          /*  !response.isFetching &&  */
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        /*    getRowHeight={() => "auto"} */
      />
    </Box>
  );
};
export default GatewayMessages;
