import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";

import { useNavigate, useParams } from "react-router-dom";
import { useCustomerEndpoint } from "./CustomerEndpointProvider";
import Loader from "../Loader";
import CustomerEndpointForm from "./CustomerEndpointFrom";
import ApiError from "../ApiError";
import IwdSnackBar from "../IwdSnackBar";

const CustomerEndpointCreateComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { createCustomerEndpoint, error } = useCustomerEndpoint();

  return (
    <Box>
      {error ? <ApiError error={error}></ApiError> : <></>}

      <CustomerEndpointForm
        buttonLabel={t("create")}
        action={(formData) => {
          createCustomerEndpoint(formData, ({ action, data }) => {
            if (action == "created") {
              navigate(`/custumer_endpoints/${data.data.id}`);
            }
          });
        }}
      ></CustomerEndpointForm>
    </Box>
  );
};

export default CustomerEndpointCreateComponent;
