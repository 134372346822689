import React from "react";
import TreeView from '@mui/lab/TreeView';
import TreeNode from "./TreeNode";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const DeviceShowItem = ({
	instance: {
		data: { attributes },
	},
	name,
	access,
	type,
}) => {
	var value = attributes[name];
	if (!value) {
		value = attributes.fields[name] ? attributes.fields[name] : "-";
	}

	return (
		<TreeView 
		 defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
		>
			<TreeNode
				name={name}
				value={value}
				access={access}
				type={type}
				attributes={attributes}
			/>
		</TreeView>
	);
};

export default DeviceShowItem;
