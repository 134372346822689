import { useParams } from "react-router-dom";
import { useLazyGetConnectionQuery } from "../../redux/slices/connectionsApi";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { Box, Grid, Button } from "@mui/material";
import TrashModal from "../TrashModal";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import { setMessage } from "../../redux/slices/snackbarSlice";
import {
  useStartConnectionMutation,
  useStopConnectionMutation,
  useDeleteConnectionMutation,
} from "../../redux/slices/connectionsApi";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
const ConnectionsShow = () => {
  const dispatch = useDispatch();
  const [toggleStarted, setToggleStarted] = useState(false);
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const { t } = useTranslation();
  const [startConnection, { isSuccess: startSuccess }] =
    useStartConnectionMutation();
  const [stopConnection, { isSuccess: stopSuccess }] =
    useStopConnectionMutation();
  const [deleteConnection, { isSuccess: deleteSuccess }] =
    useDeleteConnectionMutation();
  const navigate = useNavigate();
  const connection = useSelector(
    (state) => state?.connectionsSlice?.connection
  )?.attributes;
  const { id } = useParams();
  const [getConnection] = useLazyGetConnectionQuery();
  useEffect(() => {
    if (id) {
      getConnection(id);
    }
  }, [id]);
  const onUpdateClick = () => {
    navigate("edit");
  };
  const onStartClick = () => {
    startConnection({ id: connection.id });
  };
  const onStopClick = () => {
    stopConnection({ id: connection.id, attributes: connection });
  };
  useEffect(() => {
    if (startSuccess) {
      dispatch(setMessage(t("strat_con_succ")));
      getConnection(id);
    }
  }, [startSuccess]);
  useEffect(() => {
    if (stopSuccess) {
      dispatch(setMessage(t("stop_con_succ")));
      getConnection(id);
    }
  }, [stopSuccess]);
  useEffect(() => {
    if (deleteConfirmed && toggleStarted) {
      stopConnection({ id: id });
    }
  }, [deleteConfirmed]);
  useEffect(() => {
    if (stopSuccess && toggleStarted) {
      debugger;
      setToggleStarted(false);
    }
  }, [stopSuccess, toggleStarted]);
  useEffect(() => {
    if (deleteConfirmed && !toggleStarted) {
      debugger;
      deleteConnection(connection.id);
    }
  }, [deleteConfirmed, toggleStarted]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(unsetConfirmed());
      navigate("/connections");
    }
  }, [deleteSuccess, toggleStarted]);
  const onTrashClick = () => {
    if (connection.started === true) {
      setToggleStarted(true);
    }
    dispatch(opendModal());
  };
  return (
    <>
      {connection && (
        <>
          <TrashModal
            title={t("delete_con_title")}
            text={t("delete_con_text")}
          />
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Typography>{t("id")} :</Typography>
              <Typography sx={{ mt: 1 }}>{connection.id}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("host")} :</Typography>
              <Typography sx={{ mt: 1 }}>{connection.host}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("inserted_at")} :</Typography>
              <Typography sx={{ mt: 1 }}>
                {t("dt", { val: new Date(connection.inserted_at) })}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("updated_at")} :</Typography>
              <Typography sx={{ mt: 1 }}>
                {t("dt", { val: new Date(connection.updated_at) })}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("login")} :</Typography>
              <Typography sx={{ mt: 1 }}>{connection.login}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("name")} :</Typography>
              <Typography sx={{ mt: 1 }}>{connection.name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("passcode")} :</Typography>
              <Typography sx={{ mt: 1 }}>{connection.passcode}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("port")} :</Typography>
              <Typography sx={{ mt: 1 }}>{connection.port}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("reconnect_timeout")} :</Typography>
              <Typography sx={{ mt: 1 }}>
                {connection.reconnect_timeout}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{t("started")} :</Typography>
              <Typography sx={{ mt: 1 }}>
                {connection.started ? (
                  <DoneIcon sx={{ fill: theme.palette.success.main }} />
                ) : (
                  <CloseIcon sx={{ fill: theme.palette.error.main }} />
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("actions")} :</Typography>
              <Box sx={{ mt: 1 }}>
                {connection?.started === true ? (
                  <Tooltip title={t("stop_conn")}>
                    <IconButton onClick={onStopClick}>
                      <StopIcon sx={{ fill: theme.palette.error.main }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("start_conn")}>
                    <IconButton onClick={onStartClick}>
                      <PlayArrowIcon
                        sx={{ fill: theme.palette.success.main }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton onClick={onTrashClick}>
                  <DeleteIcon sx={{ fill: theme.palette.error.main }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Button
            onClick={onUpdateClick}
            sx={{ float: "right", mt: 2 }}
            variant="contained"
          >
            Update connection
          </Button>
        </>
      )}
    </>
  );
};
export default ConnectionsShow;
