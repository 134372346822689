import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import DeviceGroupProvider from "../../components/DeviceGroupProvider";
import DeviceGroupEditForm from "../../components/deviceGroup/DeviceGroupEditForm";
import { useParams } from "react-router-dom";
import DeviceGroupSearchProvider from "../../components/deviceGroup/DeviceGroupSearchProvider";

const DeviceGroupEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <Box>
      <h2> {t("edit_group")} </h2>
      <Box sx={{ width: "100%" }}>
        <DeviceGroupSearchProvider>
          <DeviceGroupProvider>
            <DeviceGroupEditForm groupId={id} />
          </DeviceGroupProvider>
        </DeviceGroupSearchProvider>
      </Box>
    </Box>
  );
};

export default DeviceGroupEdit;
