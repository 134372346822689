import React, { useState, useEffect } from "react";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

const SocketStatus = ({ isConnected }) => {
	const { t } = useTranslation();
	if (isConnected) {
		return (
			<Tooltip title={t("socket_status")} arrow>
				<CloudIcon />
			</Tooltip>
		);
	}

	return (
		<Tooltip title={t("socket_status")} arrow>
			<CloudOffIcon />
		</Tooltip>
	);
};

export default SocketStatus;
