import { api } from "./api";
import { prepareBodyWithoutId } from "../../utils/api_params";
const CompaignsType = "campaings";
const positionType = "position";

export const CampaignApi = api.injectEndpoints({
  endpoints: (build) => ({
    postCampaign: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/campaigns",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, CompaignsType)),
      }),
    }),
    getIndexCampaign: build.query({
      query: () => {
        return "v1/plugin/campaigns";
      },
    }),
    getShowCampaign: build.query({
      query: (id) => {
        return `v1/plugin/campaigns/${id}`;
      },
    }),
    startCampaign: build.mutation({
      query: ({ id, attributes }) => ({
        url: `v1/plugin/campaigns/${id}/start`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, positionType)),
      }),
    }),
    stopCampaign: build.mutation({
      query: ({ id, attributes }) => ({
        url: `v1/plugin/campaigns/${id}/stop`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, positionType)),
      }),
    }),
    deleteCampaign: build.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `v1/plugin/campaigns/${id}`,
      }),
    }),
  }),
});

export const {
  usePostCampaignMutation,
  useLazyGetIndexCampaignQuery,
  useLazyGetShowCampaignQuery,
  useStartCampaignMutation,
  useStopCampaignMutation,
  useDeleteCampaignMutation,
} = CampaignApi;
