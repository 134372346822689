import validator from "@rjsf/validator-ajv6";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  useStartConnectionMutation,
  useStopConnectionMutation,
} from "../../redux/slices/connectionsApi";
import { Form } from "@rjsf/mui";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateConnectionMutation } from "../../redux/slices/connectionsApi";
import { useEffect, useState } from "react";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ConnectionUpdate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startConnection, { isSuccess: startSuccess }] =
    useStartConnectionMutation();
  const [stopConnection, { isSuccess: stopSuccess }] =
    useStopConnectionMutation();
  const { id } = useParams();
  const connection = useSelector(
    (state) => state?.connectionsSlice?.connection
  );
  const [firstRender, setFirstRender] = useState(true);
  const [toggleStarted, setToggleStarted] = useState(false);
  const [formData, setFormData] = useState({});
  const [createdId, setCreatedId] = useState(null);
  const [updateConnection, { isSuccess: updateSuccess }] =
    useUpdateConnectionMutation();
  const onSubmitClick = (obj) => {
    if (connection.attributes.started === true) {
      setToggleStarted(true);
      stopConnection({ id: id, attributes: connection.attributes });
    } else {
      updateConnection({ attributes: obj, id: id });
    }
  };
  useEffect(() => {
    if (stopSuccess && toggleStarted) {
      updateConnection({ id: id, attributes: formData });
    }
  }, [stopSuccess, toggleStarted]);
  useEffect(() => {
    if (updateSuccess && toggleStarted) {
      startConnection({ id: id });
    }
  }, [updateSuccess, toggleStarted]);
  useEffect(() => {
    if (startSuccess && toggleStarted) {
      setToggleStarted(false);
    }
  }, [startSuccess, toggleStarted]);
  useEffect(() => {
    if (updateSuccess && !toggleStarted) {
      dispatch(setMessage("Connection updated successfully"));
      navigate(`/connections/${id}`);
    }
  }, [updateSuccess, toggleStarted]);
  useEffect(() => {
    if (connection && firstRender) {
      setFormData(connection.attributes);
      setFirstRender(false);
    }
  }, [connection]);
  const schema = {
    title: "New Connection",
    type: "object",
    properties: {
      host: {
        $id: "1",
        type: "string",
        title: t("host"),
      },
      login: {
        $id: "2",
        type: "string",
        title: t("login"),
      },
      name: {
        $id: "3",
        type: "string",
        title: t("name"),
      },
      passcode: {
        $id: "4",
        type: "string",
        title: t("passcode"),
      },
      passcode: {
        $id: "5",
        type: "string",
        title: t("passcode"),
      },
      port: {
        $id: "6",
        type: "number",
        title: t("port"),
        maximum: 65535,
      },
      reconnect_timeout: {
        $id: "7",
        type: "number",
        title: t("reconnect_timeout"),
      },
    },
    required: [
      "host",
      "login",
      "name",
      "passcode",
      "port",
      "reconnect_timeout",
    ],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
  };
  return (
    <>
      {!firstRender && (
        <Box sx={{ flexGrow: 1 }}>
          <Form
            schema={schema}
            /*  fields={fields} */
            formData={formData}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => onSubmitClick(values.formData)}
            validator={validator}
            uiSchema={uiSchema}
            /* widgets={widgets} */
            /*    
        customValidate={customValidate} */
            noHtml5Validate
            showErrorList={false}
            /*  templates={{ ErrorListTemplate }} */
          />
        </Box>
      )}
    </>
  );
};
export default ConnectionUpdate;
