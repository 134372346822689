import React, { useState } from "react";
import InputSelect from "./form/InputSelect";
import { useNetworkAdapters } from "./NetwokrAdaptersSelect";

const ActiveNetworkAdaptersSelect = ({ item, control }) => {
	const { selectedNetworkAdpaters } = useNetworkAdapters();
	const [selectedActiveNetworkAdpaters, setSelectedActiveNetworkAdapters] =
		useState();

	return (
		<InputSelect
			item={item}
			options={selectedNetworkAdpaters}
			control={control}
			value={setSelectedActiveNetworkAdapters}
			onChange={({ target: { value } }) => {
				setSelectedActiveNetworkAdapters(value);
			}}
		/>
	);
};

export default ActiveNetworkAdaptersSelect;
