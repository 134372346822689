import { api } from "./api";

export const digitalTwinApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDigitalTwins: build.query({
      query: () => {
        return `v1/altior/digital_twin`;
      },
    }),
    getDigitalTwinShow: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/${id}/attribute`;
      },
    }),
  }),
});

export const { useLazyGetDigitalTwinsQuery, useLazyGetDigitalTwinShowQuery } =
  digitalTwinApi;
