import React from "react";
import { useTranslation } from "react-i18next";
import {
	Table,
	TableBody,
	TableCell,
	Chip,
	TableHead,
	TableRow,
} from "@mui/material";

const SecurityShow = ({ security: { acl, groups }, access, name }) => {
	const { t } = useTranslation();

	return (
		<div className="">
			<div className="security-show-title">
				<h6> {t("acl")} </h6>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell> {t("attuator")} </TableCell>
							<TableCell> {t("actions")} </TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{acl.map(({ name, scopes }) => (
							<TableRow>
								<TableCell> {name} </TableCell>
								<TableCell>
									{" "}
									{scopes
										? scopes.map((scope) => (
												<>
													<Chip label={scope}> </Chip>{" "}
												</>
										  ))
										: "-"}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div className="security-show-title">
				<h6> {t("groups")} </h6>
				{groups.map((group) => {
					return (
						<>
							<Chip label={group}> </Chip>{" "}
						</>
					);
				})}
			</div>
		</div>
	);
};

export default SecurityShow;
