import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import DigitalTwinSelect, {
	DigitalTwinProvider,
} from "../../components/DigitalTwinSelect";

import DeviceCreateForm from "./DeviceCreateForm";

const CreateDevice = () => {
	const { t } = useTranslation();
	return (
		<DigitalTwinProvider>
			<Box >
				<Typography variant="h2" sx={{marginBottom: 3}}>
					{t("create_new_device")}
				</Typography>
				<DigitalTwinSelect />
				<DeviceCreateForm />
			</Box>
		</DigitalTwinProvider>
	);
};

export default CreateDevice;
