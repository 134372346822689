import React from "react";
import { Box, Paper, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/uk";
import IwdBreadCrumb from "../components/IwdBreadcrumb";
import IwdBreadCrumbProvider from "./IwdBreadcrumbProvider";
import Footer from "./Footer";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const localeMap = {
  "it-IT": itLocale,
  "en-UK": enLocale,
};

const Layout = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={localeMap[i18n.language]}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <Header />
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              flexBasis: "fitContent",
            }}
          >
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <IwdBreadCrumbProvider>
                <DrawerHeader />
                <IwdBreadCrumb />
                <Paper sx={{ padding: 1 }}>
                  <Outlet />
                </Paper>
              </IwdBreadCrumbProvider>
            </Box>
            <Footer />
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  );
};
export default Layout;
