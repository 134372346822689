import React, { useState, useEffect } from "react";
import { MapContainer } from "react-leaflet";
import useFetch from "use-http";
import { useAuth } from "../../components/AuthProvider";
import Loader from "../../components/Loader";

import DevicesMapComponent from "./DevicesMapComponent";

const DevicesMap = (props) => {
  const auth = useAuth();
  const [userPreferences, setUserPreferences] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const { get, loading, error } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}/api/v1`
  );

  useEffect(() => {
    auth.getMe((preferences, status) => {
      if (status == 401) {
        auth.signout(() => {});
      }
      if (userPreferences === null) {
        if (preferences.mapCenter) {
          setMapCenter([preferences.mapCenter.lat, preferences.mapCenter.lng]);
        } else {
          setMapCenter([45.518914803561195, 9.053357731163247]);
        }
        setUserPreferences(preferences);
      }
    });
  }, [userPreferences]);

  if (userPreferences === null && mapCenter === null) {
    return <Loader />;
  }

  return (
    <>
      <div className="map-container">
        <MapContainer
          id="map"
          center={mapCenter}
          zoom={13}
          scrollWheelZoom={true}
        >
          <DevicesMapComponent></DevicesMapComponent>
        </MapContainer>
        {loading ? (
          <div className="map-loader">
            <Loader />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default DevicesMap;
