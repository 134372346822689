import React from "react";
import DeviceFormItem from "./DeviceFormItem";
import { NetworkAdaptersProvider } from "./NetwokrAdaptersSelect";
import { useDigitalTwin } from "./DigitalTwinSelect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { convertFromFormToApi } from "./../utils";
import { Box, Grid, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";

const DeviceForm = ({ attributes, onSubmit }) => {
  const removeActonsAndSplitInCategories = (attributes) => {
    const results = {};
    attributes
      .filter(({ attributes: { access } }) => access !== "action")
      .forEach((item) => {
        var key = item.attributes.category;
        if (!key) {
          key = "base";
        }
        if (!results[key]) {
          results[key] = [];
        }
        results[key].push(item);
      });

    return results;
  };
  const getCategoryKeys = (categories) => {
    return Object.keys(categories).sort();
  };
  const { handleSubmit, control } = useForm();
  const { t } = useTranslation();
  const { selectedDigitalTwin } = useDigitalTwin();
  const onInternalSubmit = (data) => {
    const digitalTwinId = selectedDigitalTwin.id;
    const newData = {
      ...data,
      ...selectedDigitalTwin.attributes.digital_twin_fields,
    };
    onSubmit({
      digitalTwinId: digitalTwinId,
      data: convertFromFormToApi(newData),
    });
  };
  const [expanded, setExpanded] = React.useState(["base"]);

  const handleChange = (panel) => (event, newExpanded) => {
    const index = expanded.indexOf(panel);
    var newValues = [...expanded];
    if (index >= 0) {
      delete newValues[index];
    } else {
      newValues.push(panel);
    }

    setExpanded(newValues);
  };

  if (attributes) {
    const categories = removeActonsAndSplitInCategories(attributes);
    return (
      <Box sx={{ marginTop: 5 }}>
        <NetworkAdaptersProvider>
          <form onSubmit={handleSubmit(onInternalSubmit)}>
            {getCategoryKeys(categories).map((category) => (
              <>
                <Accordion
                  expanded={expanded.includes(category)}
                  onChange={handleChange(category)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {t(category)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} alignItems="center">
                      {categories[category].map((attribute) => (
                        <Grid item xs={3} style={{ height: 200 }}>
                          <DeviceFormItem
                            key={uuidv4()}
                            item={attribute}
                            control={control}
                            selectedDigitalTwin={selectedDigitalTwin}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}
            >
              <Button variant="contained" type="submit">
                {" "}
                {t("create")}
              </Button>
            </Box>
          </form>
        </NetworkAdaptersProvider>
      </Box>
    );
  }
  return <></>;
};

export default DeviceForm;
