import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";

import Loader from "../components/Loader";
import CustomerEndpointProvider from "../components/customer_endpoint/CustomerEndpointProvider";

import CustomerEndpointCreateComponent from "../components/customer_endpoint/CustomerEndpointCreateComponent";

const CustomerEndpointCreate = () => {
  const { t } = useTranslation();
  const { loading } = useFetch(`${process.env.REACT_APP_SWARM_BASEURL}/api/v1`);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <h2> {t("create")} </h2>
      <Box sx={{ width: "100%" }}>
        <CustomerEndpointProvider>
          <CustomerEndpointCreateComponent />
        </CustomerEndpointProvider>
      </Box>
    </Box>
  );
};

export default CustomerEndpointCreate;
