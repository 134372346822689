import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import React from "react";
import PolygonsList from "./PolygonsList";
import { actions } from "../../redux/slices/deviceDistrictSlice";
import Button from "@mui/material/Button";
import {
  useCreateDistrictMutation,
  useCreatePolygonMutation,
} from "../../redux/slices/deviceDistrictApi";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const DeviceGroupForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [districtId, setDistrictId] = useState(null);
  const [createDistrict, { isSuccess: districtSuccess }] =
    useCreateDistrictMutation();
  const [createPolygon, { isSuccess: polygonSuccess }] =
    useCreatePolygonMutation();
  const polygons = useSelector((state) => state?.deviceDistrictSlice?.polygons);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      description: formData.description,
    };
    createDistrict(attributes)
      .unwrap()
      .then((data) => setDistrictId(data.data.id));
  };
  useEffect(() => {
    if (districtId && polygons.length > 0) {
      for (let i = 0, len = polygons.length; i < len; i++) {
        createPolygon({ id: districtId, body: polygons[i] });
      }
    }
  }, [districtId, polygons]);
  const schema = {
    title: "Device Group form",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
      },
      description: {
        id: 0,
        type: "string",
        title: t("description"),
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
  };
  useEffect(() => {
    if (districtSuccess && polygons.length == 0) {
      navigate(`/groups/${districtId}`);
    }
    if (districtSuccess && polygonSuccess) {
      navigate(`/groups/${districtId}`);
    }
  }, [districtSuccess, polygonSuccess, polygons.length]);
  useEffect(() => {
    //clear function
    return () => {
      dispatch(actions.setDistrict(null));
    };
  }, []);
  return (
    <>
      <Form
        schema={schema}
        /*     fields={fields} */
        formData={formData}
        /*     customValidate={customValidate} */
        showErrorList={false}
        children={true}
        /* onSubmit={onSubmitClick} */
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        /*   onSubmit={(values) => {
      onSubmitClick(values.formData);
    }} */
        validator={validator}
        uiSchema={uiSchema}
      />
      <PolygonsList />
      <Button
        onClick={onSubmitClick}
        variant="outlined"
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: "white",
          mt: 2,
          "&:hover": { backgroundColor: theme.palette.primary.main },
        }}
      >
        {t("create")}
      </Button>
    </>
  );
};

export default React.memo(DeviceGroupForm);
