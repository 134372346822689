import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

const ErrorsAccordion = (props) => {
  const errors = useSelector((state) => state?.uploadFileSlice?.errors);
  const bearer = localStorage.getItem("user_token");
  const operationId = sessionStorage.getItem("uuid");
  const onExportClick = () => {
    window.open(
      `${window.BASE_URL}//api/v1/altior/download/import_operation?token=${bearer}&operation_id=${operationId}&in_error=true`,
      "_blank"
    );
  };
  return (
    <Box sx={{ width: "100%", mt: 3 }}>
      <Accordion>
        <AccordionSummary
          onClick={props.onClick}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="subtitle2">Errors</Typography>

            {errors.errors.length > 0 && (
              <Button
                sx={{ ml: 5 }}
                variant="contained"
                onClick={onExportClick}
              >
                Export
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <Box /* sx={{ ml: 2, mr: 2, mb: 10 }} */>{props.children}</Box>
      </Accordion>
    </Box>
  );
};

export default ErrorsAccordion;
