import React, { useState } from "react";

export const DeviceGroupContext = React.createContext();
const DeviceGroupProvider = ({  children }) => {
  const [group, setGroup] = useState( {name: null, value: null});
  const [errors, setErrors] = useState()
  const [submit, setSubmit] = useState()
  const [selectedDevices, setSelectedDevices] = useState([])

  const addOrRemoveDevice = (device) => {
    const index = selectedDevices.findIndex(({id}) => id === device.id) 
    if( index >= 0){
     setSelectedDevices(selectedDevices.splice(index, 1)) 
    }else{
      setSelectedDevices([device, ...selectedDevices])
    }
  }

  let value = {
    group,
    setGroup,
    errors,
    setErrors,
    submit,
    setSubmit,
    selectedDevices,
    addOrRemoveDevice,
    setSelectedDevices
  };

  return (
    <DeviceGroupContext.Provider value={value}>{children}</DeviceGroupContext.Provider>
  );
};

export const useDeviceGroup = () => {
  return React.useContext(DeviceGroupContext);
};

export default DeviceGroupProvider;
