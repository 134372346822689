import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { Box } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import { useLazyGetShowCampaignQuery } from "../../redux/slices/CampaignApi";
import LinearWithValueLabel from "./LinearProgress";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import CroneDecoder from "../../utils/CroneDecoder";
import HistoryIcon from "@mui/icons-material/History";
import { unsetCampaignShow } from "../../redux/slices/CampaignSlice";

import theme from "../../theme";
import ModalDialog from "../meters/ModalDialog";
import CampaignUpdateForm from "./CampaignUpdateForm";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import TrashModal from "../TrashModal";
import {
  useStartCampaignMutation,
  useStopCampaignMutation,
  useDeleteCampaignMutation,
} from "../../redux/slices/CampaignApi";
import { Toll } from "@material-ui/icons";
const CampaignShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updateModal, setUpdateModal] = useState(false);
  const [getShowCampaign] = useLazyGetShowCampaignQuery();
  const [startCampaign, { isSuccess: startSuccess }] =
    useStartCampaignMutation();
  const [stopCampaign, { isSuccess: stopSuccess }] = useStopCampaignMutation();
  const [deleteCampaign, isSuccess] = useDeleteCampaignMutation();
  const campaignShow = useSelector(
    (state) => state?.campaignSlice?.campaignShow
  );
  useEffect(() => {
    getShowCampaign(id);
  }, []);
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },
    {
      field: "serial_number",
      headerName: t("water_meter_serial_number"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "boolean",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton>
              <Link to={`/meters/${params.value}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const tableRows = campaignShow?.device?.map((item) => ({
    id: item?.[0],
    serial_number: item?.[1],
    actions: item?.[0],
  }));
  const onCampaignStartClick = () => {
    startCampaign({
      attributes: {
        name: campaignShow?.name,
        data: {
          geoJson: "ftm",
        },
      },
      id: id,
    });
  };
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const onCampaignStopClick = () => {
    stopCampaign({
      attributes: {
        name: campaignShow?.name,
        data: {
          geoJson: "ftm",
        },
      },
      id: id,
    });
  };
  useEffect(() => {
    if (stopSuccess || startSuccess) {
      getShowCampaign(id);
    }
  }, [stopSuccess, startSuccess]);
  useEffect(() => {
    console.log("isSuccess", isSuccess.isSuccess);
  }, [isSuccess]);
  const onTrashClick = () => {
    dispatch(opendModal());
  };
  useEffect(() => {
    console.log("tableRows", tableRows);
  }, [tableRows]);
  /*   if (!tableRows) {
    return <h1>load</h1>;
  } */
  useEffect(() => {
    if (deleteConfirmed) {
      deleteCampaign(id);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  useEffect(() => {
    if (isSuccess.isSuccess) {
      navigate("/campaigns");
    }
  }, [isSuccess.isSuccess]);
  useEffect(() => {
    return () => {
      dispatch(unsetCampaignShow());
    };
  }, []);
  const onUpdateClick = () => {
    setUpdateModal(true);
  };
  return (
    <>
      <ModalDialog
        open={updateModal}
        title={t("update_campaign")}
        close={() => setUpdateModal(false)}
      >
        <CampaignUpdateForm
          startDate={campaignShow?.start_date_time}
          endDate={campaignShow?.end_date_time}
        />
      </ModalDialog>

      <TrashModal
        text={t("delete_campaign_text")}
        title={t("delete_campaign_title")}
      />
      {tableRows && (
        <Grid container>
          <Grid
            container
            alignItems="center"
            rowSpacing={5}
            direction="row"
            spacing={5}
            xs={6}
          >
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("name")} :</Typography>
              <Typography variant="subtitle1">
                {campaignShow?.name ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("running")} :</Typography>
              <Typography variant="subtitle1">
                {campaignShow?.running ? (
                  <DoneIcon sx={{ ml: 1, fill: "green" }} />
                ) : (
                  <CloseIcon sx={{ ml: 1, fill: "red" }} />
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("start_date")} :</Typography>
              <Typography variant="subtitle1">
                {campaignShow?.start_date_time
                  ? t("dt", { val: new Date(campaignShow?.start_date_time) })
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("end_date")} :</Typography>
              <Typography variant="subtitle1">
                {campaignShow?.end_date_time
                  ? t("dt", { val: new Date(campaignShow?.end_date_time) })
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("last_update")} :</Typography>
              <Typography variant="subtitle1">
                {t("dt", { val: new Date(campaignShow?.last_update) }) ?? "--"}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("status")} :</Typography>
              <LinearWithValueLabel progress={campaignShow?.status} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {t("push_scheduler")} :
              </Typography>
              <CroneDecoder
                expression={campaignShow?.push_scheduler.split(" ")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">{t("actions")} :</Typography>
              <Box>
                {!campaignShow.running && campaignShow?.status < 100 && (
                  <IconButton onClick={onCampaignStartClick}>
                    <Tooltip title={t("start_campaign")}>
                      <PlayArrowIcon
                        sx={{ fill: theme.palette.primary.main }}
                      />
                    </Tooltip>
                  </IconButton>
                )}
                {campaignShow.running && (
                  <IconButton onClick={onCampaignStopClick}>
                    <Tooltip title={t("stop_campaign")}>
                      <PauseIcon sx={{ fill: theme.palette.primary.main }} />
                    </Tooltip>
                  </IconButton>
                )}
                <IconButton onClick={onTrashClick}>
                  <Tooltip title={t("delete_campaign")}>
                    <DeleteIcon sx={{ fill: theme.palette.error.main }} />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={onUpdateClick}>
                  <Tooltip title={t("update_campaign")}>
                    <HistoryIcon sx={{ fill: theme.palette.primary.main }} />
                  </Tooltip>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid item xs={12} sx={{ height: 400, width: "100%", ml: 2 }}>
              <DataGrid
                disableColumnMenu
                rows={tableRows}
                columns={columns}
                /*   rowCount={response?.data?.meta?.count} */
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                /*    onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1} */
                // loading={isLoadingTable}
                /*  paginationMode="server" */
                /*  getRowHeight={() => "auto"} */
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default CampaignShow;
