import React, { useState, useEffect } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import DeviceSvg from "../../statics/icons/device.svg";
import { useSearch } from "../../components/SearchProvider";

const SelectedDeviceInMap = () => {
	const searcher = useSearch();
	const map = useMap();
	const deviceIcon = () =>
		new L.icon({
			iconUrl: DeviceSvg,
			iconSize: 20,
		});

	if (searcher && searcher.mode === "show") {
		map.flyTo(
			L.latLng(
				searcher.showParams.attributes.coordinates.coordinates[1],
				searcher.showParams.attributes.coordinates.coordinates[0]
			)
		);
		return (
			<Marker
				icon={deviceIcon()}
				key={searcher.showParams.id}
				position={[
					searcher.showParams.attributes.coordinates.coordinates[1],
					searcher.showParams.attributes.coordinates.coordinates[0],
				]}
			>
				<Popup>{searcher.showParams.attributes.serial_number}</Popup>
			</Marker>
		);
	} else {
		return "";
	}
};

export default SelectedDeviceInMap;
