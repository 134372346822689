import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { deviceMessageApi } from "./deviceMessageApi";

const initialState = { data: [], meta: null };

const deviceMessageSlice = createSlice({
  name: "deviceMessageSlice",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      deviceMessageApi.endpoints.getDeviceMessages.matchFulfilled,
      (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      }
    );
  },
});
export const { setData } = deviceMessageSlice.actions;

export default deviceMessageSlice.reducer;
