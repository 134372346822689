import { createSlice } from "@reduxjs/toolkit";
import { uploadFileApi } from "./uploadFileApi";
const initialState = {
  uuid: sessionStorage.getItem("uuid"),
  statistics: null,
  errors: null,
};
const uploadFileSlice = createSlice({
  name: "uploadFileSlice",
  initialState,
  reducers: {
    setUuid(state, action) {
      state.uuid = action.payload;
      sessionStorage.setItem("uuid", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      uploadFileApi.endpoints.getFileStatistic.matchFulfilled,
      (state, action) => {
        state.statistics = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      uploadFileApi.endpoints.getFileErrors.matchFulfilled,
      (state, action) => {
        state.errors = action.payload.data[0].attributes;
      }
    );
  },
});
export const { setUuid } = uploadFileSlice.actions;
export default uploadFileSlice.reducer;
