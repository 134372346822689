import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { StyledDataGrid } from "../../../utils/StyledDataGrid";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../../theme";
import { Link } from "react-router-dom";

const Table = ({
  data,
  response,
  perPage,
  page,
  setPage,
  hideAccordion,
  loading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },
    /*    {
      field: "class",
      headerName: t("class"),
      editable: false,
      sortable: false,
      flex: 3,
    }, */
    {
      field: "tag",
      headerName: t("tag"),
      flex: 3,
      editable: true,
      sortable: false,

      renderCell: (params) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.8 }}>
          {params.value == "--" ? (
            <>--</>
          ) : (
            params.value.map((item) => <Chip label={t(item)} />)
          )}
        </Box>
        /*   <List>
          {params.value == "--" ? (
            <>--</>
          ) : (
            params.value.map((item) => (
              <ListItem>
                <ListItemText primart={item} />
              </ListItem>
               <Chip label={t(item)} />
            ))
          )}
        </List> */
      ),
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,
      editable: true,
      flex: 5,
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,
      editable: true,
      flex: 5,
    },

    {
      field: "address",
      headerName: t("street_address"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
    },
    {
      field: "pod",
      headerName: t("pod"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 3,
    },
    {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 3,
    },

    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 6,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 4,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton disabled sx={{ float: "right" }}>
              <DeleteIcon sx={{ ml: 2 }} />
            </IconButton>
            <Link to={`/meters/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",
    /*    class: item?.attributes?.class ? item?.attributes?.class : "--", */
    tag: item?.attributes?.fields?.tags
      ? item?.attributes?.fields?.tags.map((item) => item)
      : "--",
    metrological_serial: item?.attributes?.fields?.serial ?? "--",
    serial: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",
    pod: item?.attributes?.fields?.pod ? item?.attributes?.fields?.pod : "--",
    diameter: item?.attributes?.fields?.diameter
      ? item?.attributes?.fields?.diameter
      : "--",
    configdate: item?.attributes?.inserted_at
      ? t("dt", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: item.id ? item.id : "",
  }));

  return (
    <Box sx={{ height: hideAccordion ? "60vh" : "39vh", width: "99%" }}>
      <StyledDataGrid
        disableColumnMenu
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
        rowCount={response?.data?.meta?.count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          !response.isFetching && setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default Table;
