import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { TextField, Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDeviceDistrict } from "../DeviceDistrictProvider";

const DeviceDistrictSearchBar = () => {
  const { t } = useTranslation();
  const { searchParams, search } = useDeviceDistrict();
  const [searchValue, setSearchValue] = useState();
  const onSearch = () => {
    if (searchValue !== "") {
      search({ ...searchParams, name: searchValue });
    } else {
      const newSearchParams = { ...searchParams };
      delete newSearchParams.name;
      search(newSearchParams);
    }
  };
  return (
    <Box>
      <Stack direction="row">
        <FormControl sx={{ width: "100%" }}>
          <TextField
            id="standard-basic"
            label={t("search_term")}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onSearch();
                event.preventDefault();
              }
            }}
          />
        </FormControl>
        <FormControl sx={{ flexShrink: 0 }}>
          <Button color="primary" size="large" onClick={onSearch}>
            <SearchIcon />
          </Button>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default DeviceDistrictSearchBar;
