import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../redux/slices/errorSlice";
import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const ErrorsHandler = () => {
  const showError = useSelector((state) => state?.errorSlice?.showError);
  const errorData = {
    errors: useSelector((state) => state?.errorSlice?.errors),
    errorTitle: useSelector((state) => state?.errorSlice?.errorTitle),
    errorStatus: useSelector((state) => state?.errorSlice?.errorStatus),
    errData: useSelector((state) => state?.errorSlice?.data),
  };

  useEffect(() => {
    console.log("ers", errorData.errData);
  }, [errorData.errData]);
  const dispatch = useDispatch();

  const handleErrors = (errorData) => {
    let arrErrors;
    if (errorData?.errData?.length > 0) {
      arrErrors = errorData?.errData;
    } else {
      if (errorData?.errorStatus == 500) {
        arrErrors = ["Internal server error"];
      }
      if (typeof errorData?.errors === "string") {
        arrErrors = [errorData?.errors];
      } else {
        arrErrors = errorData?.errors;
      }
    }
    return arrErrors?.map((item, i) => (
      <ListItem key={i}>
        <ListItemText primary={item} />
      </ListItem>
    ));
  };
  let errorCode;
  if (errorData?.errData?.length > 0) {
    errorCode = errorData?.errorTitle;
  } else {
    errorCode = errorData?.errorStatus;
  }
  if (errorData?.errorTitle === "FETCH_ERROR") {
    errorCode = 404;
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        overflow="hidden"
        ref={ref}
        variant="filled"
        {...props}
      />
    );
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearErrors());
  };
  return (
    <Snackbar
      open={showError}
      autoHideDuration={6000}
      onClose={handleClose}
      sx={{ overflow: "hidden" }}
    >
      <Alert severity="error" onClose={handleClose} sx={{ overflow: "hidden" }}>
        {/*    {errorData?.errData?.length > 0 ? (
          <Typography>{`Error code: ${errorData?.errorTitle}`}</Typography>
        ) : (
          <Typography>{`Error code: ${errorData?.errorStatus}`}</Typography>
        )} */}
        <Typography>{`Error code: ${errorCode}`}</Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={3}
          spacing={2}
        >
          {
            <Grid item xs={12}>
              <List>{handleErrors(errorData)}</List>
            </Grid>
          }
        </Grid>
      </Alert>
    </Snackbar>
  );
};

export default ErrorsHandler;
