import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, value, theme) {
  if (Array.isArray(value)) {
    return {
      fontWeight:
        value && value.indexOf(option) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  } else {
    return {
      fontWeight:
        !value || value.value !== option.value
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
}

/**
 * Show a select input in according with the react form specifications.
 */
const InputSelect = ({ item, options, onChange, isMulti, control, value }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const onChangeParent = onChange;

  const currentValue = value;
  return (
    <Controller
      name={item.attributes.name}
      control={control}
      render={({ field: { name, onBlur, onChange, ref, value } }) => {
        const defaultValue = value
          ? value
          : currentValue
          ? currentValue
          : isMulti
          ? []
          : "";

        return (
          <>
            <div>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="network-adapter-select-label">
                  {t(item.attributes.name)}
                </InputLabel>

                <Select
                  onBlur={onBlur}
                  name={name}
                  ref={ref}
                  labelId="network-adapter-select-label"
                  id="network-adapter-select"
                  multiple={isMulti ? isMulti : false}
                  value={defaultValue}
                  onChange={(event) => {
                    if (onChangeParent) {
                      onChangeParent(event);
                    }
                    onChange(event);
                  }}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label={t(item.attributes.name)}
                    />
                  }
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {!Array.isArray(selected) && (
                          <Chip key={selected.label} label={selected.label} />
                        )}
                        {Array.isArray(selected) &&
                          selected.map((item) => (
                            <Chip key={item.label} label={t(item.label)} />
                          ))}
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option.label}
                      value={option}
                      style={getStyles(option, value, theme)}
                    >
                      {t(option.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        );
      }}
    />
  );
};

InputSelect.propTypes = {
  /**
   * This filed is an object and is used for getting the name.
   * The object must be contains the follows keys: `{attributes: {name}}`
   * */
  item: PropTypes.object.isRequired,
  /**
   * This is an array of objects. One object has two keys:
   *
   * - `label` : that represent the name show in select
   * - `value` : the value used in the operations
   */
  options: PropTypes.array.isRequired,
  /**
   * Function called once on item is select. The param is:
   * - classic event, it means that the key `target` has the selected item
   */
  onChange: PropTypes.func.isRequired,
  /**
   * If true the multiple select is enabled, if it's false or not present the
   * multiple select is disabled.
   */
  isMulti: PropTypes.bool,
  /**
   * `control` of [react-hook-form](https://react-hook-form.com/)
   */
  control: PropTypes.isRequired,
  /**
   * object with `label` and `value` key, for set the selected item.
   */
  value: PropTypes.object,
};
export default InputSelect;
