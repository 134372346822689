import React, { useState, useCallback } from "react";
import useFetch from "use-http";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const DigitalTwinContext = React.createContext();

export const DigitalTwinProvider = ({ children }) => {
  const [selectedDigitalTwin, setSelectedDigitalTwin] = useState();
  const [digitalTwinAttributes, setDigitalTwinAttributes] = useState();
  const [loadingDigitalTwin, setLoadingDigitalTwin] = useState(false);
  const [loadingDigitalTwinError, setLoadingDigitalTwinError] = useState(null);
  const { get, response, error } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}/api/v1`
  );
  const loadDigitalTwinAttributes = useCallback(
    async (digitalTwin) => {
      setLoadingDigitalTwin(true);
      const attributes = await get(
        `/altior/digital_twin/${digitalTwin.id}/attribute`
      );
      if (error) {
        setLoadingDigitalTwinError(true);
        setDigitalTwinAttributes(null);
      } else {
        setLoadingDigitalTwinError(null);
        setDigitalTwinAttributes(attributes);
      }
      setLoadingDigitalTwin(false);
    },
    [get, error, response]
  );

  const setSelectedDigitalTwinAndLoadAttributes = (digitalTwin) => {
    setSelectedDigitalTwin(digitalTwin);
    loadDigitalTwinAttributes(digitalTwin);
  };

  let value = {
    selectedDigitalTwin,
    setSelectedDigitalTwin,
    digitalTwinAttributes,
    setDigitalTwinAttributes,
    setSelectedDigitalTwinAndLoadAttributes,
    loadingDigitalTwin,
    loadingDigitalTwinError,
  };

  return (
    <DigitalTwinContext.Provider value={value}>
      {children}
    </DigitalTwinContext.Provider>
  );
};

export const useDigitalTwin = () => {
  return React.useContext(DigitalTwinContext);
};
const DigitalTwinSelect = () => {
  const { selectedDigitalTwin, setSelectedDigitalTwinAndLoadAttributes } =
    useDigitalTwin();
  const { t } = useTranslation();
  const { loading, error, data } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}/api/v1/altior/digital_twin`,
    {},
    []
  );
  const handleChange = (event) => {
    setSelectedDigitalTwinAndLoadAttributes(data.data[event.target.value]);
  };

  if (loading) return <Loader />;
  if (error) return <div> Errore da fare grafica </div>;
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="select-digital-twin-label">
          {t("select_digital_twin")}
        </InputLabel>
        <Select
          labelId="select-digital-twin-label"
          id="select-digital-twin"
          value={selectedDigitalTwin}
          label={t("select_digital_twin")}
          onChange={handleChange}
        >
          {data?.data?.map((digitalTwin, index) => (
            <MenuItem key={index} value={index}>
              {digitalTwin.attributes?.name} ({digitalTwin.attributes?.version}){" "}
              {digitalTwin.attributes?.device_class}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DigitalTwinSelect;
