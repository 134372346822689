import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import { useLazyGetDistrictsQuery } from "../../redux/slices/deviceDistrictApi";
import DistrictTable from "./DistrictTable";
import { useDeleteDistrictMutation } from "../../redux/slices/deviceDistrictApi";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slices/deviceDistrictSlice";
import DistrictSearchBar from "./DistrictSearchBar";
const DistrictIndex = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [deleteDistrict, { isSuccess: deleteSuccess }] =
    useDeleteDistrictMutation();
  const [getDistricts] = useLazyGetDistrictsQuery();
  const deleteDistrictId = useSelector(
    (state) => state?.deviceDistrictSlice.deleteDistrictId
  );
  const params = {
    page: page,
    perPage: perPage,
  };
  useEffect(() => {
    getDistricts(params);
  }, [page]);
  useEffect(() => {
    if (deleteDistrictId) {
      deleteDistrict(deleteDistrictId);
      dispatch(actions.setDeleteDistrictId(null));
    }
  }, [deleteDistrictId]);
  useEffect(() => {
    if (deleteSuccess) {
      getDistricts(params);
    }
  }, [deleteSuccess]);
  return (
    <>
      <DistrictSearchBar params={params} />
      <DistrictTable perPage={perPage} page={page} setPage={setPage} />
    </>
  );
};
export default React.memo(DistrictIndex);
