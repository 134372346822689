
import React, {useEffect} from "react";
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/es';

import { useTranslation } from "react-i18next";

const IwdDateTime = ({date}) => {
  const {t, i18n} = useTranslation()

  useEffect(() => {
    moment.locale(i18n.resolvedLanguage)
  }, [i18n])

  return (
    <span>
      {moment(date).format("LLLL")}
    </span>
  );
};

export default IwdDateTime;
