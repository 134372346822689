import React, { useState } from "react";
import { useSearch } from "../../components/SearchProvider";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField, Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClassSelect from "../../components/device/ClassSelect";

const DeviceSearchBar = () => {
  const { t } = useTranslation();
  const searcher = useSearch();
  const [searchValue, setSearchValue] = useState();
  const [showClasses, setShowClasses] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(
    JSON.stringify({
      title: t("serial"),
      value: "serial_number",
    })
  );
  const onSearch = () => {
    if (searchValue !== "") {
      let searchParams = {};
      const searchCriteriaObj = JSON.parse(searchCriteria);
      searchParams[searchCriteriaObj.value] = searchValue;
      searcher.setSearchParamsAndMode(searchParams);
    } else {
      searcher.setMapMode();
    }
  };
  return (
    <Box>
      <Stack direction="row">
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">
            {t("search_filter")}
          </InputLabel>
          <Select
            size="small"
            labelId="searchDevice"
            value={searchCriteria}
            label={t("search_filter")}
            onChange={(e) => {
              if (JSON.parse(e.target.value).value == "classes") {
                setShowClasses(true);
              } else {
                setShowClasses(false);
              }
              setSearchCriteria(e.target.value);
            }}
          >
            <MenuItem
              value={JSON.stringify({
                title: t("serial"),
                value: "serial_number",
              })}
            >
              {t("serial")}
            </MenuItem>
            <MenuItem
              value={JSON.stringify({ title: t("class"), value: "classes" })}
            >
              {t("class")}
            </MenuItem>
          </Select>
        </FormControl>
        {showClasses ? (
          <ClassSelect
            onClassesSlected={(val) => setSearchValue(val)}
          ></ClassSelect>
        ) : (
          <FormControl sx={{ width: "100%" }}>
            <TextField
              id="standard-basic"
              label={t("search_term")}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={(newValue) => setSearchValue(newValue.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onSearch();
                  event.preventDefault();
                }
              }}
            />
          </FormControl>
        )}
        <FormControl sx={{ flexShrink: 0 }}>
          <Button color="primary" size="large" onClick={onSearch}>
            <SearchIcon />
          </Button>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default DeviceSearchBar;
