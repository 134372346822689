import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetDataQuery } from "../../../redux/slices/GetDataRtq";
import { setDigitalTwin } from "../../../redux/slices/digitalTwinSlice";
import { useEffect, useState } from "react";
import { Diamond } from "@mui/icons-material";
const timeZoneDefaultValue = [
  { title: "The Shawshank Redemption", year: 1994 },
];

const SearcheableSelect = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [value, setValue] = useState(props.formData ? props.formData : "");
  const [trigger, result, lastPromiseInfo] = useLazyGetDataQuery();
  const diameter = props?.schema?.dispatched
    ? result?.data?.data.filter((item) => item?.attributes?.id == value?.key)
    : null;
  let data = result ? props.schema?.mapFn(result) : [];

  useEffect(() => {
    if (value && value != "" && value != props.formData) {
      props.onChange(value.value);
    }
  }, [value]);

  useEffect(() => {
    if (props?.schema?.dispatched) {
      dispatch(setDigitalTwin(diameter));
    }
  }, [value]);

  useEffect(() => {
    if (open && firstRender) {
      Promise.all([trigger(props.schema.url)]).then(() => {
        setLoading(false);
        setFirstRender(false);
      });
    }
  }, [open]);
  useEffect(() => {
    console.log("rezik", result);
  }, [result]);

  const openFunc = () => {
    setOpen(true);
    if (firstRender) {
      setLoading(true);
    }
  };

  return (
    <>
      <Autocomplete
        fullWidth
        value={props?.formData && props?.formData}
        open={open}
        onOpen={openFunc}
        onClose={() => setOpen(false)}
        options={data ? data : []}
        disableClearable
        defaultValue={props?.schema?.defaultTimeZone && "Europe/Rome"}
        popupIcon={""}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              required={props.required}
              {...params}
              error={props?.rawErrors?.length > 0}
              label={props.schema.title}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CircularProgress color="inherit" size={20} />}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </>
  );
};

export default SearcheableSelect;
