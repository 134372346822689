import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import JSONInput from "react-json-editor-ajrm";
import { Box } from "@mui/material";
import locale from "react-json-editor-ajrm/locale/en";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PropTypes from "prop-types";

/**
 * Show a JSON input in according with the react form specifications.
 */
const InputJSON = ({ item, control, name }) => {
  const { t } = useTranslation();
  if (item) {
    return (
      <Controller
        name={item.attributes.name}
        control={control}
        render={({ field }) => (
          <>
            <FormControl fullWidth={true} style={{ height: "100%" }}>
              <FormLabel component="legend">
                {t(item.attributes.name)}
              </FormLabel>
              <Box sx={{ height: "100%" }}>
                <JSONInput
                  locale={locale}
                  height="100%"
                  width="100%"
                  {...field}
                />
              </Box>
            </FormControl>
          </>
        )}
      />
    );
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <FormControl fullWidth={true} style={{ height: "100%" }}>
            <FormLabel component="legend">{t(name)}</FormLabel>
            <Box sx={{ height: "100%" }}>
              <JSONInput
                locale={locale}
                height="100%"
                width="100%"
                {...field}
              />
            </Box>
          </FormControl>
        </>
      )}
    />
  );
};

InputJSON.propTypes = {
  /**
   * This filed is an object and is used for getting the name.
   * The object must be contains the follows keys: `{attributes: {name}}`
   * */
  item: PropTypes.object.isRequired,
  /**
   * `control` of [react-hook-form](https://react-hook-form.com/)
   */
  control: PropTypes.isRequired,
};
export default InputJSON;
