import { Button, List, ListItem, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { actions } from "../redux/slices/iwdWebSocketSlice";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  ListItemText,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const Riccardo = () => {
  const dispatch = useDispatch();
  const { isWSConnected, subscribedChannels, data, error, channels, loading } =
    useSelector((state) => state.iwdWebSocketSlice);
  const [eventName, setEventName] = useState("data_update");
  const [selectedChannel, setSelectedChannel] = useState();
  const [channel, setChannel] = useState();
  const [params, setParams] = useState();
  const [message, setMessage] = useState();

  return (
    <>
      <Box>
        {loading ? <p> Loading </p> : <>-</>}
        <Box>
          <List>
            <ListItem disablePadding>
              <ListItemText
                primary={`IsConnected: ${isWSConnected ? "yes" : "no"}`}
              />
              <ListItemText
                primary={`Joined Channels: ${JSON.stringify(
                  Object.keys(channels)
                )}`}
              />
              <ListItemText
                primary={`Subscibe events: ${JSON.stringify(
                  subscribedChannels
                )}`}
              />
            </ListItem>
          </List>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}
          >
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Channel
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <TextField
                    sx={{ flexGrow: 1 }}
                    id="channel"
                    label="Channel"
                    variant="outlined"
                    onChange={(e) => setChannel(e.target.value)}
                    value={channel}
                  />
                  <TextField
                    sx={{ flexGrow: 1 }}
                    id="params"
                    label="Params"
                    variant="outlined"
                    onChange={(e) => setParams(e.target.value)}
                    multiline
                    rows={8}
                    value={params}
                  />
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Event
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <FormControl>
                    <InputLabel id="channel-select">Channel</InputLabel>
                    <Select
                      labelId="channel-id"
                      id="channel-select"
                      value={selectedChannel}
                      label="Selected Channels"
                      onChange={(e) => setSelectedChannel(e.target.value)}
                    >
                      {Object.keys(channels).map((name) => (
                        <MenuItem value={name}>{name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="event"
                    label="Event"
                    variant="outlined"
                    onChange={(e) => setEventName(e.target.value)}
                    value={eventName}
                  />
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Send message
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      flexGrow: 1,
                    }}
                  >
                    <FormControl>
                      <InputLabel id="channel-select">Channel</InputLabel>
                      <Select
                        labelId="channel-id"
                        id="channel-select"
                        value={selectedChannel}
                        label="Selected Channels"
                        onChange={(e) => setSelectedChannel(e.target.value)}
                      >
                        {Object.keys(channels).map((name) => (
                          <MenuItem value={name}>{name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="event"
                      label="Event"
                      variant="outlined"
                      onChange={(e) => setEventName(e.target.value)}
                      value={eventName}
                    />
                    <TextField
                      id="message"
                      label="message"
                      sx={{ width: "100%" }}
                      multiline
                      rows={4}
                      variant="outlined"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  In message
                </Typography>
                {data ? <pre>{JSON.stringify(data, null, 10)}</pre> : "No data"}
              </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  In Error
                </Typography>
                {error ? <pre>{JSON.stringify(error)}</pre> : "No error"}
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={() => dispatch(actions.connect())}
            >
              Connect
            </Button>
            <Button
              disabled={!channel || !isWSConnected}
              variant="contained"
              onClick={() => {
                dispatch(
                  actions.joinChannel({
                    name: channel,
                    params: params,
                  })
                );
              }}
            >
              Join channel
            </Button>
            <Button
              variant="contained"
              disabled={Object.keys(channels).length == 0}
              onClick={() =>
                dispatch(
                  actions.subscribeChannel({
                    channelName: selectedChannel,
                    eventName: eventName,
                  })
                )
              }
            >
              Subscribe
            </Button>
            <Button
              variant="contained"
              disabled={Object.keys(subscribedChannels).length == 0}
              onClick={() =>
                dispatch(
                  actions.unsuscribeChannel({
                    channelName: selectedChannel,
                    eventName: eventName,
                  })
                )
              }
            >
              Unsucribe
            </Button>
            <Button
              disabled={Object.keys(channels).length == 0}
              variant="contained"
              onClick={() => dispatch(actions.leaveChannel(selectedChannel))}
            >
              Leave channel
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Riccardo;
