import * as React from "react";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CopyButton({ valueToCopy }) {
  const [color, setColor] = React.useState("secondary");
  return (
    <CopyToClipboard text={valueToCopy} onCopy={() => setColor("success")}>
      <Button color={color}>
        <ContentCopyIcon />
      </Button>
    </CopyToClipboard>
  );
}
