import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { EventRepeat } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setDataSource } from "../../redux/slices/dexDataSourceSlice";

const BasicSelect = ({ onChange }) => {
  // const [dataSource, setDataSource] = React.useState("");
  const { t } = useTranslation();
  const dataSources = useSelector(
    (state) => state.dexDataSourceSlice.dataSources
  );
  const dataSource = useSelector(
    (state) => state.dexDataSourceSlice.dataSource
  );
  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch(setDataSource(event.target.value));
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-data-source-select-label">
          {t("dataSource")}
        </InputLabel>

        <Select
          labelId="demo-data-source-select-label"
          id="demo-data-source-select"
          value={dataSource}
          label={t("dataSource")}
          onChange={handleChange}
        >
          {dataSources.map((item) => (
            <MenuItem value={item}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
