import { createSlice } from "@reduxjs/toolkit";
import { use } from "i18next";
import { userPreferenceApi } from "./userPreferenceApi";
const initialState = {
  userInfo: null,
  updating: false,
  firstRender: true,
  preference: null,
  dashboardId: "nodash",
  mapCenter: {
    lat: 44.833839087777484,
    lng: 11.826524734497072,
  },
  refreshIntervall: null,

  mapZoom: 9,
  roles: [],
};

const userPreferenceSlice = createSlice({
  name: "userPreferenceSlice",
  initialState,
  reducers: {
    setPreferenceDashboardId(state, action) {
      state.dashboardId = action.payload;
      state.preference.preferences.selected_dashboard_id = action.payload;
      state.updating = true;
    },
    setPreferenceMapcenter(state, action) {
      state.mapCenter = action.payload;
      state.preference.preferences.mapCenter = action.payload;
      state.updating = true;
    },
    setPreferenceMapZoom(state, action) {
      debugger;
      state.mapZoom = action.payload;
      state.preference.preferences.mapZoom = action.payload;
    },
    setRefreshIntervall(state, action) {
      state.refreshIntervall = action.payload;
      state.preference.preferences.refreshIntervall = action.payload;
      state.updating = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userPreferenceApi.endpoints.getPreference.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          "user_info",
          JSON.stringify(action.payload.data.attributes)
        );
        localStorage.setItem(
          "user_preferences",
          JSON.stringify(action.payload.data.attributes.preferences)
        );
        state.userInfo = action.payload.data.attributes;
        state.dashboardId =
          action.payload.data.attributes.preferences.selected_dashboard_id;
        if (action.payload.data.attributes.preferences.mapCenter) {
          state.mapCenter =
            action.payload.data.attributes.preferences.mapCenter;
        }
        if (action.payload.data.attributes.preferences.mapZoom) {
          state.mapZoom = action.payload.data.attributes.preferences.mapZoom;
        }
        if (action.payload.data.attributes.preferences.refreshIntervall) {
          state.refreshIntervall =
            action.payload.data.attributes.preferences.refreshIntervall;
        }
        state.preference = action.payload.data.attributes;
        state.roles = action.payload.data.attributes.roles;
        state.firstRender = false;
      }
    );
    builder.addMatcher(
      userPreferenceApi.endpoints.setPreference.matchFulfilled,
      (state) => {
        state.updating = false;
      }
    );
  },
});
export const {
  setPreferenceDashboardId,
  setPreferenceMapcenter,
  setPreferenceMapZoom,
  setRefreshIntervall,
} = userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;
