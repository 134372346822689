import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import UploadIcon from "@mui/icons-material/Upload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import {
	ListItemIcon,
	ListItemText,
	ListItemButton,
	ListItem,
} from "@mui/material";

const messageIconCss = (inserted) => {
	if (inserted) {
		return { color: "green" };
	} else {
		return {};
	}
};

const MessageIcon = ({ event, inserted }) => {
	const { t } = useTranslation();
	switch (event) {
		case "inbound":
			return (
				<Tooltip title={t(event)} arrow>
					<DownloadIcon sx={messageIconCss(inserted)} />
				</Tooltip>
			);
		case "outbound":
			return (
				<Tooltip title={t(event)} arrow>
					<UploadIcon sx={messageIconCss(inserted)} />
				</Tooltip>
			);
		case "error":
			return (
				<Tooltip title={t(event)} arrow>
					<ErrorOutlineIcon sx={messageIconCss(inserted)} />
				</Tooltip>
			);
		case "errors":
			return (
				<Tooltip title={t(event)} arrow>
					<ErrorOutlineIcon sx={messageIconCss(inserted)} />
				</Tooltip>
			);

		default:
			return (
				<Tooltip title={"-"} arrow>
					<DownloadIcon sx={messageIconCss(inserted)} />
				</Tooltip>
			);
	}
};

const MessageText = ({ payload }) => {
	if (payload) {
		return (
			<ListItemText
				className="device-message-text"
				primary={JSON.stringify(payload)}
				secondary={payload.message_date}
			/>
		);
	}
	return <ListItemText className="device-message-text" primary="-" />;
};

const DeviceMessage = ({ message, onClick, selected }) => {
	const handleOnClick = () => {
		onClick(message);
	};
	return (
		<ListItem onClick={handleOnClick} sx={{ width: "auto" }}>
			<ListItemButton selected={selected === message} sx={{ width: "100%" }}>
				<ListItemIcon>
					<MessageIcon
						event={message.type ? message.type : message.message_type}
						inserted={message.inserted}
					/>
				</ListItemIcon>
				<MessageText payload={message} />
			</ListItemButton>
		</ListItem>
	);
};

export default DeviceMessage;
