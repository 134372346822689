import React, { useState } from "react";

const SearchContext = React.createContext();
/**
 *
 *  The available modes are:
 *  - map: when the map bounds or zoom change table and map devices are updated
 *  - cluster: indicate that a single cluster is clicked, in that case only the table must be updated
 *  - search: when the search is active only the table must be updated
 */
const SearchProvider = ({ children }) => {
  const [mode, setMode] = useState("map");
  const [searchParams, setSearchParams] = useState();
  const [mapParams, setMapParams] = useState();
  const [clusterParams, setClusterParams] = useState();
  const [showParams, setShowParams] = useState();

  const setMapMode = () => {
    setMode("map");
  };
  const setClusterMode = () => {
    setMode("cluster");
  };
  const setSearchMode = () => {
    setMode("search");
  };
  const setShowMode = () => {
    setMode("show");
  };

  const setMapParamsAndMode = (params) => {
    setMapMode();
    setMapParams(params);
  };

  const setSearchParamsAndMode = (params) => {
    setSearchMode();
    setSearchParams(params);
  };

  const setClusterParamsAndMode = (params) => {
    setClusterMode();
    setClusterParams(params);
  };
  const setShowParamsAndMode = (params) => {
    setShowMode();
    setShowParams(params);
  };

  let value = {
    mode,
    setSearchMode,
    setClusterMode,
    setMapMode,
    searchParams,
    setSearchParamsAndMode,
    mapParams,
    setMapParamsAndMode,
    clusterParams,
    setClusterParamsAndMode,
    showParams,
    setShowParamsAndMode,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearch = () => {
  return React.useContext(SearchContext);
};

export default SearchProvider;
