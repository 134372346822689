import { method } from "lodash";
import { api } from "./api";
import { prepareBodyWithoutId } from "../../utils/api_params";
const type = "position";
export const positionApi = api.injectEndpoints({
  endpoints: (build) => ({
    postPosition: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/positions",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, type)),
      }),
    }),
  }),
});

export const { usePostPositionMutation } = positionApi;
