/**
 * This function convert the data obtain from the form and covert it into the correct format for
 * call the HTTP API
 */
export const convertFromFormToApi = (params) => {
  let result = {};
  Object.keys(params).map((key) => {
    switch (key) {
      case "active_network_adapters":
        if (params[key] && params[key].value) {
          var {
            value: { name },
          } = params[key];
          result[key] = [{ name: name, module: null }];
        } else {
          result[key] = [];
        }
        break;
      case "network_adapters":
        result[key] = [];
        if (params[key]) {
          params[key].map((item) => {
            var {
              value: { name },
            } = item;
            result[key].push({ name: name, module: null });
          });
        }
        break;
      default:
        if (params[key] && params[key].jsObject) {
          result[key] = params[key].jsObject;
        } else {
          result[key] = params[key];
        }
    }
  });
  // Set serial_number field with identifier_type field, this is necessary for
  // Swarm!
  result["serial_number"] = params[params.identifier_type];
  return result;
};
