import { api } from "./api";
import { prepareParams } from "../../utils";
import { prepareBodyWithoutId } from "../../utils/api_params";
import { prepareBody } from "../../utils/api_params";

export const trilliantApi = api.injectEndpoints({
  endpoints: (build) => ({
    exportTrilliantMeters: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/seal",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
    getTrilliantMeters: build.query({
      query: (params) => {
        return `v1/plugin/infodevices?${prepareParams(params)}`;
      },
    }),
    deleteTrilliantMeter: build.mutation({
      query: (id) => ({
        url: `v1/plugin/infodevices/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useExportTrilliantMetersMutation,
  useLazyGetTrilliantMetersQuery,
  useDeleteTrilliantMeterMutation,
} = trilliantApi;
