import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Box } from "@mui/material";
import ModalDialog from "../../meters/ModalDialog";
import { useTranslation } from "react-i18next";
import {
  useDownloadMutation,
  useLazyStartExportQuery,
} from "../../../redux/slices/deviceExportApi";
import {
  useLazyStartExportMessageQuery,
  useDownloadMessagesMutation,
} from "../../../redux/slices/messageExportApi";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/slices/iwdWebSocketSlice";
import { loadingOn, loadingOff } from "../../../redux/slices/loadingSlice";

const options = ["Export XLSX"];

const ExportMessageButton = ({
  styleProp,
  digitaltwinId,
  meeterId,
  params,
}) => {
  const url = `${window.BASE_URL}/api/v1/altior/download/digital_twin/${digitaltwinId}/instance/${meeterId}/message/export?`;
  const bearer = localStorage.getItem("user_token");
  const [open, setOpen] = React.useState(false);
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [uuid, setUUID] = useState();
  const [startDownload, setStartDownload] = useState(false);
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const [
    startExportMessage,
    {
      isLoading: isDeviceExportLoading,
      isSuccess: isDeviceExportSuccess,
      isError: isDeviceExportError,
      data: resultStartExport,
      requestId,
    },
  ] = useLazyStartExportMessageQuery();
  const { t } = useTranslation();
  const { isWSConnected, subscribedChannels, data, error, channels } =
    useSelector((state) => state.iwdWebSocketSlice);

  useEffect(() => {
    console.log("resultStartExport", resultStartExport);
  }, [resultStartExport]);
  useEffect(() => {
    if (isWSConnected && !channels["export_status"]) {
      console.log("Join Channell export_status");
      let requestUuid = uuidv4();
      setUUID(requestUuid);

      dispatch(
        actions.joinChannel({
          name: "export_status",
          params: { uuid: requestUuid },
        })
      );
    }
  }, [isWSConnected]);
  useEffect(() => {
    console.log("meeterID", meeterId);
  }, [meeterId]);
  useEffect(() => {
    console.log("resdata1", data);
    console.log("resdata2(datauuid)", data?.uuid);
    console.log("resdata3(uuid)", uuid);
    console.log("resdata4(datastatus)", data?.status);
  }, [data, uuid, data?.uuid, data?.status]);
  useEffect(() => {
    debugger;
    if (
      data &&
      data.data_update?.uuid == uuid &&
      data?.data_update?.status == "ready"
    ) {
      const copySearchParams = new URLSearchParams(params);
      copySearchParams.append("download_type", "excel");
      copySearchParams.append("uuid", uuid);
      copySearchParams.append("token", bearer);
      window.open(`${url}${copySearchParams.toString()}`, "_blank");
      dispatch(loadingOff());
    }
  }, [data, data.data_update?.uuid, data.data_update?.status]);

  useEffect(() => {
    if (data) {
      console.log("datares", data);
    }
  }, [data]);
  useEffect(() => {
    if (!isWSConnected) {
      dispatch(actions.connect());
    }
  }, [isWSConnected]);

  useEffect(() => {
    if (
      channels["export_status"] &&
      (!subscribedChannels["export_status"] ||
        !subscribedChannels["export_status"]["data_update"])
    ) {
      dispatch(
        actions.subscribeChannel({
          channelName: "export_status",
          eventName: "data_update",
        })
      );

      console.log("Subscribe evnet export_status => data_update");
      if (startDownload) {
        const copySearchParams = new URLSearchParams(params);

        copySearchParams.append("download_type", "excel");
        copySearchParams.append("uuid", uuid);
        startExportMessage({
          params: copySearchParams,
          digitalTwinId: digitaltwinId,
          meeterId: meeterId,
        });
        setStartDownload(false);
      }
    }
  }, [channels]);

  useEffect(() => {
    if (!isDeviceExportLoading) {
      switch (resultStartExport) {
        case "already_created":
          const copySearchParams = new URLSearchParams(params);
          copySearchParams.append("download_type", "excel");
          copySearchParams.append("uuid", uuid);
          copySearchParams.append("token", bearer);
          window.open(`${url}${copySearchParams.toString()}`, "_blank");
          dispatch(loadingOff());
          break;
        case "creating":
          dispatch(loadingOn());
          break;
        default:
          break;
      }
    }
  }, [
    isDeviceExportSuccess,
    isDeviceExportLoading,
    requestId,
    resultStartExport,
  ]);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);

    dispatch(loadingOn());
    switch (selectedIndex) {
      case 0:
        if (!isWSConnected) {
          dispatch(actions.connect());
          setStartDownload(true);
        } else {
          const copySearchParams = new URLSearchParams(params);
          copySearchParams.append("download_type", "excel");
          copySearchParams.append("uuid", uuid);

          startExportMessage({
            params: copySearchParams,
            digitalTwinId: digitaltwinId,
            meeterId: meeterId,
          });
        }

        break;

      default:
        alert("Nothing to do");
        break;
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    //clean up

    return () => {
      dispatch(actions.cleanData("data_update"));
      dispatch(actions.leaveChannel("export_status"));
    };
  }, []);

  return (
    <>
      {bearer && (
        <>
          <ModalDialog open={loading} title={t("note")} cancell={false}>
            <Box>{t("exporting_data")}</Box>
          </ModalDialog>
          <Box sx={styleProp}>
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button onClick={handleClick}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </>
      )}
    </>
  );
};

export default ExportMessageButton;
