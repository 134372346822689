import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import DeviceSearchBar from "../devices/DeviceSearchBar";
import { useSearch } from "../../components/SearchProvider";
import { useTranslation } from "react-i18next";
import IwdTable, { useIwdTable } from "./../../components/IwdTable";
import useFetch from "use-http";
import Loader from "../../components/Loader";
import { convertParamsToString } from "../../utils";
import { Checkbox } from "@mui/material";
import { useDeviceGroup } from "../../components/DeviceGroupProvider";

const SearchDevice = ({ groupId, ...attrs }) => {
  const { setSearchMode, mode, searchParams } = useSearch();
  const { addOrRemoveDevice, selectedDevices, setSelectedDevices } =
    useDeviceGroup();
  const { t } = useTranslation();
  const {
    page,
    pages,
    onChangePage,
    onPageNext,
    onPagePrev,
    setPage,
    setPages,
  } = useIwdTable();

  const [params, setParams] = useState({ per_page: 10, page: 1 });
  const [devices, setDevices] = useState();
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (mode !== "search") {
      setSearchMode();
    }
  }, [mode]);
  const { get, loading, error } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}/api/v1`
  );
  const columns = [
    {
      name: () => {
        return (
          <Checkbox
            checked={selectAll || selectedDevices.length >= devices.data.length}
            onChange={() => {
              if (selectAll) {
                setSelectAll(false);
                setSelectedDevices([]);
              } else {
                setSelectAll(true);
                setSelectedDevices(devices.data);
              }
            }}
          />
        );
      },
      selector: (row) => {
        return (
          <Checkbox
            checked={
              selectedDevices.findIndex((item) => item.id === row.id) >= 0
            }
            onClick={() => addOrRemoveDevice(row)}
          />
        );
      },
    },
    {
      name: t("id"),
      selector: (row) => row.id,
    },
    {
      name: t("serialNumber"),
      selector: (row) => row.attributes.serial_number,
    },
    {
      name: t("class"),
      selector: (row) => row.attributes.class,
    },
    {
      name: t("inserted_at"),
      selector: (row) => row.attributes.inserted_at,
    },
  ];

  useEffect(() => {
    if (params.page && mode && Object.keys(params).length >= 2) {
      if (groupId) {
        get(
          `/altior/device?not_in_group=${groupId}&${convertParamsToString(
            params
          )}`
        ).then((value) => setDevices(value));
      } else {
        get(`/altior/device?${convertParamsToString(params)}`).then((value) =>
          setDevices(value)
        );
      }
    }
  }, [params]);

  useEffect(() => {
    if (params.page && params.page !== page) {
      setParams({ ...params, page: page });
    } else {
      switch (mode) {
        case "search":
          setParams({
            ...searchParams,
            pages: [1],
            page: 1,
          });
          setPage(1);
          setPages([1]);
          break;
        default:
          break;
      }
    }
  }, [searchParams, page, mode]);
  if (loading || devices == null) {
    return <Loader />;
  }

  if (error) {
  }

  return (
    <Box {...attrs}>
      <Box sx={{ marginBottom: 3 }}>
        <DeviceSearchBar />
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        <IwdTable
          columns={columns}
          loading={loading}
          data={devices}
          onPagePrev={onPagePrev}
          onPageNext={onPageNext}
          onChangePage={onChangePage}
          page={page}
          pages={pages}
          count={devices.meta.count}
        />
      </Box>
    </Box>
  );
};

export default SearchDevice;
