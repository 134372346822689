import validator from "@rjsf/validator-ajv6";
/* import validator from "@rjsf/validator-ajv8"; */
import { Form } from "@rjsf/mui";
import { ErrorListProps } from "@rjsf/utils";
import Point from "./formComponents/Point";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

import MuiAlert from "@mui/material/Alert";
import { unSetDigitalTwin } from "../../redux/slices/digitalTwinSlice";
import Snackbar from "@mui/material/Snackbar";
import DigitalTwinImage from "./formComponents/DigitalTwinImage";

import { Button, Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { logout } from "../../redux/slices/logoutSlice";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import SearcheableSelect from "./formComponents/SearcheableSelect";
import { usePostDigitalTwinInstanceMutation } from "../../redux/slices/QdtiSlice";
import SearchOnServer from "./formComponents/SearchOnServer";
import ListItemText from "@mui/material/ListItemText";
import ErrorsHandler from "../../utils/ErrorsHandler";

/* import { postDigitalTwinInstance } from "../../redux/slices/ditigalTwinInstance"; */

import { Box } from "@mui/system";

import { useEffect, useState } from "react";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const MeterCreate = () => {
  const navigate = useNavigate();
  const [meterId, setMeterId] = useState();
  const dispatch = useDispatch();

  const [postDigitalTwinInstance, { data, isLoading, error }] =
    usePostDigitalTwinInstanceMutation();
  /*  const errors =
    error &&
    Object?.entries(error?.data?.errors)?.map(
      (item) => `${item?.[0]} : ${item?.[1]}`
    ); */

  const [open, setOpen] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const diameter = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.attributes?.digital_twin_fields?.diameter;
  const identifireType = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.attributes?.digital_twin_fields?.identifier_type;
  const digitalTwinId = useSelector(
    (state) => state.digitalTwinSlice.digitalTwin
  )?.[0]?.id;
  const imageId =
    useSelector((state) => state.digitalTwinSlice.digitalTwin)?.[0]?.attributes
      ?.digital_twin_fields?.image_id ?? null;
  const classStateSelector = ["classesSlice", "classes"];

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });

  const schm = {
    title: "New Device",
    type: "object",
    properties: {
      serial: {
        $id: "1",
        type: "string",
        length: "10",
        title: t("serial"),
      },
      key: {
        $id: "2",
        type: "string",
        title: t("key"),
      },
      pod: {
        $id: "3",
        type: "string",
        title: t("POD"),
      },
      address: {
        $id: "4",
        type: "string",
        title: t("street_address"),
      },

      dataConfigurazione: {
        $id: "5",
        type: "string",
        format: "date-time",
        title: t("configuration_date"),
      },

      time_zone: {
        $id: "6",
        type: "string",
        title: t("time_zone"),
        url: "time_zone",
        defaultTimeZone: true,
        default: "Europe/Rome",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item?.attributes?.key,
            label: item?.attributes?.name,
            value: item?.attributes?.id,
          })),
      },
      digital_twin: {
        $id: "7",

        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item.attributes?.id,
            label: `${item.attributes?.name} (${item.attributes?.version})`,
            value: `${item.attributes?.name} (${item.attributes?.version})`,
          })),
        /*    stateSelector: classStateSelector, */

        url: "altior/digital_twin",
        type: "string",
        title: t("digital_twin"),
        dispatched: true,
      },
      reverse: {
        $id: "8",
        type: "boolean",
        title: t("mounted_reverse"),
      },

      tags: {
        $id: "10",
        title: t("tag"),
        type: "array",
        url: "tag",
        multiple: true,

        properties: {
          tags: {
            type: "number",
          },
        },
      },
      coordinates: {
        $id: "12",
        type: "object",
        properties: {
          lat: {
            $id: "28",
            type: "number",
            default: 0,
          },
          lng: {
            $id: "29",
            type: "number",
            default: 0,
          },
        },
      },
      note: {
        $id: "13",
        type: "string",
        title: t("notes"),
      },
      diameter: {
        $id: "11",
        type: "string",
        title: t("diameter"),
        readOnly: true,
      },
    },
    required: [
      "key",
      "serial",
      "pod",
      "address",
      "coordinates",
      "tags",
      "time_zone",
      "digital_twin",
    ],
  };
  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "digital_twin",

      "serial",
      "key",
      "pod",
      "address",
      "dataConfigurazione",
      "time_zone",
      "coordinates",

      "tags",
      "diameter",
      "reverse",

      "note",
      "image",
    ],

    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    coordinates: {
      "ui:field": "point",
    },
    time_zone: {
      "ui:field": "search",
    },
    id_type: {
      "ui:field": "search",
    },
    note: {
      "ui:options": {
        widget: "textarea",
        minRows: 12,
      },
    },
    digital_twin: {
      "ui:field": "search",
    },

    tags: {
      "ui:field": "serverSearch",
    },
    image: {
      "ui:field": "image",
    },
  };
  const [uiSchema, setUiSchema] = useState(uiSchm);
  const [schema, setSchema] = useState(schm);
  const customValidate = (formData, errors) => {
    if (formData?.tags?.length < 1) {
      errors?.tags?.addError("is a required property");
    }
    return errors;
  };
  useEffect(() => {
    console.log("formdata", formData);
  }, [formData]);

  useEffect(() => {
    console.log("erroooor", error);
  }, [error]);

  const onSubmitClick = (formData) => {
    const body = {
      data: {
        attributes: {
          fields: {
            class: "",
            active_network_adapters: [],
            network_adapters: [],
            identifier_type: identifireType,
            digital_twin_id: digitalTwinId?.toString(),

            ...formData,

            coordinates: {
              coordinates: [
                formData?.coordinates?.lng,
                formData?.coordinates?.lat,
              ],
              type: "Point",
            },
          },
        },
        type: "plugin",
      },
    };

    postDigitalTwinInstance(body);
    setOpenErr(true);
  };

  useEffect(() => {
    if (data) {
      setMeterId(data?.data?.id);
    }
  }, [data]);
  useEffect(() => {
    if (meterId) {
      navigate(`/meters/${meterId}`);
    }
  }, [meterId]);

  useEffect(() => {
    console.log("ITYPE", identifireType);
  }, [identifireType]);

  useEffect(() => {
    if (formData?.digital_twin) {
      setFormData((prev) => ({
        ...prev,
        diameter: diameter,
      }));

      if (identifireType) {
        const props = { ...schema.properties };
        const required = [...schema.required, identifireType];
        props[identifireType] = {
          $id: "16",
          type: "string",
          title: t(identifireType),
        };
        props["image"] = {
          $id: "17",
          type: "string",
          imageId: imageId,
        };

        const newSchema = { ...schema, properties: props, required };
        setSchema(newSchema);
      }
    }
  }, [formData?.digital_twin]);

  useEffect(() => {
    if (identifireType) {
      const order = { ...uiSchema };
      order["ui:order"] = [
        "digital_twin",
        identifireType,

        "serial",
        "key",
        "pod",
        "address",
        "dataConfigurazione",
        "time_zone",
        "coordinates",

        "tags",
        "diameter",
        "reverse",

        "note",
        "image",
      ];
      const newUiSchema = { ...uiSchema, ...order };

      setUiSchema(newUiSchema);
    }
  }, [identifireType]);

  useEffect(() => {
    return () => dispatch(unSetDigitalTwin());
  }, []);

  useEffect(() => {
    if (error && openErr) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenErr(false);
  };

  /*   const widgets = {
    point: Point,
    search: SearcheableSelect,
    serverSearch: SearchOnServer,
  }; */
  const fields = {
    point: Point,
    search: SearcheableSelect,
    serverSearch: SearchOnServer,
    image: DigitalTwinImage,
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick(values.formData)}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        showErrorList={false}
        customValidate={customValidate}
        noHtml5Validate

        /*  templates={{ ErrorListTemplate }} */
      />
      <ErrorsHandler />
    </Box>
  );
};

export default MeterCreate;
