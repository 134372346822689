import { api } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signout: build.mutation({
      query: () => ({
        url: "v1/logout",
        method: "DELETE",
      }),
    }),
  }),
});

export const { useSignoutMutation } = authApi;
