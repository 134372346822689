import TextField from "@mui/material/TextField";
import { Button } from "@material-ui/core";
import { useState } from "react";

const TestLik = () => {
  const [link, setLink] = useState("");
  const onGoClick = () => {
    window.open(`//${link}`, "_blank");
  };
  return (
    <>
      <TextField onChange={(e) => setLink(e.target.value)} />
      <Button onClick={onGoClick}>Go</Button>
    </>
  );
};

export default TestLik;
