import React, { useEffect } from "react";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import CustomerEndpointProvider, {
  useCustomerEndpoint,
} from "../components/customer_endpoint/CustomerEndpointProvider";
import CustomerEndpointsComponent from "../components/customer_endpoint/CustomerEndpointsComponent";

const CustomerEndpoints = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <CustomerEndpointProvider>
        <Box>
          <h1> {t("customerEndpoints")}</h1>
        </Box>
        <CustomerEndpointsComponent></CustomerEndpointsComponent>
      </CustomerEndpointProvider>
    </Box>
  );
};

export default CustomerEndpoints;
