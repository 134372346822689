import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";

const PressureSensorShow = ({ device, categories }) => {
  const { t } = useTranslation();

  if (device == null) {
    return <Box>{t("Loading...")}</Box>;
  }

  var cardStyle = {
    display: "block",
    minWidth: "10vw",
    transitionDuration: "0.3s",
    height: "10vw",
    backgroundColor: "#e8eaf6",
  };

  const deviceFields = device["data"].attributes.fields;
  const baseArray = categories["base"];

  const renderSwitch = (name) => {
    switch (name) {
      case "pressure":
      case "temeperature":
        return (
          <Grid item>
            <Card sx={cardStyle}>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {t(name)}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h4" align="center">
                  {deviceFields[name] ? deviceFields[name] : "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );

      case "push_interval":
      case "uptime":
        return (
          <Grid item>
            <Card sx={cardStyle}>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {t(name)}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h4" align="center">
                  {deviceFields[name]
                    ? JSON.stringify(deviceFields[name])
                    : "-"}{" "}
                  s
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );

      case "state_flag":
        const bgcolor = deviceFields[name].leak ? "warning.main" : "";
        // const bgcolor = ''
        let custom = Object.assign({}, cardStyle, { bgcolor: bgcolor });
        debugger;

        return (
          <Grid item>
            <Card sx={custom}>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {" "}
                  leak{" "}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h4" align="center">
                  {deviceFields[name].leak
                    ? JSON.stringify(deviceFields[name].leak)
                    : "-"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );

      case "cable_length":
      case "cable_resistivity":
      case "leak_position":
        return (
          <Grid item>
            <Card sx={cardStyle}>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {name}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h4" align="center">
                  {deviceFields[name].value
                    ? JSON.stringify(deviceFields[name].value)
                    : "-"}{" "}
                  {deviceFields[name].unit ? deviceFields[name].unit : ""}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      default:
        return;
    }
  };

  const cable_data = baseArray?.map(({ attributes: { name } }) => {
    return renderSwitch(name);
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {cable_data}
      </Grid>
    </Box>
  );
};

export default PressureSensorShow;
