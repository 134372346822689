import { api } from "./api";
import { prepareBodyWithoutId } from "../../utils/api_params";
const type = "dex";
export const dexApi = api.injectEndpoints({
  endpoints: (build) => ({
    postDex: build.mutation({
      query: (attributes) => ({
        url: "v1/dex",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, type)),
      }),
    }),
  }),
});

export const { usePostDexMutation } = dexApi;
