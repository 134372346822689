import React, { useState, useEffect } from "react";
import { TileLayer, Marker, Popup, ScaleControl, useMap } from "react-leaflet";
import useFetch from "use-http";
import { useAuth } from "../../components/AuthProvider";
import L from "leaflet";
import DeviceSvg from "../../statics/icons/device.svg";
import ClusterMarker from "./ClusterMarker";
import {
  prepareInAreaDevicesQueryString,
  prepareInAreaClustersQueryString,
  prepareInAreaQueryString,
  convertParamsToString,
  getDistance,
} from "../../utils";
import { useDebouncedCallback } from "use-debounce";
import { useSearch } from "../../components/SearchProvider";
import SelectedDeviceInMap from "./SelectdDeviceInMap";

const DevicesMapComponent = (props) => {
  const deviceIcon = () =>
    new L.icon({
      iconUrl: DeviceSvg,
      iconSize: 20,
    });

  const searcher = useSearch();
  const auth = useAuth();
  const [clusterQueryPrams, setClusterQueryParams] = useState({});
  const [deviceQueryPrams, setDeviceQueryParams] = useState({});
  const [devices, setDevices] = useState();
  const [clusters, setClusters] = useState();
  const [userPreferences, setUserPreferences] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const map = useMap();
  const { get, loading, error } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}/api/v1`
  );

  const debounced = useDebouncedCallback((bounds, zoomLevel) => {
    if (!searcher || searcher.mode != "show") {
      loadMapItems(bounds, zoomLevel);
    }
  }, 500);
  const setCenter = useDebouncedCallback((center) => {
    auth.addOrUpdateUserPreference("mapCenter", center, () => {});
  }, 3000);

  const getDevices = async () => {
    if (Object.keys(deviceQueryPrams).length > 0) {
      const devicesResponse = await get(
        `/altior/device?${convertParamsToString(deviceQueryPrams)}`,
        (globalOptions) => {
          globalOptions.cachePolicy = "no-cache";
          return globalOptions;
        }
      );
      setDevices(devicesResponse.data);
    }
  };

  const getClusters = async () => {
    if (Object.keys(clusterQueryPrams).length > 0) {
      const clustersRepsonse = await get(
        `/altior/device/cluster?${convertParamsToString(clusterQueryPrams)}`
      );
      setClusters(clustersRepsonse.data);
    }
  };

  const loadMapItems = (bounds) => {
    const distance = getDistance(bounds);
    searcher.setMapParamsAndMode(prepareInAreaQueryString(bounds));
    setDeviceQueryParams(prepareInAreaDevicesQueryString(bounds, distance));
    setClusterQueryParams(prepareInAreaClustersQueryString(bounds, distance));
  };
  useEffect(() => getDevices, [deviceQueryPrams]);
  useEffect(() => getClusters, [clusterQueryPrams]);

  useEffect(() => {
    if (map) {
      loadMapItems(map.getBounds());
      map.on("zoomend", () => debounced(map.getBounds()));
      map.on("moveend", () => {
        debounced(map.getBounds());
        setCenter(map.getCenter());
      });
    }
  }, [map]);

  return (
    <>
      <div>
        <ScaleControl />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {devices &&
          devices.map((device) => (
            <Marker
              icon={deviceIcon()}
              key={device.id}
              position={[
                device.attributes.coordinates.coordinates[1],
                device.attributes.coordinates.coordinates[0],
              ]}
            >
              <Popup>{device.attributes.serial_number}</Popup>
            </Marker>
          ))}
        {clusters &&
          clusters.map((cluster) => (
            <ClusterMarker cluster={cluster} params={deviceQueryPrams} />
          ))}
        <SelectedDeviceInMap />
      </div>
    </>
  );
};

export default DevicesMapComponent;
