import { api } from "./api";

export const meterReadingGraphApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMeterReadingGraph: build.query({
      query: ({ digitalTwinId, deviceId, from, to }) => {
        return `v1/altior/digital_twin/${digitalTwinId}/instance/${deviceId}/message/graph?from=${from}&to=${to}`;
      },
      transformResponse: (response) => {
        const value = response.data.map(({ attributes }) => {
          return {
            oneDay: attributes.one_day,
            reading: parseFloat(attributes.reading),
          };
        });

        return value;
      },
    }),
    extraOptions: { maxRetries: 1 },
  }),
});

export const { useLazyGetMeterReadingGraphQuery } = meterReadingGraphApi;
