import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import InputText from "../../components/form/InputText";
import { useForm } from "react-hook-form";
import { useDeviceGroup } from "../../components/DeviceGroupProvider";

const DeviceGroupForm = ({ onSubmit, value, ...attrs }) => {
	const { t } = useTranslation();
	const currentForm = useRef();
	const { group, setGroup, setSubmit } = useDeviceGroup();
	const {
		handleSubmit,
		control,
		formState: { errors },
		trigger,
	} = useForm();
	const remoteSubmit = () => {
		if (currentForm && currentForm.current) {
			currentForm.current.dispatchEvent(
				new Event("submit", { cancelable: true, bubbles: true })
			);
		} else {
			if (onSubmit) {
				onSubmit();
			}
		}
	};

	useEffect(() => {
		setSubmit(() => remoteSubmit);
	}, [control]);

	const onInternalSubmit = (data) => {
		if (onSubmit) {
			onSubmit(data);
		}
	};

	const handleNameChange = (e) => {
		setGroup({ ...group, name: e.target.value });
	};
	const handleDescriptionChange = (e) => {
		setGroup({ ...group, description: e.target.value });
	};

	return (
		<Box {...attrs}>
			<form onSubmit={handleSubmit(onInternalSubmit)} ref={currentForm}>
				<Box sx={{ marginBottom: 2 }}>
					<InputText
						name={t("name")}
						control={control}
						value={group.name}
						onChange={handleNameChange}
						rules={{ required: true }}
						error={errors.name}
					/>
				</Box>
				<Box sx={{ marginBottom: 2 }}>
					<InputText
						name={t("description")}
						control={control}
						value={group.description}
						onChange={handleDescriptionChange}
						multiline={true}
						rows={5}
						maxRows={5}
						error={errors.description}
					/>
				</Box>
			</form>
		</Box>
	);
};

export default DeviceGroupForm;
