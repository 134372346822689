import React from "react";
import DeviceForm from "../../components/DeviceForm";
import { useNavigate } from "react-router-dom";
import { useDigitalTwin } from "../../components/DigitalTwinSelect";
import Loader from "../../components/Loader";
import useFetch from "use-http";
import { createPostRequest } from "../../utils/api_params";
import ApiError from "../../components/ApiError";

const DeviceCreateForm = () => {
  const navigate = useNavigate();
  const { digitalTwinAttributes, loadingDigitalTwin, loadingDigitalTwinError } =
    useDigitalTwin();
  const { loading, error, post } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`
  );

  const onSubmit = async ({ digitalTwinId, data }) => {
    data["digital_twin_id"] = digitalTwinId;
    if (data.tags) {
      data.tags = data.tags.split(",");
    }
    const body = createPostRequest({ fields: data, meta: {} }, "devices");
    const result = await post(
      `/api/v1/altior/digital_twin/${digitalTwinId}/instance`,
      body
    );
    if (result.data && result.data.id) {
      navigate(`/devices/${result.data.id}`, { replace: true });
    }
  };

  if (loadingDigitalTwin || loading) {
    return <Loader />;
  }

  return (
    <>
      {(loadingDigitalTwinError || error) && <ApiError error={error} />}
      {digitalTwinAttributes ? (
        <DeviceForm
          attributes={digitalTwinAttributes.data}
          onSubmit={onSubmit}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default DeviceCreateForm;
