import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { List, ListItem } from "@mui/material";

export default function WaterLeakCableLogs({ logs }) {
  const { t } = useTranslation();
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{t("logs_history")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {logs.map(({ data, distance }) => (
              <ListItem disablePadding>
                {t("dt", { val: new Date(data) })}: {distance}
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
