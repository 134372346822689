import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { setDexPage } from "../../redux/slices/dexDataSourceSlice";

const DexTable = (/* { data, loading } */) => {
  const dexData = useSelector((state) => state?.dexDataSourceSlice?.dex);
  const rowCount = useSelector((state) => state?.dexDataSourceSlice?.count);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const page = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.page
  );
  const perPage = useSelector(
    (state) => state?.dexDataSourceSlice?.dexAttributes?.per_page
  );
  if (dexData) {
    if (dexData.length > 0) {
      const columns = Object.keys(dexData[0]).map((column) => {
        return {
          field: column,
          headerName: t(column),
          editable: false,
          sortable: false,
          hide: column == "id",
          flex: 7,
          renderCell: (params) => {
            if (typeof params.value === "boolean") {
              return params.value ? <ErrorIcon sx={{ fill: "red" }} /> : "--";
            }

            if (typeof params.value === "object" && params.value) {
              return (
                <ul style={{ padding: 0 }}>
                  {Object.keys(params.value).map((k) => (
                    <li>
                      {k}: {params.value[k]}
                    </li>
                  ))}
                </ul>
              );
            }
            // if (typeof params.value === "string") {
            //   const floatValue = parseInt(params.value)
            //   if (!isNaN(floatValue)) {
            //     return floatValue
            //   }
            //   const dateTime = new Date(params.value)

            //   if (params.value && !isNaN(dateTime.getDate())) {
            //     return t("dt", { val: dateTime })
            //   }
            // }
            return params.value;
          },
        };
      });

      const tableRows = dexData?.map((item, key) => ({
        id: key,
        device_id: item?.device_id,
        in_error: item?.in_error,
        inserted_at: t("dt", { val: new Date(item?.inserted_at) }),
        message_date: t("dt", { val: new Date(item?.message_date) }),
        parent_device_id: item?.parent_device_id,
        unit: item?.unit,
        volume: item?.volume,
      }));

      return (
        <Box sx={{ height: 600 }}>
          <DataGrid
            /* getRowHeight={() => "auto"} */
            rows={tableRows}
            columns={columns}
            pageSize={perPage}
            rowCount={rowCount}
            page={page - 1}
            rowsPerPageOptions={[perPage]}
            onPageChange={(pageNumber) => {
              dispatch(setDexPage(pageNumber + 1));
            }}
            disableSelectionOnClick
            /*    loading={loading} */
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            paginationMode="server"
            /* components={{ Toolbar: GridToolbar }} */
          />
        </Box>
      );
    } else {
      return <Box> {t("no_results")}</Box>;
    }
  }
  return <></>;
};

export default DexTable;
