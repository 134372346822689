import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";

import { useParams } from "react-router-dom";
import { useCustomerEndpoint } from "./CustomerEndpointProvider";
import Loader from "../Loader";
import CustomerEndpointForm from "./CustomerEndpointFrom";
import ApiError from "../ApiError";
import IwdSnackBar from "../IwdSnackBar";

const CustomerEndpointUpdateComponent = ({ customerEndpointId: id }) => {
  const { t } = useTranslation();
  const { loading } = useFetch(`${process.env.REACT_APP_SWARM_BASEURL}/api/v1`);
  const [showMessage, setShowMessage] = useState(false);
  const {
    getCustomerEndpoint,
    customerEndpoint,
    error,
    updateCustomerEndpoint,
  } = useCustomerEndpoint();

  useEffect(() => {
    if ((!customerEndpoint || customerEndpoint.id != id) && id) {
      getCustomerEndpoint(id);
    }
  }, [customerEndpoint]);

  if (loading && (!customerEndpoint || customerEndpoint.id != id)) {
    return <Loader />;
  }

  return (
    <Box>
      {error ? <ApiError error={error}></ApiError> : <></>}
      {showMessage ? (
        <IwdSnackBar
          severity="success"
          isOpen={showMessage}
          message={t("updated_success")}
        />
      ) : (
        <></>
      )}
      <CustomerEndpointForm
        data={customerEndpoint}
        buttonLabel={t("update")}
        action={(formData) => {
          setShowMessage(false);
          updateCustomerEndpoint(formData, ({ action, data }) => {
            if (action == "updated") {
              setShowMessage(true);
            }
          });
        }}
      ></CustomerEndpointForm>
    </Box>
  );
};

export default CustomerEndpointUpdateComponent;
