import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/slices/iwdWebSocketSlice";
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import WifiIcon from "@mui/icons-material/Wifi";
import { useTranslation } from "react-i18next";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { set } from "lodash";
const WsConnector = ({
  channelName,
  channelParams,
  eventName,
  setDataFunction,

  style,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    isWSConnected,
    data,
    error,
    channels,
    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);

  useEffect(() => {
    if (!isWSConnected) {
      dispatch(actions.connect());
    }
  }, [isWSConnected]);
  useEffect(() => {
    if (isWSConnected) {
      dispatch(
        actions.joinChannel({
          name: channelName,
          params: channelParams,
        })
      );
    }
  }, [isWSConnected]);
  useEffect(() => {
    if (Object.keys(channels).length > 0) {
      dispatch(
        actions.subscribeChannel({
          channelName: channelName,
          eventName: eventName,
        })
      );
    }
  }, [channels]);
  useEffect(() => {
    if (data[eventName] && setDataFunction) {
      dispatch(setDataFunction(data[eventName]));
    }
  }, [data[eventName]]);
  useEffect(() => {
    return () => {
      dispatch(actions.cleanData(eventName));
    };
  }, []);

  return (
    <>
      {isWSConnected ? (
        <Tooltip title={`${t("webSocket")}: ${t("connected")} `}>
          <WifiIcon sx={style} />
        </Tooltip>
      ) : (
        <Tooltip title={`${t("webSocket")}: ${t("no_connection")}`}>
          <WifiOffIcon sx={style} />
        </Tooltip>
      )}
    </>
  );
};

export default WsConnector;
