import { StarRateTwoTone } from "@material-ui/icons";
import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
  isWSConnected: false,
  isChannelJoined: false,
  data: {},
  error: null,
  webSocket: null,
  channels: {},
  subscribedChannels: {},
  loading: false,
};

const iwdWebSocketSlice = createSlice({
  name: "iwdWebSocketSlice",
  initialState,
  reducers: {
    connect(state, action) {
      state.loading = true;
      state.error = null;
    },

    connected(state, action) {
      state.webSocket = action.payload;
      state.loading = false;
      state.isWSConnected = true;
    },
    joinChannel(state, action) {
      state.loading = false;
      state.error = null;
    },
    joinedChannel(state, action) {
      state.loading = false;
      let channels = { ...state.channels };
      channels[action.payload.name] = action.payload.value;
      state.channels = channels;
    },
    subscribeChannel(state, action) {
      state.loading = true;
      state.error = null;
    },
    subscribedChannel(state, action) {
      state.loading = false;
      let subscribedChannels = { ...state.subscribedChannels };
      let events = subscribedChannels[action.payload.channelName];
      let newEvents = [action.payload.eventName];
      if (events) {
        newEvents = [action.payload.eventName, ...events];
      }
      subscribedChannels[action.payload.channelName] = newEvents;
      state.subscribedChannels = subscribedChannels;
    },
    unsuscribeChannel(state, action) {
      state.loading = true;
      state.error = null;
    },
    unsuscribedChannel(state, action) {
      state.loading = false;
      let subscribedChannels = { ...state.subscribedChannels };
      let events = subscribedChannels[action.payload.channelName];
      const indexToDelete = events?.indexOf(action.payload.eventName);
      events?.splice(indexToDelete, 1);
      subscribedChannels[action.payload.channelName] = events;
      state.subscribedChannels = subscribedChannels;
    },
    setData(state, action) {
      /*   if (action.payload.eventName == "data_update") {
        state.data.dashboardData = action.payload.data;
      } */
      state.data[action.payload.eventName] = action.payload.data;
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    leaveChannel(state, action) {
      state.loading = true;
      state.error = null;
    },
    leavedChannel(state, action) {
      state.loading = false;
      let subscribedChannels = { ...state.subscribedChannels };
      let channels = { ...state.channels };
      delete subscribedChannels[action.payload];
      delete channels[action.payload];
      state.channels = channels;
      state.subscribedChannels = subscribedChannels;
    },
    disconnect(state, action) {
      state.loading = true;
      state.error = null;
    },
    disconnected(state, action) {
      state.loading = false;
      state.isWSConnected = false;
      state.channels = [];
      state.subscribedChannels = [];
      state.webSocket = null;
      state.error = null;
      state.data = null;
    },
    loading(state, action) {
      state.loading = true;
    },
    cleanData(state, action) {
      state.data[action.payload] = null;
    },
  },
});

export const actions = iwdWebSocketSlice.actions;
export default iwdWebSocketSlice.reducer;
