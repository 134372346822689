import { api } from "./api";
import { prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";
import { method } from "lodash";

export const connectionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConnections: build.query({
      query: (params) => {
        return `v1/plugin/connection?${prepareParams(params)}`;
      },
    }),
    getConnection: build.query({
      query: (id) => {
        return `v1/plugin/connection/${id}`;
      },
    }),
    createConnection: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/connection",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
    updateConnection: build.mutation({
      query: ({ attributes, id }) => ({
        url: `v1/plugin/connection/${id}`,
        method: "PATCH",
        body: JSON.stringify(prepareBody(attributes, id, "plugin")),
      }),
    }),
    deleteConnection: build.mutation({
      query: (id) => ({
        url: `v1/plugin/connection/${id}`,
        method: "DELETE",
      }),
    }),
    startConnection: build.mutation({
      query: ({ id, attributes }) => ({
        url: `v1/plugin/connection/${id}/start`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
    stopConnection: build.mutation({
      query: ({ id, attributes }) => ({
        url: `v1/plugin/connection/${id}/stop`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
      }),
    }),
  }),
});

export const {
  useLazyGetConnectionsQuery,
  useLazyGetConnectionQuery,
  useCreateConnectionMutation,
  useUpdateConnectionMutation,
  useDeleteConnectionMutation,
  useStartConnectionMutation,
  useStopConnectionMutation,
} = connectionsApi;
