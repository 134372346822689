import { MapContainer } from "react-leaflet";
import FormMap from "./FormMap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Box } from "@mui/material";

import zIndex from "@mui/material/styles/zIndex";
const MapDevice = ({
  pos,
  hideAccordion,
  setPreferenceLoading,
  classValue,
}) => {
  const center = useSelector((state) => state?.userPreferenceSlice?.mapCenter);
  const zoom = useSelector((state) => state?.userPreferenceSlice?.mapZoom);
  let style = { height: hideAccordion ? "60vh" : "39vh", width: "99%" };
  const mapCenter = {
    lat: 44.833839087777484,
    lng: 11.826524734497072,
  };

  return (
    <Box sx={style}>
      <MapContainer
        style={{ height: "100%", zIndex: 0 }}
        id="show-device-map"
        center={center}
        zoom={zoom}
        minZoom={5}
        scrollWheelZoom={true}
      >
        <FormMap
          pos={pos}
          hideAccordion={hideAccordion}
          setPreferenceLoading={setPreferenceLoading}
          classValue={classValue}
        />
      </MapContainer>
    </Box>
  );
};
export default MapDevice;
