import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import logoutSlice from "./slices/logoutSlice";
import campaignSlice from "./slices/CampaignSlice";
import dashboardSlice from "./slices/dashboardSlice";
import iwdWebSocketSlice from "./slices/iwdWebSocketSlice";
import digitalTwinSlice from "./slices/digitalTwinSlice";
import widgetsSlice from "./slices/widgetsSlice";
import clusterSlice from "./slices/clusterSlice";
import meterSlice from "./slices/meterSlice";
import { digitalTwinInstanceRtk } from "./slices/QdtiSlice";
import { dataApi } from "./slices/GetDataRtq";
import getImageSlice from "./slices/getImageSlice";
import trilliantSlice from "./slices/trilliantSlice";
import socketMiddleware from "./middleware/socketMiddleware";
import meterReadingSlice from "./slices/meterReadingSlice";
import deviceMessageSlice from "./slices/deviceMessageSlice";
import userPreferenceSlice from "./slices/userPreferenceSlice";
import trashSlice from "./slices/trashSlice";
import statisticsSlice from "./slices/statisticsSlice";
import breadCrumbMeterSlice from "./slices/breadCrumbMeterSlice";
import connectionsSlice from "./slices/connectionsSlice";
import aegisSlice from "./slices/aegisSlice";
import messageSlice from "./slices/messageSlice";
import loadingSlice from "./slices/loadingSlice";
import utilsSlice from "./slices/utilsSlice";
import userSlice from "./slices/userSlice";
import dexDataSourceSlice from "./slices/dexDataSourceSlice";
import { api } from "./slices/api";
import deviceDistrictSlice from "./slices/deviceDistrictSlice";
import authSlice, { authReducer } from "./slices/authSlice";
import errorSlice from "./slices/errorSlice";
import gatewaySlice from "./slices/gatewaySlice";
import snackbarSlice from "./slices/snackbarSlice";
import uploadFileSlice from "./slices/uploadFileSlice";

const rootReducer = combineReducers({
  logoutSlice,
  snackbarSlice,
  gatewaySlice,
  utilsSlice,
  clusterSlice,
  deviceDistrictSlice,
  statisticsSlice,
  messageSlice,
  authSlice,
  breadCrumbMeterSlice,
  aegisSlice,
  userSlice,
  trashSlice,
  dashboardSlice,
  campaignSlice,
  userPreferenceSlice,
  getImageSlice,
  widgetsSlice,
  loadingSlice,
  connectionsSlice,
  errorSlice,
  /* ditigalTwinInstance, */
  digitalTwinSlice,
  iwdWebSocketSlice,
  meterReadingSlice,
  deviceMessageSlice,
  dexDataSourceSlice,
  [api.reducerPath]: api.reducer,
  [digitalTwinInstanceRtk.reducerPath]: digitalTwinInstanceRtk.reducer,
  [dataApi.reducerPath]: dataApi.reducer,
});
export const setupStore = (preloadState) => {
  return configureStore({
    reducer: rootReducer,
    preloadState,
  });
};
console.log("vahui", campaignSlice);
export const store = configureStore({
  reducer: {
    logoutSlice,
    snackbarSlice,
    utilsSlice,
    clusterSlice,
    deviceDistrictSlice,
    statisticsSlice,
    connectionsSlice,
    messageSlice,
    authSlice,
    breadCrumbMeterSlice,
    aegisSlice,
    userSlice,
    trashSlice,
    dashboardSlice,
    trilliantSlice,
    meterSlice,
    gatewaySlice,
    campaignSlice,
    userPreferenceSlice,
    getImageSlice,
    widgetsSlice,
    loadingSlice,
    uploadFileSlice,
    errorSlice,
    /* ditigalTwinInstance, */
    digitalTwinSlice,
    iwdWebSocketSlice,
    meterReadingSlice,
    deviceMessageSlice,
    dexDataSourceSlice,
    [api.reducerPath]: api.reducer,
    [digitalTwinInstanceRtk.reducerPath]: digitalTwinInstanceRtk.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(dataApi.middleware)
      .concat(digitalTwinInstanceRtk.middleware)
      .concat([socketMiddleware]),
});

export default store;
