import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import MapLeaflet from "./MapLeaflet";

import ManufacturerData from "./ManufacturerData";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import { useDispatch } from "react-redux";
import theme from "../../theme";
import MeterCommands from "./MeterCommands";
import Registri from "./Registry";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import MeterReadingsTab from "../meters/MeterReadingsTab";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const MeterShow = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [trigger, result, lastPromiseInfo] = useLazyGetDataQuery();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  /*   const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const identifierTypeValue = result?.data?.data?.attributes?.fields?.[key]; */
  const serial = result?.data?.data?.attributes?.fields?.serial;
  useEffect(() => {
    trigger(`altior/digital_twin/0/instance/${id}`);
  }, []);
  useEffect(() => {
    if (serial) {
      dispatch(setidentifierTypeValue(serial));
    }
  }, [serial]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // breadCrumb value clean up function

    return () => {
      dispatch(unsetValue());
    };
  }, []);

  return (
    <>
      {serial && (
        <>
          {" "}
          <Typography
            sx={{ color: theme.palette.primary.main }}
            variant="h3"
          >{`Meter: ${result?.data?.data?.attributes?.fields?.serial}`}</Typography>
          <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 2",
                width: "100%",
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                <Tab label={t("registry")} />
                <Tab label={t("manufacturer_data")} />
                <Tab label={t("readings")} />
                <Tab label={t("consumptions")} disabled />
                <Tab label={t("commands")} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Registri result={result} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ManufacturerData result={result} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MeterReadingsTab meter={result?.data?.data} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <MapLeaflet />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <MeterCommands
                  digitalTwinId={
                    result?.data?.data?.attributes?.digital_twin_id
                  }
                />
              </TabPanel>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default MeterShow;
