import React from "react";
import InputJSON from "./form/InputJSON";

const DeviceCommandSubForm = ({ commandType, control }) => {
	switch (commandType) {
		case "void":
			return <></>;
		case "map":
			return (
				<InputJSON
					control={control}
					item={{ attributes: { name: "params" } }}
				/>
			);

		default:
			return <></>;
	}
};

export default DeviceCommandSubForm;
