import { api } from "./api";
import { prepareBody } from "../../utils/api_params";
const type = "user_preference";
const id = "me";
export const userOTPApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteOTP: build.mutation({
      query: () => ({
        url: "v1/me/otp",
        method: "DELETE",
      }),
    }),
    createOTP: build.mutation({
      query: () => ({
        url: "v1/me/otp",
        method: "POST",

        body: JSON.stringify({
          data: {
            attributes: {},
            type: "otp",
          },
        }),
      }),
    }),
    verifyOTP: build.mutation({
      query: ({ code: code }) => ({
        url: "v1/me/otp/verify",
        method: "POST",

        body: JSON.stringify({
          data: {
            attributes: {
              code: code,
            },
            type: "otp",
          },
        }),
      }),
    }),
  }),
});

export const {
  useDeleteOTPMutation,
  useCreateOTPMutation,
  useVerifyOTPMutation,
} = userOTPApi;
