import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/mui";
import { useTranslation } from "react-i18next";
import { useLazyGetMeterReadingGraphQuery } from "../../redux/slices/meterReadingGraphApi";
import MeterReadingsGraph from "./MeterReadingsGraph";
import SearchIcon from "@mui/icons-material/Search";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { useLazyGetDeviceMessagesQuery } from "../../redux/slices/deviceMessageApi";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Chip } from "@material-ui/core";
import theme from "../../theme";

import ExportMessageButton from "../form/formComponents/ExportMessagesButton";

// import { clearData } from "../../redux/slices/meterReadingSlice";

const MeterReadingsTab = ({ meter }) => {
  const dispatch = useDispatch();

  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });

  const [formData, setFormData] = useState(1);
  const { t } = useTranslation();
  const now = new Date();
  const fromDate = new Date(now);
  fromDate.setDate(fromDate.getDate() - 7);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [to, setTo] = useState(now);
  const [from, setFrom] = useState(fromDate);
  const [getMeterReadingGraph, { isLoading }, lastPromiseInfo] =
    useLazyGetMeterReadingGraphQuery();
  const [
    getDeviceMessages,
    { isLoading: isLoadingTable },
    lastPromiseInfoTable,
  ] = useLazyGetDeviceMessagesQuery();
  const schema = {
    type: "number",
    oneOf: [
      { const: 1, title: t("1_week") },
      { const: 2, title: t("1_month") },
      { const: 3, title: t("3_month") },
      { const: 4, title: t("6_month") },
      { const: 5, title: t("last_year") },
    ],
  };

  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: <SearchIcon />,
      props: {
        className: "searchBtn",
      },
    },
  };

  const data = useSelector((state) => state.deviceMessageSlice.data);
  const meta = useSelector((state) => state.deviceMessageSlice.meta);
  useEffect(() => {
    console.log("messagedata", data);
  }, [[data]]);

  const columns = [
    {
      field: "messageDate",
      headerName: t("readTime"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return <>{t("dt", { val: new Date(params.row.messageDate) })}</>;
      },
    },

    {
      field: "strValue",
      headerName: t("volume"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      // valueFormatter: ({ value }) => {
      //   debugger;
      //   return value;
      // },
    },
    {
      field: "alarms",
      headerName: t("alarms"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.row.alarms === undefined) {
          return <>--</>;
        } else {
          return params.row.alarms.map((item) => (
            <Chip
              style={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
              }}
              label={item}
            />
          ));
        }
      },
      // valueFormatter: ({ value }) => value.message_date,
    },

    // data[2].attributes.values.values.value.last_reading.datetime_utc
  ];
  const rows = data?.map((item, i) => ({
    id: i,
    messageDate: item?.messageDate,
    strValue: item?.strValue,
    alarms: item?.alarms,
  }));
  const params = {
    from_date: from.toISOString(),
    to_date: now.toISOString(),
    page: page,
    per_page: perPage,
    message_type: "reading",
    sort: "message_date",
    order: "desc",
  };
  useEffect(() => {
    if (from && to) {
      // dispatch(clearData());

      getMeterReadingGraph({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: meter.id,
        from: from.toISOString(),
        to: now.toISOString(),
      });
      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: meter.id,
        from: from.toISOString(),
        to: now.toISOString(),
        page: page,
        perPage: perPage,
      });
    }
  }, [from, to]);

  useEffect(() => {
    if (from && to) {
      getDeviceMessages({
        digitalTwinId: meter.attributes.digital_twin_id,
        deviceId: meter.id,
        from: from.toISOString(),
        to: now.toISOString(),
        page: page,
        perPage: perPage,
      });
    }
  }, [page]);

  const hanldeSubimt = ({ formData: selectdIntervalId }) => {
    var newFrom = new Date(now);
    switch (selectdIntervalId) {
      case 1:
        newFrom.setDate(newFrom.getDate() - 7);
        setFrom(newFrom);
        break;
      case 2:
        newFrom.setMonth(newFrom.getMonth() - 1);
        setFrom(newFrom);
        break;
      case 3:
        newFrom.setMonth(newFrom.getMonth() - 3);
        setFrom(newFrom);
        break;
      case 4:
        newFrom.setMonth(newFrom.getMonth() - 6);
        setFrom(newFrom);
        break;
      case 5:
        newFrom.setFullYear(newFrom.getFullYear() - 1);
        setFrom(newFrom);
        break;
      default:
        newFrom.setDate(newFrom.getDate() - 7);
        setFrom(newFrom);
        break;
    }
  };
  useEffect(() => {
    console.log("meter", meter);
  }, [meter]);

  return (
    <Box>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={({ formData: newFormData }) => setFormData(newFormData)}
        onSubmit={hanldeSubimt}
        validator={validator}
      />
      {meter?.id && (
        <ExportMessageButton
          params={params}
          digitaltwinId={meter.attributes.digital_twin_id}
          meeterId={meter.id}
          styleProp={{ float: "right", mr: 2 }}
        />
      )}
      {data?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            mt: 10,
          }}
        >
          <Box sx={{ flexGrow: 1, flex: "0 0 50%", mt: 2 }}>
            <DataGrid
              disableColumnMenu
              rows={rows}
              columns={columns}
              pageSize={perPage}
              // rowsPerPageOptions={[perPage]}
              // paginationMode="client"
              onPageChange={(pageNumber) => {
                setPage(pageNumber + 1);
              }}
              page={page - 1}
              rowCount={meta?.count}
              loading={isLoadingTable}
              paginationMode="server"

              // sortingMode="server"
              // onSortModelChange={handleSortModelChange}

              // disableSelectionOnClick
              // experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
          <Box sx={{ flex: "0 0 49%", width: "100%", mt: 1 }}>
            <MeterReadingsGraph meter={meter} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            mt: 10,
          }}
        >
          <Box sx={{ flexGrow: 1, flex: "0 0 45%", mt: 2 }}></Box>
          <Box sx={{ flex: "0 0 49%", width: "100%", mt: 1 }}>
            <Typography sx={{ color: theme.palette.primary.main }} variant="h4">
              {t("no_available_data")}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MeterReadingsTab;
