import React, { useState } from "react";
import Loader from "./Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export const useIwdTable = () => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([1]);

  const onChangePage = (event, page) => {
    setPage(page);
    if (!pages.includes(page)) {
      setPages([...pages, page]);
    }
  };

  const onPageNext = () => {
    const newPage = Math.max(...pages) + 1;
    if (!pages.includes(newPage)) {
      setPages([...pages, newPage]);
    }
    setPage(newPage);
  };

  const onPagePrev = () => {
    const newPage = page - 1;
    if (newPage > 0) {
      setPage(newPage);
    }
  };

  return {
    perPage,
    setPerPage,
    page,
    setPage,
    pages,
    setPages,
    onChangePage,
    onPageNext,
    onPagePrev,
  };
};
const IwdTable = ({
  columns,
  loading,
  data,
  onChangePage,
  onPagePrev,
  onPageNext,
  pages,
  perPage,
  page,
  count,
}) => {
  if (loading) {
    return <Loader />;
  }
  const checkFunction = (name) => {
    if (typeof name === "function") return <TableCell>{name()}</TableCell>;
    return <TableCell>{name}</TableCell>;
  };

  const calcultateTotal = () => {
    if (count && perPage && count >= perPage) {
      return Math.ceil(count / perPage);
    } else {
      return 1;
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map(({ name }) => checkFunction(name))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.map((item) => (
              <TableRow key={item.id}>
                {columns.map(({ selector }) => (
                  <TableCell>{selector(item)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={2}>
        <Pagination
          count={calcultateTotal()}
          showFirstButton
          showLastButton
          onChange={onChangePage}
          page={page}
        />
      </Stack>
    </div>
  );
};

export default IwdTable;

//			<Pagination>
//				<Pagination.First onClick={() => onChangePage(1)} />
//				<Pagination.Prev onClick={onPagePrev} />
//				{pages.map((visitedPage) => (
//					<Pagination.Item
//						key={page}
//						active={visitedPage === page}
//						onClick={() => onChangePage(visitedPage)}
//					>
//						{visitedPage}
//					</Pagination.Item>
//				))}
//				<Pagination.Next onClick={onPageNext} />
//			</Pagination>
