import React, { useState, useEffect } from "react";
import useFetch from "use-http";
import Loader from "./Loader";
import InputSelect from "./form/InputSelect";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const NetworkAdaptersContext = React.createContext();

export const NetworkAdaptersProvider = ({ children }) => {
  const [networkAdpaters, setNetworkAdapters] = useState([]);
  const [selectedNetworkAdpaters, setSelectedNetworkAdapters] = useState([]);

  let value = {
    networkAdpaters,
    setNetworkAdapters,
    selectedNetworkAdpaters,
    setSelectedNetworkAdapters,
  };

  return (
    <NetworkAdaptersContext.Provider value={value}>
      {children}
    </NetworkAdaptersContext.Provider>
  );
};

export const useNetworkAdapters = () => {
  return React.useContext(NetworkAdaptersContext);
};

const NetworkAdaptersSelect = ({ item, control }) => {
  const {
    selectedNetworkAdpaters,
    setNetworkAdapters,
    setSelectedNetworkAdapters,
  } = useNetworkAdapters();
  const { get, loading, error, response } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`
  );
  const [options, setOptions] = useState([]);
  useEffect(() => {
    loadNetworkAdapters();
  }, []);

  const loadNetworkAdapters = async () => {
    const { data } = await get(`/api/v1/altior/network_adapter`);
    if (response.ok) {
      const newOptions = data
        .map(({ attributes: { instances, name } }) => {
          const items = instances
            .filter(({ name }) => name)
            .map((instance) => {
              var port = "-";
              if (
                instance &&
                instance.protocol &&
                instance.protocol.socket_port
              )
                port = instance.protocol.socket_port;
              return {
                label: `${instance.name} ${instance.description} [${port}]`,
                value: instance,
              };
            });
          if (items.length === 0) {
            return [{ label: name, value: { name: name, description: name } }];
          } else {
            return items;
          }
        })
        .flat();
      setNetworkAdapters(data);
      setOptions(newOptions);
    }
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div> Error da fare... </div>;
  }

  return (
    <InputSelect
      item={item}
      options={options}
      isMulti={true}
      control={control}
      value={selectedNetworkAdpaters}
      onChange={(event) => {
        setSelectedNetworkAdapters(event.target.value);
      }}
    />
  );
};

export default NetworkAdaptersSelect;
