import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SearchProvider from "../../components/SearchProvider";
import SearchDevice from "./SearchDevice";
import DeviceGroupProvider, {
	DeviceGroupContext,
} from "../../components/DeviceGroupProvider";
import { Button, Stack, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DeviceGroupSearchProvider, {
	DeviceGroupSearchConsumer,
} from "../../components/deviceGroup/DeviceGroupSearchProvider";

const AddDeviceToGroup = ({ ...attrs }) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const handleAddDevices = (addDevices, devices) => {
		if (devices && devices.length > 0) {
			addDevices(id, devices).then(() => {
				navigate(`/groups/${id}`, { replace: true });
			});
		} else {
			navigate(`/groups/${id}`, { replace: true });
		}
	};
	return (
		<Box>
			<DeviceGroupSearchProvider>
				<DeviceGroupSearchConsumer>
					{({ addDevices }) => (
						<DeviceGroupProvider>
							<DeviceGroupContext>
								{({ selectedDevices }) => (
									<SearchProvider>
										<Typography variant="h3" sx={{ marginBottom: 5 }}>
											{t("associate_devices")}
										</Typography>
										<SearchDevice groupId={id} {...attrs} />
										<Stack direction="row" justifyContent="space-between">
											<Button
												onClick={() =>
													navigate(`/groups/${id}`, { replace: true })
												}
											>
												{t("back")}{" "}
											</Button>
											<Button
												onClick={() =>
													handleAddDevices(addDevices, selectedDevices)
												}
											>
												{t("add devices")}{" "}
											</Button>
										</Stack>
									</SearchProvider>
								)}
							</DeviceGroupContext>
						</DeviceGroupProvider>
					)}
				</DeviceGroupSearchConsumer>
			</DeviceGroupSearchProvider>
		</Box>
	);
};

export default AddDeviceToGroup;
