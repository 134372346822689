import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "use-http";
import {
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { Stack, Button, Typography, Divider } from "@mui/material";

import IwdTable, { useIwdTable } from "../IwdTable";
import { convertParamsToString } from "../../utils";
import { useIwdBreadCrumb } from "../IwdBreadcrumbProvider";
import Loader from "../Loader";

const CustomTooltip = ({ payload, label, active }) => {
  const { t } = useTranslation();
  if (active) {
    return (
      <Box
        sx={{ background: "#fcfcfc", border: "1px solid #8884d8", padding: 2 }}
      >
        <p> {t("dt", { val: new Date(label) })} </p>
        <p className="label">
          {payload[0] && payload[0].value ? (
            <>
              {`${t("volume")} : ${payload[0].value}`} m<sup>3</sup>{" "}
            </>
          ) : (
            <></>
          )}
        </p>
      </Box>
    );
  }
  return null;
};

const WaterMeterReadings = ({ device }) => {
  const { t } = useTranslation();
  const to = new Date();
  const fromOneDayAgo = new Date(to.getTime());
  fromOneDayAgo.setDate(fromOneDayAgo.getDate() - 1);

  const fromThreeDayAgo = new Date(to.getTime());
  fromThreeDayAgo.setDate(fromThreeDayAgo.getDate() - 3);

  const fromOneWeekAgo = new Date(to.getTime());
  fromOneWeekAgo.setDate(fromOneWeekAgo.getDate() - 7);

  const fromOneMonthAgo = new Date(to.getTime());
  fromOneMonthAgo.setDate(fromOneMonthAgo.getDate() - 30);

  const intervals = [
    {
      name: t("one_day"),
      value: { from: fromOneDayAgo.toISOString(), to: to.toISOString() },
    },
    {
      name: t("three_day"),
      value: { from: fromThreeDayAgo.toISOString(), to: to.toISOString() },
    },
    {
      name: t("one_week"),
      value: { from: fromOneWeekAgo.toISOString(), to: to.toISOString() },
    },
    {
      name: t("one_month"),
      value: { from: fromOneMonthAgo.toISOString(), to: to.toISOString() },
    },
  ];

  const [selectedInterval, setSelectedInterval] = useState(0);

  const handleChange = (event) => {
    setSelectedInterval(event.target.value);
    const {
      value: { from, to },
    } = intervals[event.target.value];
    setParams({ ...params, from_date: from, to_date: to });
  };

  const [selectedId, setSelectedId] = useState({});
  const { setLast } = useIwdBreadCrumb();

  const [callback, setCallback] = useState();

  const { loading, error, get } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );

  const { id } = useParams();
  const {
    page,
    pages,
    onChangePage,
    onPageNext,
    onPagePrev,
    setPage,
    setPages,
  } = useIwdTable();

  const [messages, setMessages] = useState();

  const [params, setParams] = useState({
    per_page: 100,
    page: 1,
    sort: "message_date",
    from_date: intervals[0].value.from,
    to_date: intervals[0].value.to,
  });

  const getMessages = () => {
    get(
      `api/v1/altior/digital_twin/${
        device.attributes.digital_twin_id
      }/instance/${device.id}/message?${convertParamsToString(params)}`
    ).then((value) => {
      setMessages(value);
    });
  };

  const columns = () => [
    {
      name: t("date_system_entry"),
      selector: (row) => {
        if (row.attributes.message_date) {
          var date = new Date(row.attributes.message_date);
          return t("dt", { val: date });
        }
        return "-";
      },
    },
    {
      name: t("date_reading"),
      selector: (row) => {
        if (
          row.attributes.values.values.actual_reading_volume &&
          row.attributes.values.values.actual_reading_volume.date
        ) {
          var date = new Date(
            row.attributes.values.values.actual_reading_volume.date
          );
          return t("dt", { val: date });
        }
        return "-";
      },
    },
    {
      name: t("metrological_errors"),
      selector: (row) => {
        if (row.attributes.values.values.alarms) {
          return Object.keys(row.attributes.values.values.alarms)
            .filter((key) => row.attributes.values.values.alarms[key] == 1)
            .map((val) => t(val))
            .join(", ");
        } else {
          return "-";
        }
      },
    },
    {
      name: t("system_errors"),
      selector: (row) => "-",
    },
    {
      name: t("RSSI"),
      selector: (row) => "-",
    },
    {
      name: t("volume"),
      selector: (row) => {
        if (row.attributes.values.values.actual_reading_volume) {
          return row.attributes.values.values.actual_reading_volume.value.toFixed(
            3
          );
        } else {
          return "-";
        }
      },
    },
    {
      name: t("unit"),
      selector: (row) => {
        if (row.attributes.values.values.actual_reading_volume) {
          return row.attributes.values.values.actual_reading_volume.unit
            ? row.attributes.values.values.actual_reading_volume.unit
            : "m3";
        } else {
          return "m3";
        }
      },
    },
  ];

  useEffect(() => {
    if (params.page && Object.keys(params).length >= 2) {
      getMessages();
    }
  }, [params]);

  useEffect(() => {
    if (params.page && params.page !== page) {
      setParams({ ...params, page: page });
    }
  }, [page]);

  if (loading || !device || !messages) {
    return <Loader />;
  }

  debugger;
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <FormControl fullWidth>
          <InputLabel id="select-interval-label">{t("interval")}</InputLabel>
          <Select
            labelId="select-interval-label"
            id="select-interval"
            value={selectedInterval}
            onChange={handleChange}
            label={t("interval")}
            autoWidth
          >
            {intervals.map(({ name }, index) => (
              <MenuItem key={index} value={index}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button>Export</Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: 5,
        }}
      >
        {messages.data.length > 0 ? (
          <ResponsiveContainer height={500} width="100%">
            <LineChart
              data={messages.data}
              margin={{
                top: 30,
                right: 20,
                left: 0,
                bottom: 155,
              }}
            >
              <Line
                type="monotone"
                dataKey="attributes.values.values.actual_reading_volume.value"
                stroke="#8884d8"
              />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis
                dataKey="attributes.values.values.actual_reading_volume.date"
                angle={315}
                reversed={true}
                allowDuplicatedCategory={false}
                tickFormatter={(value) => {
                  return t("dt_n", { val: new Date(value) });
                }}
                tickMargin={55}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        <IwdTable
          columns={columns()}
          loading={loading}
          data={messages}
          onPagePrev={onPagePrev}
          onPageNext={onPageNext}
          onChangePage={onChangePage}
          page={page}
          pages={pages}
          count={messages.meta.count}
        />
      </Box>
    </Box>
  );
};

export default WaterMeterReadings;
