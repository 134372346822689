import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import { useDeviceDistrict } from "../DeviceDistrictProvider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Loader from "../Loader";
import { useLazyGetPolygonDevicesQuery } from "../../redux/slices/deviceDistrictApi";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteDialog from "../DeleteDialog";
import SpeedIcon from "@mui/icons-material/Speed";
import { geoJsonPolygonSwapCoordinates } from "../../utils";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const squerCss = (item) => {
  return {
    width: 15,
    height: 15,
    backgroundColor: item.prev ? item.prev : item.pathOptions.color,
    marginLeft: 3,
    border: "2px solid rgba(0, 0, 0, 0.87)",
  };
};

const PolygonListText = ({ labelId, item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {labelId} <Box sx={squerCss(item)} />
    </Box>
  );
};

const PolygonList = () => {
  const { t } = useTranslation();
  const [getPolyDev] = useLazyGetPolygonDevicesQuery();
  const { polygons, removePolygon, setHighlight, getDevices, downloadDevices } =
    useDeviceDistrict();
  const { loading, get } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}/api/v1`
  );
  const [show, setShow] = useState(false);
  const [currentItem, setCurrentItem] = useState();

  const askForDeletePolygon = (item) => {
    setCurrentItem(item);
    setShow(true);
  };

  const deletePolygon = (item) => {
    removePolygon(item);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      {show ? (
        <DeleteDialog
          show={show}
          setShow={setShow}
          deleteId={currentItem}
          deleteFunction={deletePolygon}
        />
      ) : (
        <></>
      )}
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {polygons.map((item, index) => {
          const labelId = `${t("polygon")}-${index + 1}`;

          return (
            <ListItem
              key={index}
              secondaryAction={
                <>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => {
                      const params = geoJsonPolygonSwapCoordinates(
                        item.polygon.geom
                      );

                      downloadDevices(params);
                    }}
                  >
                    <CloudDownloadIcon
                      color={item.active ? "primary" : "action"}
                    />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => {
                      const params = geoJsonPolygonSwapCoordinates(
                        item.polygon.geom
                      );

                      getDevices(params);
                    }}
                  >
                    <SpeedIcon color={item.active ? "primary" : "action"} />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => setHighlight(item)}
                  >
                    <VisibilityIcon
                      color={item.active ? "primary" : "action"}
                    />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => askForDeletePolygon(item)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemText
                  id={labelId}
                  primary={<PolygonListText labelId={labelId} item={item} />}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default PolygonList;
