import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { widgetsApi } from "./widgetsApi";

const initialState = {
  widgets: [],
  widgetShow: null,
  updating: false,
  trashId: null,
  previewMode: false,
};

const widgetSlice = createSlice({
  name: "widgetsSlice",
  initialState,
  reducers: {
    setUpdating(state, action) {
      state.updating = action.payload;
    },
    setTrashId(state, action) {
      state.trashId = action.payload;
    },
    setPreviewMode(state, action) {
      state.previewMode = action.payload;
    },
    widgetShowCleanUp(state) {
      state.widgetShow = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      widgetsApi.endpoints.getWidgets.matchFulfilled,
      (state, action) => {
        state.widgets = action.payload.data;
      }
    );
    builder.addMatcher(
      widgetsApi.endpoints.getShowWidgets.matchFulfilled,
      (state, action) => {
        state.widgetShow = action.payload.data;
      }
    );
  },
});
export const { setUpdating, setTrashId, setPreviewMode, widgetShowCleanUp } =
  widgetSlice.actions;

export default widgetSlice.reducer;
