/* import { build } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/cacheLifecycle' */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { api } from "./api";

/* export const digitalTwinInstanceRtk = createApi({
  reducerPath: "digitalTwinInstance",
  tagTypes: ["Post"],
  transformErrorResponse: () => {
    debugger;
  },
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SWARM_BASEURL}/`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${bearer}`);
      headers.set("Content-Type", "application/vnd.api+json");
      headers.set("Accept", "application/vnd.api+json");
      return headers;
    },
  }),
  endpoints: (build) => ({
    postDigitalTwinInstance: build.mutation({
      query: (body) => ({
        url: "/api/v1/plugin/water_meters",

        method: "POST",

        body: JSON.stringify(body),
      }),
      transformErrorResponse: (response, meta, arg) => {
        if (response.status === "FETCH_ERROR") return "fetch error";
        if (response?.data?.errors) {
          return Object.entries(response?.data?.errors).map(
            (item) => `${item?.[0]} : ${item?.[1]}`
          );
        }
      },
    }),
  }),
}); */
export const digitalTwinInstanceRtk = api.injectEndpoints({
  endpoints: (build) => ({
    postDigitalTwinInstance: build.mutation({
      query: (body) => ({
        url: "v1/plugin/water_meters",

        method: "POST",

        body: JSON.stringify(body),
      }),
    }),
  }),
});

export const { usePostDigitalTwinInstanceMutation } = digitalTwinInstanceRtk;
