import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import { useLazyGetDashboardListQuery } from "../../redux/slices/dashboardApi";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardId } from "../../redux/slices/dashboardSlice";
import { useTranslation } from "react-i18next";

const DashBoardSelect = (
  /* { setDashboardId, dashboardId } */ { disabled }
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [getDashboardList, res] = useLazyGetDashboardListQuery();
  const dashboardId = useSelector((state) => state.dashboardSlice.dashboardId);
  const [value, setValue] = React.useState(dashboardId);

  const dashboardList = useSelector(
    (state) => state?.dashboardSlice?.dashboards
  );
  useEffect(() => {
    getDashboardList();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value) {
      dispatch(setDashboardId(value));
    }
  }, [value]);
  return (
    <Box sx={{ width: "40%" }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">
          {t("select_dashboard")}
        </InputLabel>
        <Select
          disabled={disabled}
          sx={{ height: 56 }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          /*  value={age} */
          value={value}
          label="Select dashboard"
          onChange={handleChange}
        >
          {dashboardList?.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DashBoardSelect;
