import { api } from "./api";
import { prepareParams } from "../../utils";
export const messageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDiagnosticMessages: build.query({
      query: (params) => {
        /* return `v1/message?message_type=diagnostic&page=${page}&per_page=${perPage}`; */
        return `v1/message?${prepareParams(params)}`;
      },
    }),
    getReadingMessages: build.query({
      query: (params) => {
        /*   return `v1/message?message_type=reading&page=${page}&per_page=${perPage}`; */
        return `v1/message?${prepareParams(params)}`;
      },
    }),
    getAllMessages: build.query({
      query: (params) => {
        return `v1/message?${prepareParams(params)}`;
      },
    }),
  }),
});

export const {
  useLazyGetDiagnosticMessagesQuery,
  useLazyGetReadingMessagesQuery,
  useLazyGetAllMessagesQuery,
} = messageApi;
