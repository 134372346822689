import { createSlice } from "@reduxjs/toolkit";
import { gatewayApi } from "./gatewayApi";
const initialState = {
  gataways: null,
  gateway: null,
  metaCount: null,
  messages: null,
  twinId: null,
  messagesMeta: null,
};

const gatewaySlice = createSlice({
  name: "gatewaySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      gatewayApi.endpoints.getIndexGateway.matchFulfilled,
      (state, action) => {
        state.gataways = action.payload.data;
        state.metaCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      gatewayApi.endpoints.getShowGateway.matchFulfilled,

      (state, action) => {
        state.gateway = action.payload.data.attributes;
        state.twinId = action.payload.data.attributes.digital_twin_id;
      }
    );
    builder.addMatcher(
      gatewayApi.endpoints.getGatewayMessages.matchFulfilled,
      (state, action) => {
        state.messages = action.payload.data;
        state.messagesMeta = action.payload.meta.count;
      }
    );
  },
});

export default gatewaySlice.reducer;
