import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

const ChannelStatus = ({ isConnected }) => {
	const { t } = useTranslation();
	if (isConnected) {
		return (
			<Tooltip title={t("channel_status")} arrow>
				<SignalWifiStatusbar4BarIcon />
			</Tooltip>
		);
	}

	return (
		<Tooltip title={t("channel_status")} arrow>
			<SignalWifiStatusbarConnectedNoInternet4Icon />
		</Tooltip>
	);
};

export default ChannelStatus;
