import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { Avatar, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const GatewayIndexTable = ({ data, hideAccordion, perPage, page, setPage }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const metaCount = useSelector((state) => state?.gatewaySlice?.metaCount);
  const fakeData = [
    {
      id: 228,
      attributes: {
        serial_number: "2281337",
        inserted_at: "23.04.1998",
        fields: {
          address: "Via zabarella",
        },
      },
    },
  ];
  useEffect(() => {
    console.log("gatdata", data);
  }, [data]);
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },

    {
      field: "name",
      headerName: t("name"),
      type: "number",
      sortable: false,
      editable: true,
      flex: 5,
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,
      editable: true,
      flex: 5,
    },

    {
      field: "modem_imei",
      headerName: t("modem_imei"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
    },

    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 6,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 4,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton disabled sx={{ float: "right" }}>
              <DeleteIcon sx={{ ml: 2 }} />
            </IconButton>
            <Link to={`/gateways/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const tableRows = data?.map((item) => ({
    id: item.id ? item.id : "--",

    name: item?.attributes?.fields?.name,
    serial: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    modem_imei: item?.attributes?.fields?.modem_imei
      ? item?.attributes?.fields?.modem_imei
      : "--",

    configdate: item?.attributes?.inserted_at
      ? t("dt", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: item.id ? item.id : "",
  }));

  return (
    <Box sx={{ height: hideAccordion ? "60vh" : "39vh", width: "99%" }}>
      <DataGrid
        disableColumnMenu
        rows={tableRows ? [...tableRows] : []}
        columns={columns}
        rowCount={metaCount}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          /* !response.isFetching &&  */ setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default GatewayIndexTable;
