import { useEffect, useState } from "react";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";

import { Grid, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import DeviceMap from "../metrics/MapLeaflet";
import { useTranslation } from "react-i18next";
import { secondsToDhms } from "../../utils/api_params";
import PasswordField from "material-ui-password-field";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";

const GatewayRegistri = ({ gateway }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  /*  const fields = result?.data?.data?.attributes?.fields; */
  const [trigger, res, lastPromiseInfo] = useLazyGetDataQuery();
  /*   const filteredType = res?.data?.data?.attributes?.filter(
    (item) => item.value == fields?.type
  ); */
  const key = gateway.fields?.identifier_type;
  /*   useEffect(() => {
    if (result?.data) {
      trigger("plugin/en137577");
    }
  }, [result]); */

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems="center"
          rowSpacing={5}
          direction="row"
          spacing={5}
          xs={8}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`name`)} :</Typography>
            <Typography>{gateway.fields?.name}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`${gateway.fields?.identifier_type}`)} :
            </Typography>
            <Typography>{gateway.fields?.[key]}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("coordinates")} :</Typography>
            <Typography variant="subtitle1">{`Lat : ${gateway?.fields?.coordinates?.coordinates[1]}`}</Typography>
            <Typography variant="subtitle1">{`Lng : ${gateway?.fields?.coordinates?.coordinates[0]}`}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("instalation_data")} :{" "}
            </Typography>
            <Typography variant="subtitle1">
              {t("dt", {
                val: new Date(gateway?.inserted_at),
              })}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("time_zone")} :</Typography>
            <Typography variant="subtitle1">
              {/* {fields?.time_zone ?? "--"} */}
              Europe/Rome
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_imei`)} :</Typography>
            <Typography>{gateway.fields?.modem_imei}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_mode`)} :</Typography>
            <Typography>{gateway.fields?.modem_mode}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_iccid`)} :</Typography>
            <Typography>{gateway.fields?.modem_iccid}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_rss`)} :</Typography>
            <Typography>{gateway.fields?.modem_rssi}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`modem_operator`)} :</Typography>
            <Typography>{gateway.fields?.modem_operator}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`temperature`)} :</Typography>
            <Typography>{gateway.fields?.temperature}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`uptime`)} :</Typography>
            <Typography>{secondsToDhms(gateway.fields?.uptime)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`battery_percentage`)} :
            </Typography>
            <Typography>{gateway.fields?.battery_percentage}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`tag`)} :</Typography>
            <Typography>{gateway.fields?.tag}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t(`version_sw_modem`)} :
            </Typography>
            <Typography>{gateway.fields?.version_sw_modem}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t(`identifier`)} :</Typography>
            <Typography>{gateway.fields?.identifier}</Typography>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={12}>
            <DeviceMap pos={gateway?.fields?.coordinates} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GatewayRegistri;
