import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import MenuList from "@mui/material/MenuList";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  useDownloadMutation,
  useLazyStartExportQuery,
} from "../../redux/slices/deviceExportApi";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useSearchParams } from "react-router-dom";
import ModalDialog from "../meters/ModalDialog";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import { actions as districtActions } from "../../redux/slices/deviceDistrictSlice";
import { loadingOn, loadingOff } from "../../redux/slices/loadingSlice";
/* const url = "http://192.168.1.94:4006/api/v1/altior/device/export/download?";  344*/

const DistrictDeviceDownloader = ({ styleProp, params }) => {
  const url = `${window.BASE_URL}/api/v1/altior/download/device/export?`;
  const bearer = localStorage.getItem("user_token");
  const options = ["Export XLSX"];
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [uuid, setUUID] = useState();
  const downloadData = useSelector(
    (state) => state?.deviceDistrictSlice?.devicesToDownload
  );
  const [startDownload, setStartDownload] = useState(false);
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  useEffect(() => {
    console.log("URL", url);
  }, [url]);

  const [
    startExportDevice,
    {
      isLoading: isDeviceExportLoading,
      isSuccess: isDeviceExportSuccess,
      isError: isDeviceExportError,
      data: resultStartExport,
      requestId,
    },
  ] = useLazyStartExportQuery();
  const [
    downloadDevice,
    {
      isSuccess: isDeviceDownloadSuccess,
      isError: isDeviceDownloadError,
      data: resultStartDownload,
      reset: resetStartDownload,
    },
  ] = useDownloadMutation();
  const { isWSConnected, subscribedChannels, data, error, channels } =
    useSelector((state) => state.iwdWebSocketSlice);
  const { t } = useTranslation();
  useEffect(() => {
    // 1
    dispatch(loadingOff());
  }, [isDeviceDownloadSuccess, isDeviceDownloadError]);

  useEffect(() => {
    if (isWSConnected && !channels["export_status"]) {
      console.log("Join Channell export_status");
      let requestUuid = uuidv4();
      setUUID(requestUuid);

      dispatch(
        actions.joinChannel({
          name: "export_status",
          params: { uuid: requestUuid },
        })
      );
    }
  }, [isWSConnected]);

  useEffect(() => {
    if (
      data &&
      data?.data_update?.uuid == uuid &&
      data?.data_update?.status == "ready"
    ) {
      debugger;
      const copySearchParams = new URLSearchParams(searchParams);
      copySearchParams.append("download_type", "excel");
      copySearchParams.append("uuid", uuid);
      copySearchParams.append("token", bearer);
      copySearchParams.append("in_polygon", `${JSON.stringify(downloadData)}`);
      window.open(`${url}${copySearchParams.toString()}`, "_blank");
      dispatch(loadingOff());
      dispatch(districtActions.setDevicesToDownload(null));
    }
  }, [data, data?.data_update, data.data_update?.status]);
  useEffect(() => {
    if (!isWSConnected) {
      dispatch(actions.connect());
    }
  }, [isWSConnected]);

  useEffect(() => {
    if (
      channels["export_status"] &&
      (!subscribedChannels["export_status"] ||
        !subscribedChannels["export_status"]["data_update"])
    ) {
      dispatch(
        actions.subscribeChannel({
          channelName: "export_status",
          eventName: "data_update",
        })
      );

      console.log("Subscribe evnet export_status => data_update");
      if (startDownload) {
        debugger;
        const copySearchParams = new URLSearchParams(searchParams);

        copySearchParams.append("download_type", "excel");
        copySearchParams.append("uuid", uuid);
        copySearchParams.append(
          "in_polygon",
          `${JSON.stringify(downloadData)}`
        );
        startExportDevice(copySearchParams);
        setStartDownload(false);
      }
    }
  }, [channels]);
  useEffect(() => {
    if (!isDeviceExportLoading) {
      switch (resultStartExport) {
        case "already_created":
          debugger;
          const copySearchParams = new URLSearchParams(params);
          copySearchParams.append("download_type", "excel");
          copySearchParams.append("uuid", uuid);
          copySearchParams.append("token", bearer);
          copySearchParams.append(
            "in_polygon",
            `${JSON.stringify(downloadData)}`
          );
          window.open(`${url}${copySearchParams.toString()}`, "_blank");
          dispatch(loadingOff());
          dispatch(districtActions.setDevicesToDownload(null));
          break;
        case "creating":
          debugger;
          dispatch(loadingOn());
          break;
        default:
          break;
      }
    }
  }, [
    isDeviceExportSuccess,
    isDeviceExportLoading,
    requestId,
    resultStartExport,
  ]);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);

    dispatch(loadingOn());
    switch (selectedIndex) {
      case 0:
        debugger;
        if (!isWSConnected) {
          dispatch(actions.connect());
          setStartDownload(true);
        } else {
          const copySearchParams = new URLSearchParams(searchParams);
          copySearchParams.append("download_type", "excel");
          copySearchParams.append("uuid", uuid);
          copySearchParams.append("uuid", uuid);
          copySearchParams.append(
            "in_polygon",
            `${JSON.stringify(downloadData)}`
          );
          startExportDevice(copySearchParams);
        }

        break;

      default:
        alert("Nothing to do");
        break;
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  useEffect(() => {
    console.log("resultStartExport", resultStartExport);
  }, [resultStartExport]);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  useEffect(() => {
    if (downloadData !== null) {
      debugger;
      handleClick();
    }
  }, [downloadData]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    //clean up

    return () => {
      dispatch(actions.cleanData("data_update"));
      dispatch(actions.leaveChannel("export_status"));
      dispatch(districtActions.setDevicesToDownload(null));
    };
  }, []);

  return (
    <>
      {bearer && (
        <>
          <ModalDialog open={loading} title={t("note")} cancell={false}>
            <Box>{t("exporting_data")}</Box>
          </ModalDialog>
          {/*  <IconButton onClick={handleClick}>
            <CloudDownloadIcon />
          </IconButton> */}
        </>
      )}
    </>
  );
};

export default React.memo(DistrictDeviceDownloader);
