import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DeviceDistrictProvider from "../components/DeviceDistrictProvider";
import DeviceDistrictCreateUpdateComponent from "../components/deviceDistrict/DeviceDistrictCreateUpdateComponent";

const DeviceDistrictCreateUpdate = () => {
  return (
    <Box>
      <DeviceDistrictProvider>
        <DeviceDistrictCreateUpdateComponent />
      </DeviceDistrictProvider>
    </Box>
  );
};

export default DeviceDistrictCreateUpdate;
