import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import { setSeverity } from "../../redux/slices/snackbarSlice";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import Select from "@mui/material/Select";
import { usePostCommandsMutation } from "../../redux/slices/meterApi";
import { useLazyGetCommandsQuery } from "../../redux/slices/meterApi";
import { Typography } from "@mui/material";
const MeterCommands = ({ digitalTwinId }) => {
  const commands = useSelector((state) => state?.meterSlice?.commands);
  const { t } = useTranslation();
  const [getCommands] = useLazyGetCommandsQuery();
  const [postCommands, { isSuccess: postSuccess }] = usePostCommandsMutation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [command, setCommand] = React.useState("");
  const handleChange = (e) => {
    setCommand(e.target.value);
  };
  const fakeSubmitClick = () => {
    if (command == 20) {
      dispatch(setSeverity("warning"));
      const msg = (
        <Box>
          <Typography>{t("warning")}</Typography>
          <Typography>{t("demo_message")}</Typography>
        </Box>
      );
      dispatch(setMessage(msg));
    } else {
      dispatch(setSeverity("success"));
      dispatch(setMessage(t("demo_succ_msg")));
    }
  };
  React.useEffect(() => {
    if (postSuccess) {
      dispatch(setSeverity("success"));
      dispatch(setMessage(t("demo_succ_msg")));
    }
  }, [postSuccess]);
  const onSubmitClick = () => {
    const attributes = {
      values: {},
    };
    postCommands({
      attributes: attributes,
      commandName: command,
      digitalTwinId: digitalTwinId,
      digitalTwinInstance: id,
    });
  };
  React.useEffect(() => {
    getCommands(digitalTwinId);
  }, [digitalTwinId]);
  return (
    <>
      <Box sx={{ minWidth: 120, display: "flex", mt: 4 }}>
        <FormControl sx={{ width: 1600 }}>
          <InputLabel id="demo-simple-select-label">{t("command")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={command}
            label={t("command")}
            onChange={handleChange}
          >
            {commands?.map((item) => (
              <MenuItem value={item?.attributes?.name}>
                {t(`${item?.attributes?.name}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          disabled={!command}
          onClick={onSubmitClick}
          sx={{ ml: 3 }}
          variant="contained"
        >
          {t("send_command")}
        </Button>
      </Box>

      {/* {commands?.length === 0 && (
        <Box sx={{ minWidth: 120, display: "flex", mt: 4 }}>
          <FormControl sx={{ width: 1600 }}>
            <InputLabel id="demo-simple-select-label">
              {t("command")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={command}
              label={t("command")}
              onChange={handleChange}
            >
              <MenuItem value={10}>{t("open_valve")}</MenuItem>
              <MenuItem value={20}>{t("close_valve")}</MenuItem>
              <MenuItem value={30}>{t("sync_time")}</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={onSubmitClick} sx={{ ml: 3 }} variant="contained">
            {t("send_command")}
          </Button>
        </Box>
      )} */}
    </>
  );
};
export default MeterCommands;
