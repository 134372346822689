import { prepareParams } from "../../utils";
import { prepareBodyWithoutId } from "../../utils/api_params";
import { api } from "./api";
function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

export const deviceExportApi = api.injectEndpoints({
  endpoints: (build) => ({
    startExport: build.query({
      query: (params) => {
        switch (typeof params) {
          case "object":
            if (params instanceof URLSearchParams) {
              return `v1/altior/device/export?${params.toString()}`;
            }
            return `v1/altior/device/export?${prepareParams(params)}`;

          case "string":
            return `v1/altior/device/export?${params}`;
          default:
            return `v1/altior/device/export?${prepareParams(params)}`;
        }
      },
      transformResponse: (response) => {
        return response.data.attributes.status;
      },

      // extraOptions p: { maxRetries: 1 },
    }),
    download: build.mutation({
      query: (params) => {
        const uuid = params.get("uuid");
        const downloadType = params.get("download_type");
        return {
          url: `v1/altior/device/export?${params.toString()}`,
          method: "POST",
          body: JSON.stringify(
            prepareBodyWithoutId(
              { download_type: downloadType, uuid: uuid },
              "export"
            )
          ),
          responseHandler: async (response) => {
            response.headers.forEach((value, key) => {
              console.log(`${key} ==> ${value}`);
            });
            /*   console.log(
              "yareyare",
              response.headers.get("content-disposition")
            ); */
            return window.location.assign(
              window.URL.createObjectURL(await response.blob())
            );
          },
        };
      },
      transformResponse: (response) => {
        debugger;
        return response.data.attributes.status;
      },
    }),
  }),
});

export const { useLazyStartExportQuery, useDownloadMutation } = deviceExportApi;
