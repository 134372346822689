import validator from "@rjsf/validator-ajv6";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useCreateConnectionMutation } from "../../redux/slices/connectionsApi";
import { Form } from "@rjsf/mui";
import { useEffect, useState } from "react";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const ConnectionCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [createdId, setCreatedId] = useState(null);
  const [createConnection] = useCreateConnectionMutation();
  const onSubmitClick = (obj) => {
    createConnection(obj)
      .unwrap()
      .then((data) => setCreatedId(data?.data?.attributes?.id));
  };
  useEffect(() => {
    if (createdId) {
      navigate(`/connections/${createdId}`);
    }
  }, [createdId]);
  const schema = {
    title: "New Connection",
    type: "object",
    properties: {
      host: {
        $id: "1",
        type: "string",
        title: t("host"),
      },
      login: {
        $id: "2",
        type: "string",
        title: t("login"),
      },
      name: {
        $id: "3",
        type: "string",
        title: t("name"),
      },
      passcode: {
        $id: "4",
        type: "string",
        title: t("passcode"),
      },

      port: {
        $id: "6",
        type: "number",
        title: t("port"),
        maximum: 65535,
      },
      reconnect_timeout: {
        $id: "7",
        type: "number",
        title: t("recconnect_timeout"),
      },
    },
    required: [
      "host",
      "login",
      "name",
      "passcode",
      "port",
      "reconnect_timeout",
    ],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Form
        schema={schema}
        /*  fields={fields} */
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => onSubmitClick(values.formData)}
        validator={validator}
        uiSchema={uiSchema}
        /* widgets={widgets} */
        /*     showErrorList={false}
        customValidate={customValidate} */
        noHtml5Validate
        showErrorList={false}

        /*  templates={{ ErrorListTemplate }} */
      />
    </Box>
  );
};
export default ConnectionCreate;
