import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { Box } from "@mui/system";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePostPositionMutation } from "../../redux/slices/positionApi";
import FileWidget from "./FileWidget";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const PositionCreate = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [postPosition] = usePostPositionMutation();
  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });
  const schema = {
    title: "New position",
    type: "object",
    properties: {
      name: {
        title: t("name"),
        type: "string",
      },
      data: {
        type: "string",
        title: t("data"),
      },
    },
  };
  const fields = {
    file: FileWidget,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    data: {
      "ui:field": "file",
    },
  };
  useEffect(() => {
    console.log("fmd", formData);
  }, [formData]);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      data: formData.data,
    };
    postPosition(attributes);
  };
  return (
    <>
      <Box>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          fields={fields}
          formData={formData}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={onSubmitClick}
          validator={validator}
          showErrorList={false}
          noHtml5Validate
        />
      </Box>
    </>
  );
};

export default PositionCreate;
