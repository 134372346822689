import React, { useState } from "react";
import { useSearch } from "../../components/SearchProvider";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField, Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const ClassSelect = ({ onClassesSlected }) => {
  const { t } = useTranslation();
  const searcher = useSearch();
  const [searchValue, setSearchValue] = useState();

  const [searchCriteria, setSearchCriteria] = useState("select_class");
  const waterMeterClasses =
    `${process.env.REACT_APP_WATER_METER_CLASSES}`.split(" ");
  const gatewayClasses = `${process.env.REACT_APP_GATEWAY_CLASSES}`.split(" ");
  const sensorClaesses = `${process.env.REACT_APP_SENSORS_CLASSES}`.split(" ");

  return (
    <FormControl sx={{ minWidth: 120, width: "100%" }}>
      <InputLabel id="class-select-label">{t("class")}</InputLabel>
      <Select
        size="small"
        labelId="class-select-label"
        value={searchCriteria}
        label={t("class")}
        onChange={(e) => {
          if (e.target.value != "select_class") {
            if (onClassesSlected) {
              if (e.target.value == "water_meter") {
                onClassesSlected(waterMeterClasses.join(","));
              } else {
                onClassesSlected(e.target.value);
              }
            }
          }
          setSearchCriteria(e.target.value);
        }}
      >
        <MenuItem value="select_class">{t("select_class")}</MenuItem>
        {gatewayClasses.map((gatewayClass) => (
          <MenuItem value={gatewayClass}>{gatewayClass}</MenuItem>
        ))}
        {waterMeterClasses.map((waterMeterClass) => (
          <MenuItem value={waterMeterClass}>{waterMeterClass}</MenuItem>
        ))}
        {sensorClaesses.map((waterMeterClass) => (
          <MenuItem value={waterMeterClass}>{waterMeterClass}</MenuItem>
        ))}

        <MenuItem value={"water_meter"}>{t("water_meter")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ClassSelect;
