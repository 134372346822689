import React, { useEffect } from "react";
import { useSelector } from "react-redux";
const ClusterIcon = ({ count, itemId, selectedClusterId }) => {
  useEffect(() => {
    console.log("itemID:" + itemId, "selectedClid: " + selectedClusterId);
  }, [itemId, selectedClusterId]);
  return (
    <div>
      <svg
        width="50px"
        height="50px"
        viewBox="0 0 42 42"
        className="donut"
        aria-labelledby="beers-title beers-desc"
        role="img"
      >
        <circle
          className="donut-hole"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="white"
          role="presentation"
        ></circle>
        <circle
          className="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#d2d3d4"
          strokeWidth="3"
          role="presentation"
        ></circle>
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke={itemId === selectedClusterId ? "green" : "#ce4b99"}
          strokeWidth="3"
          /*   strokeDasharray={`${perc} ${100 - perc}`} */
          strokeDashoffset="25"
          aria-labelledby="donut-segment-1-title donut-segment-1-desc"
        ></circle>
        <g className="chart-text">
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">
            {count}
          </text>
        </g>
      </svg>
    </div>
  );
};

export default ClusterIcon;
