import { useState, useContext, useEffect, useCallback } from "react";
import { PhoenixSocketContext } from "./PhoenixContext";

const useChannel = (channelName) => {
  const [channel, setChannel] = useState();
  const { socket, connected, socketError } = useContext(PhoenixSocketContext);
  const [channelError, setChannelError] = useState();
  const [channelConnected, setChannelConnected] = useState(false);
  const [channelSubscritions, setChannelSubscritions] = useState({});
  const [message, setMessage] = useState();

  //  useEffect(() => {
  //    const phoenixChannel = connect();
  //    return () => {
  //      phoenixChannel.leave();
  //    };
  //  }, []);

  const connect = () => {
    if (!socket.isConnected()) {
      socket.connect();
    }
    const phoenixChannel = socket.channel(channelName);

    phoenixChannel
      .join()
      .receive("ok", () => {
        phoenixChannel.onError((e) => {
          setChannelError(e);
          setChannelConnected(false);
        });
        phoenixChannel.onClose((e) => {
          setChannelConnected(false);
          socket.disconnect(() => {});
        });
        setChannel(phoenixChannel);
        setChannelError(null);
        setChannelConnected(true);
      })
      .receive("error", (resp) => {
        setChannel(null);
        setChannelError(resp);
        setChannelConnected(false);
      });
    return phoenixChannel;
  };

  const subscribeToChannelEvent = (eventName) => {
    const newSubscritrion = channel.on(eventName, (response) => {
      setMessage(response);
    });
    const newObj = {};
    newObj[eventName] = newSubscritrion;
    const newChannelSubscritions = { ...channelSubscritions, ...newObj };
    setChannelSubscritions(newChannelSubscritions);
  };

  const unsubscribeToChannelEvent = (eventName) => {
    channel.off(eventName, channelSubscritions[eventName]);
  };

  const leaveChannel = () => {
    channel.leave();
    setChannel(null);
  };

  const push = (eventName, payload) => {
    channel.push(eventName, payload);
  };

  return {
    channel,
    channelConnected,
    channelError,
    channelName,
    socketConnected: connected,
    socketError,
    subscribeToChannelEvent,
    unsubscribeToChannelEvent,
    message,
    push,
    leaveChannel,
    connect,
  };
};

export default useChannel;
