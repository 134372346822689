import { createSlice } from "@reduxjs/toolkit";
import { connectionsApi } from "./connectionsApi";
const initialState = {
  connections: [],
  connection: null,
  count: null,
};
const connectionsSlice = createSlice({
  name: "connectionsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      connectionsApi.endpoints.getConnections.matchFulfilled,
      (state, action) => {
        state.connections = action.payload.data.attributes.data;
        state.count = action.payload.data.attributes.meta.count;
      }
    );

    builder.addMatcher(
      connectionsApi.endpoints.getConnection.matchFulfilled,
      (state, action) => {
        state.connection = action.payload.data;
      }
    );
  },
});

export default connectionsSlice.reducer;
