import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";
import "react-datepicker/dist/react-datepicker.css";

const SimpleInputDateTime = ({ item, onChange }) => {
  const [value, setValue] = useState(new Date());

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };
  return (
    <>
      <DateTimePicker
        renderInput={(params) => <TextField {...params} />}
        value={value}
        onChange={handleChange}
      />
    </>
  );
};

export default SimpleInputDateTime;
