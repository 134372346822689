import React from "react";
import DevicesTable from "./devices/DevicesTable";
import DevicesMap from "./devices/DevicesMap";
import SearchProvider from "../components/SearchProvider";
import DeviceSearchBar from "./devices/DeviceSearchBar";
import Box from "@mui/material/Box";

function Devices() {
  return (
    <Box>
      <SearchProvider>
        <Box sx={{ marginBottom: 3 }}>
          <DeviceSearchBar />
        </Box>
        <Box sx={{ marginBottom: 3 }}>
          <DevicesMap />
        </Box>
        <Box sx={{ marginBottom: 3 }}>
          <DevicesTable />
        </Box>
      </SearchProvider>
    </Box>
  );
}

export default Devices;
