import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { convertParamsToString } from "../../utils";
import IwdTable, { useIwdTable } from "./../../components/IwdTable";
import { Button, Stack } from "@mui/material";
import { useSearch } from "../../components/SearchProvider";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PinDropIcon from "@mui/icons-material/PinDrop";
import IwdDateTime from "../../components/IwdDateTime";
import { useAuth } from "../../components/AuthProvider";

function DevicesTable() {
  const { t } = useTranslation();
  const {
    page,
    pages,
    onChangePage,
    onPageNext,
    onPagePrev,
    setPage,
    setPages,
  } = useIwdTable();
  const auth = useAuth();
  const [params, setParams] = useState({});
  const [devices, setDevices] = useState();
  const searcher = useSearch();

  const showDeviceInMap = (device) => {
    searcher.setShowParamsAndMode(device);
  };

  const { get, loading, error } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}/api/v1`
  );
  const columns = [
    {
      name: t("id"),
      selector: (row) => row.id,
    },
    {
      name: t("serialNumber"),
      selector: (row) => row.attributes.serial_number,
    },
    {
      name: t("class"),
      selector: (row) => row.attributes.class,
    },
    {
      name: t("inserted_at"),
      selector: (row) => <IwdDateTime date={row.attributes.inserted_at} />,
    },
    {
      name: t("actions"),
      selector: (row) => (
        <Stack direction="row">
          <Button as={Link} to="">
            {" "}
            <ModeEditIcon />{" "}
          </Button>
          <Button as={Link} to={`/devices/${row.id}`}>
            <VisibilityIcon />
          </Button>
          <Button as={Link} to={`/devices/${row.id}`}>
            <PinDropIcon />
          </Button>
          <Button
            as={Link}
            to=""
            onClick={() => alert(`Delete: ${row.id}`)}
            color="error"
          >
            <DeleteIcon />
          </Button>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    if (
      params.page &&
      searcher.mode &&
      Object.keys(params).length >= 2 &&
      auth.isLogged()
    ) {
      get(`/altior/device?${convertParamsToString(params)}`).then((value) => {
        setDevices(value);
      });
    }
  }, [params]);

  useEffect(() => {
    if (params.page && params.page !== page) {
      setParams({ ...params, page: page });
    } else {
      switch (searcher.mode) {
        case "search":
          setParams({
            ...searcher.searchParams,
            pages: [1],
            page: 1,
          });
          setPage(1);
          setPages([1]);
          break;
        case "map":
          setParams({
            ...searcher.mapParams,
            pages: [1],
            page: 1,
          });
          setPage(1);
          setPages([1]);
          break;
        case "cluster":
          setParams({
            ...searcher.clusterParams,
            pages: [1],
            page: 1,
          });
          setPage(1);
          setPages([1]);
          break;
        default:
          //setParams({ ...params, pages: [1], page: 1 });
          break;
      }
    }
  }, [
    searcher.mapParams,
    searcher.searchParams,
    page,
    searcher.mode,
    searcher.clusterParams,
    searcher.showParams,
  ]);

  if (loading || devices == null) {
    return <Loader />;
  }

  if (error) {
  }

  return (
    <div>
      <IwdTable
        columns={columns}
        loading={loading}
        data={devices}
        onPagePrev={onPagePrev}
        onPageNext={onPageNext}
        onChangePage={onChangePage}
        page={page}
        pages={pages}
        perPage={20}
        count={devices.meta.count}
      />
    </div>
  );
}

export default DevicesTable;
