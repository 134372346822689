import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import AuthProvider from "./components/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import Connections from "./components/connections/Connections";
import ConnectionsShow from "./components/connections/ConnectionsShow";
import Campaigns from "./components/campaign/Campaigns";
import { useSelector } from "react-redux";
import ConnectionUpdate from "./components/connections/ConnectionUpdate";
import UserShow from "./components/users/UserShow";
import DistrictIndex from "./components/DeviceGroups/DistrictsIndex";
import UserCreate from "./components/users/UserCreate";
import { Routes, Route } from "react-router-dom";
import LiveMessages from "./components/messages/LiveMessages";
import GroupDeviceCreate from "./components/DeviceGroups/GroupDeviceCreate";
import GatewayCreate from "./components/gateway/GatewayCreate";
import { Provider } from "react-redux";
import Uploader from "./components/FileUploader /Uploader";
import store from "./redux/store";
import Logged from "./components/Logged";
import CreateWidget from "./components/widgets/CreateWidget";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import LoginPage from "./components/Login";
import TrilliantMeters from "./components/campaign/trilliantMeters/TrilliantMeters";
import DashboardCreate from "./components/dashboard/DashboardCreate";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import MeterCreate from "./components/form/MeterCreate";
import UploadedFileShow from "./components/FileUploader /UploadedFileShow";
import Meters from "./components/form/Meters";
import Devices from "./pages/Devices";
import CreateDevice from "./pages/devices/CreateDevice";
import ShowDevice from "./pages/ShowDevice";
import AuthenticatedProvider from "./components/AuthenticatedProvider";
import DeviceGroups from "./pages/DeviceGroups";
import DeviceDistricts from "./pages/DeviceDistricts";
import DeviceGroupCreate from "./pages/DeviceGroupCreate";
import DeviceGroup from "./pages/DeviceGroup";
import DeviceGroupEdit from "./pages/device_groups/DeviceGroupEdit";
import AddDeviceToGroup from "./pages/device_groups/AddDeviceToGroup";
import DeviceDistrictCreateUpdate from "./pages/DeviceDistrictCreateUpdate";
import Dashboard from "./pages/Dashboard";
import PositionCreate from "./components/position/PositionCreate";
import CampaignCreate from "./components/campaign/CampaignCreate";
import Users from "./components/users/Users";
import CampaignShow from "./components/campaign/CampaignShow";
import theme from "./theme";
import GlobalLoading from "./utils/GlobalLoading";
import themeTrilliant from "./themeTrilliant";
import ConnectionCreate from "./components/connections/ConnectionCreate";
import { ThemeProvider } from "@mui/material";
import GroupDeviceShow from "./components/DeviceGroups/GroupsDeviceShow";
import Dex from "./pages/Dex";
import CustomSnackbar from "./utils/Snackbars";
import NetworkAdapters from "./pages/NetworkAdapters";
import CustomerEndpoints from "./pages/CustomerEndpoints";
import CustomerEndpointCreate from "./pages/CustomerEndpointCreate";
import { createRoot } from "react-dom/client";
import Messages from "./components/messages/Messages";
import CustomerEndpointUpdate from "./pages/CustomerEndpointUpdate";
import MeterShow from "./components/metrics/MeterShow";
import Riccardo from "./pages/Riccardo";
import FallbackLoader from "./utils/FallbackLoader";
import ErrorsHandler from "./utils/ErrorsHandler";
import TestLik from "./components/TestLink";
import StatisticShow from "./components/statistics/StatisticShow";
import Metrics from "./pages/Metrics";
import MyAccount from "./pages/MyAccount";
import GatewayShow from "./components/gateway/GatewayShow";
import StatisticCreate from "./components/statistics/StatisticCreate";
import HelmetHeader from "./utils/HelmetHeader";
import WidgetShow from "./components/widgets/WidgetShow";
import Statistics from "./components/statistics/Statistics";
import GatewayIndex from "./components/gateway/GatewayIndex";
import NotFoundPage from "./utils/NotfoundPage";
import Widgets from "./components/widgets/Widgets";
import DigitalTwinShow from "./components/digital_twins/DigitalTwinShow";
import DigitalTwins from "./components/digital_twins/DigitalTwins";
import UploadFileForm from "./components/FileUploader /UploadFileForm";
function Ade() {
  // 👇️ redirect to external URL
  window.location.replace("http://192.168.1.65:9295/");

  return null;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}
library.add(fab, faCheckSquare, faCoffee);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

/* const themeSelector = () => {
  debugger;
  let theme;
  switch (`${process.env.REACT_APP_THEME}`) {
    case "default":
      return (theme = themeDefault);
    case "trilliant":
      return (theme = themeTrilliant);
  }
}; */

root.render(
  <Provider store={store}>
    <HelmetHeader />
    <React.StrictMode>
      <Suspense /* fallback={<FallbackLoader />} */>
        <GlobalLoading />
        <ErrorsHandler />
        <CustomSnackbar />
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AuthenticatedProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<Layout />}>
                    <Route
                      path="/"
                      element={
                        <RequireAuth>
                          <Metrics />
                        </RequireAuth>
                      }
                    />
                    <Route path="/app" element={<App />} />
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logged/:token" element={<Logged />} />
                    <Route
                      path="/widgets/create"
                      element={
                        <RequireAuth>
                          <CreateWidget />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/widgets/:id"
                      element={
                        <RequireAuth>
                          <WidgetShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/widgets"
                      element={
                        <RequireAuth>
                          <Widgets />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/statistics/create"
                      element={
                        <RequireAuth>
                          <StatisticCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/statistics"
                      element={
                        <RequireAuth>
                          <Statistics />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/statistics/:id"
                      element={
                        <RequireAuth>
                          <StatisticShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/import"
                      element={
                        <RequireAuth>
                          <Uploader />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/import/show"
                      element={
                        <RequireAuth>
                          <UploadedFileShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/protected"
                      element={
                        <RequireAuth>
                          <ProtectedPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/devices"
                      element={
                        <RequireAuth>
                          <Devices />
                        </RequireAuth>
                      }
                    />

                    <Route
                      path="/metrics"
                      element={
                        <RequireAuth>
                          <Metrics />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/trilliant"
                      element={
                        <RequireAuth>
                          <TrilliantMeters />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/digitaltwins"
                      element={
                        <RequireAuth>
                          <DigitalTwins />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/digitaltwins/:id"
                      element={
                        <RequireAuth>
                          <DigitalTwinShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/gateways"
                      element={
                        <RequireAuth>
                          <GatewayIndex />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/gateways/create"
                      element={
                        <RequireAuth>
                          <GatewayCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/gateways/:id"
                      element={
                        <RequireAuth>
                          <GatewayShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/testlink"
                      element={
                        <RequireAuth>
                          <TestLik />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/messages"
                      element={
                        <RequireAuth>
                          <Messages />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/messages/live"
                      element={
                        <RequireAuth>
                          <LiveMessages />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/user/create"
                      element={
                        <RequireAuth>
                          <UserCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/campaign/create"
                      element={
                        <RequireAuth>
                          <CampaignCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/groups/create"
                      element={
                        <RequireAuth>
                          <GroupDeviceCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/groups/:id"
                      element={
                        <RequireAuth>
                          <GroupDeviceShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <RequireAuth>
                          <Users />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/users/:id"
                      element={
                        <RequireAuth>
                          <UserShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/campaigns"
                      element={
                        <RequireAuth>
                          <Campaigns />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/position/create"
                      element={
                        <RequireAuth>
                          <PositionCreate />
                        </RequireAuth>
                      }
                    />

                    <Route
                      path="/meters/create"
                      element={
                        <RequireAuth>
                          <MeterCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/metrics/create"
                      element={
                        <RequireAuth>
                          <DashboardCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/connections"
                      element={
                        <RequireAuth>
                          <Connections />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/connections/create"
                      element={
                        <RequireAuth>
                          <ConnectionCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/connections/:id"
                      element={
                        <RequireAuth>
                          <ConnectionsShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/connections/:id/edit"
                      element={
                        <RequireAuth>
                          <ConnectionUpdate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/meters"
                      element={
                        <RequireAuth>
                          <Meters />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/ade"
                      element={
                        <RequireAuth>
                          <Ade />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/devices/create"
                      element={
                        <RequireAuth>
                          <CreateDevice />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/devices/:id"
                      element={
                        <RequireAuth>
                          <ShowDevice />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/meters/:id"
                      element={
                        <RequireAuth>
                          <MeterShow />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/campaigns/:id"
                      element={
                        <RequireAuth>
                          <CampaignShow />
                        </RequireAuth>
                      }
                    />
                    {/*   <Route
                      path="/groups"
                      element={
                        <RequireAuth>
                          <DeviceGroups />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/groups/create"
                      element={
                        <RequireAuth>
                          <DeviceGroupCreate />
                        </RequireAuth>
                      }
                    /> */}
                    <Route
                      path="/groups/:id"
                      element={
                        <RequireAuth>
                          <DeviceGroup />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/groups/:id/edit"
                      element={
                        <RequireAuth>
                          <DeviceGroupEdit />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/groups/:id/add_device"
                      element={
                        <RequireAuth>
                          <AddDeviceToGroup />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/districts/create"
                      element={
                        <RequireAuth>
                          <DeviceDistrictCreateUpdate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/districts/:id"
                      element={
                        <RequireAuth>
                          <DeviceDistrictCreateUpdate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/districts"
                      element={
                        <RequireAuth>
                          <DeviceDistricts />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/groups"
                      element={
                        <RequireAuth>
                          <DistrictIndex />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/network_adapter"
                      element={
                        <RequireAuth>
                          <NetworkAdapters />
                        </RequireAuth>
                      }
                    />

                    <Route
                      path="/dex"
                      element={
                        <RequireAuth>
                          <Dex />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/custumer_endpoints"
                      element={
                        <RequireAuth>
                          <CustomerEndpoints />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/custumer_endpoints/create"
                      element={
                        <RequireAuth>
                          <CustomerEndpointCreate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/custumer_endpoints/:id"
                      element={
                        <RequireAuth>
                          <CustomerEndpointUpdate />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/riccardo"
                      element={
                        <RequireAuth>
                          <Riccardo />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/me"
                      element={
                        <RequireAuth>
                          <MyAccount />
                        </RequireAuth>
                      }
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </AuthenticatedProvider>
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
