import React, { useRef, useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useSearch } from "../../components/SearchProvider";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import ClusterIcon from "../../components/ClusterIcon";

const DevicesInCuster = ({ cluster, params }) => {
  const clusterIcon = (count) =>
    new L.divIcon({
      html: ReactDOMServer.renderToString(<ClusterIcon count={count} />),
      className: "",
      iconSize: [20, 20],
      iconAnchor: [10, 10],
    });
  const map = useMap();
  const markerRef = useRef(null);
  const searcher = useSearch();

  useEffect(() => {
    map.on("moveend", () => {
      Object.keys(map._layers).map((ml) => {
        if (map._layers[ml].valueOf()._icon) {
          map._layers[ml].valueOf()._icon.style.backgroundColor = "transparent";
        }
      });
    });
    if (markerRef)
      markerRef.current.on("click", () => {
        let newParams = { ...params };
        newParams["single_cluster"] = {
          ...newParams.single_cluster,
          cluster_id: cluster.id,
        };
        Object.keys(map._layers).map((ml) => {
          if (map._layers[ml].valueOf()._icon) {
            map._layers[ml].valueOf()._icon.style.backgroundColor =
              "transparent";
          }
        });
        markerRef.current.valueOf()._icon.style.backgroundColor = "green";
        searcher.setClusterParamsAndMode(newParams);
      });
  }, [markerRef]);

  return (
    <Marker
      icon={clusterIcon(cluster.attributes.count)}
      key={cluster.id}
      position={[
        cluster.attributes.center.coordinates[1],
        cluster.attributes.center.coordinates[0],
      ]}
      ref={markerRef}
    ></Marker>
  );
};

export default DevicesInCuster;
