import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dexApi } from "./dexApi";

const dataSources = [
  {
    name: "readings",
    fields: {
      device_id: {
        label: "DeviceId",
        type: "number",
        fieldSettings: {
          min: 0,
        },
        valueSources: ["value"],
        operators: ["not_equal", "equal"],
      },
      parent_device_id: {
        label: "Parent Device Id",
        type: "number",
        fieldSettings: {
          min: 0,
        },
        valueSources: ["value"],
        operators: ["not_equal", "equal"],
      },
      in_error: {
        label: "In Error",
        type: "boolean",

        valueSources: ["value"],
        // operators: ["sum", "equal"],
      },
      inserted_at: {
        label: "Date system entry",
        type: "datetime",

        valueSources: ["value"],
        //   operators: ["sum", "equal"],
      },
      message_date: {
        label: "Reading Date",
        type: "datetime",
        valueSources: ["value"],
      },
      unit: {
        label: "Unit",
        type: "string",
        valueSources: ["value"],
      },
      volume: {
        label: "Volume",
        type: "number",
        valueSources: ["value"],
        operators: [
          "sum",
          "count",
          "average",
          "not_equal",
          "equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
        ],
      },
    },
  },
];
const initialState = {
  dataSources: dataSources,
  dataSource: null,
  dexAttributes: {
    logic: null,
    data_source: null,
    page: null,
    per_page: null,
  },
  postOrUpdate: false,
  count: null,
  dex: null,
};

const dexDataSourceSlice = createSlice({
  name: "dexDataSourceSlice",
  initialState,
  reducers: {
    setDataSource(state, action) {
      state.dataSource = action.payload;
    },
    setDexAttributes(state, action) {
      state.dexAttributes = action.payload;
      state.postOrUpdate = true;
    },
    setDexPage(state, action) {
      state.dexAttributes.page = action.payload;
      state.postOrUpdate = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dexApi.endpoints.postDex.matchFulfilled,
      (state, action) => {
        state.dex = action.payload.data.attributes.data;
        state.count = action.payload.data.attributes.meta.count;
        state.postOrUpdate = false;
      }
    );
    builder.addMatcher(dexApi.endpoints.postDex.matchRejected, (state) => {
      state.postOrUpdate = false;
    });
  },
});
export const { setDataSource, setDexAttributes, setDexPage } =
  dexDataSourceSlice.actions;

export default dexDataSourceSlice.reducer;
