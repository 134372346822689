import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SimpleInputCoordinates from "./SimpleInputCoordiantes";
import { FormControlLabel } from "@mui/material";

const InputCoordinates = ({ item, control }) => {
	const { t } = useTranslation();
	return (
		<Controller
			name={item.attributes.name}
			control={control}
			render={({ field }) => (
				<>
					<FormControlLabel
						labelPlacement="top"
						control={<SimpleInputCoordinates item={item} {...field} />}
						label={t(item.attributes.name)}
					/>
				</>
			)}
		/>
	);
};

export default InputCoordinates;
