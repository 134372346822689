import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDeviceDistrict } from "../DeviceDistrictProvider";
import Loader from "../Loader";
import PolygonList from "./PolygonList";
import DeviceDistrictMap from "./DeviceDistrictMap";
import DeviceDistrictForm from "../../pages/device_districts/DeviceDistrictForm";
import IwdSnackBar from "../IwdSnackBar";
import ApiError from "../ApiError";
import { useIwdBreadCrumb } from "../IwdBreadcrumbProvider";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeviceDistrictCommand from "./DeviceDistrictCommand";

const DeviceDistrictCreateUpdateComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setLast } = useIwdBreadCrumb();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { loading } = useFetch(`${process.env.REACT_APP_SWARM_BASEURL}/api/v1`);
  const [creating, setCreating] = useState(false);
  const { id } = useParams();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [apiError, setApiError] = useState();
  const {
    onSubmit,
    setUpdating,
    district,
    getDistrict,
    clearDistrictAndPolygon,
    downloadDistrict,
  } = useDeviceDistrict();
  const created = ({ action, id: creadedId, error }) => {
    switch (action) {
      case "created":
        if (error) {
          setCreating(false);
          setApiError(error);
        } else {
          setCreating(false);
          setApiError(null);
          navigate(`/districts/${creadedId}`);
        }
        break;
      case "updated":
        setCreating(false);
        setApiError(null);

        setShowSuccessMessage(true);
        break;
      default:
        setApiError(null);
        setCreating(false);
        break;
    }
  };

  useEffect(() => {
    if (id && !loading && !district) {
      getDistrict(id);
      setUpdating(true);
    }
    if (!id) {
      clearDistrictAndPolygon();
      setUpdating(false);
    }
  }, [id, district]);

  if (loading || creating) {
    return <Loader />;
  }
  return (
    <Box>
      {apiError ? (
        <ApiError
          error={{ name: t("district_creation"), message: t("error") }}
          body={apiError}
        />
      ) : (
        <> </>
      )}
      <IwdSnackBar
        severity="success"
        isOpen={showSuccessMessage}
        message={t("updated_with_success")}
      />
      <h2> {id ? t("update_district") : t("create_district")} </h2>
      <Box sx={{ width: "100%", marginTop: 5, height: "100%" }}>
        <form
          onSubmit={handleSubmit((data, event) => {
            setCreating(true);
            onSubmit(data, created);
          })}
          style={{ height: "100%" }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: 3,
                marginRight: 3,
                gap: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {id ? (
                district ? (
                  <DeviceDistrictForm control={control} errors={errors} />
                ) : (
                  <></>
                )
              ) : (
                <DeviceDistrictForm control={control} errors={errors} />
              )}
              <Box>
                <DeviceDistrictCommand></DeviceDistrictCommand>
              </Box>
              <PolygonList />
            </Box>
            <Box sx={{ flexGrow: 8, height: "100%" }}>
              <DeviceDistrictMap />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 5,
            }}
          >
            <Button
              sx={{ marginRight: 2 }}
              onClick={downloadDistrict}
              variant="contained"
              startIcon={<CloudDownloadIcon />}
            >
              {t("export")}
            </Button>
            <Button type="submit" variant="contained">
              {id ? t("update") : t("create")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default DeviceDistrictCreateUpdateComponent;
