import React, { useState } from "react";
import jwt_decode from "jwt-decode";

let AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [logged, setLogged] = useState(false);
  const [currentToken, setCurrentToken] = useState();
  let signin = (newToken, callback) => {
    //   const decoded = jwt_decode(newToken);
    localStorage.setItem("user_token", newToken);
    //   localStorage.setItem("user_info", JSON.stringify(decoded));
    setCurrentToken(newToken);
    setLogged(true);
    callback();
  };

  let signout = (callback) => {
    token = localStorage.getItem("user_token");
    localStorage.removeItem("user_token");
    localStorage.removeItem("user_info");
    localStorage.removeItem("user_preferences");
    localStorage.removeItem("from");
    fetch(`${process.env.REACT_APP_SWARM_BASEURL}/api/v1/logout`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((data) => {
      setCurrentToken(null);
      setLogged(false);
      callback();
    });
  };

  let getMe = (callback) => {
    token = localStorage.getItem("user_token");
    fetch(`${process.env.REACT_APP_SWARM_BASEURL}/api/v1/me`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 204) {
          return response.json();
        } else {
          callback(null, 401);
        }
      })
      .then(({ data: { attributes: attributes } }) => {
        localStorage.setItem("user_info", JSON.stringify(attributes));
        localStorage.setItem(
          "user_preferences",
          JSON.stringify(attributes.preferences)
        );
        callback(attributes);
      });
  };

  let addOrUpdateUserPreference = (name, value, callback) => {
    token = localStorage.getItem("user_token");
    var currentPreferences = userPreferences();
    currentPreferences[name] = value;
    fetch(`${process.env.REACT_APP_SWARM_BASEURL}/api/v1/me`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
      },
      body: JSON.stringify({
        data: {
          attributes: { preferences: currentPreferences },
          id: "me",
          type: "user_preference",
        },
      }),
    })
      .then((response) => response.json())
      .then(
        ({
          data: {
            attributes: { preferences },
          },
        }) => {
          localStorage.setItem("user_preferences", JSON.stringify(preferences));
          callback();
        }
      )
      .catch((e) => console.err(e));
  };

  let isLogged = () => {
    const newToken = localStorage.getItem("user_token");
    const userInfo = localStorage.getItem("user_info");
    if (newToken && userInfo) {
      // setCurrentToken(newToken);
      // setLogged(true);
      return true;
    }

    setCurrentToken(null);
    setLogged(false);
    return false;
  };

  let token = () => {
    const token = localStorage.getItem("user_token");
    setCurrentToken(token);
    setLogged(true);
    return token;
  };
  let user = () => JSON.parse(localStorage.getItem("user_info"));
  let userPreferences = () =>
    JSON.parse(localStorage.getItem("user_preferences"));

  let setFrom = (from) => localStorage.setItem("from", JSON.stringify(from));
  let getFrom = () => JSON.parse(localStorage.getItem("from"));

  let value = {
    signin,
    signout,
    isLogged,
    token,
    user,
    setFrom,
    getFrom,
    getMe,
    userPreferences,
    addOrUpdateUserPreference,
    logged,
    currentToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export default AuthProvider;
