import { api } from "./api";
import { prepareBody } from "../../utils/api_params";
const type = "user_preference";
const id = "me";
export const userPreferenceApi = api.injectEndpoints({
  endpoints: (build) => ({
    setPreference: build.mutation({
      query: (attributes) => ({
        url: "v1/me",
        method: "PATCH",

        body: JSON.stringify(prepareBody(attributes, id, type)),
      }),
    }),
    getPreference: build.query({
      query: () => {
        return `v1/me`;
      },
    }),
  }),
});

export const { useSetPreferenceMutation, useLazyGetPreferenceQuery } =
  userPreferenceApi;
