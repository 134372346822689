import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { EventRepeat } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import IwdSnackBar from "../IwdSnackBar";

const DeviceDistrictCommand = ({ onChange }) => {
  const [dataSource, setDataSource] = useState("");
  const [showSnak, setShowSnak] = useState(false);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setDataSource(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-data-source-select-label">
          {t("campaign")}
        </InputLabel>
        <Select
          labelId="demo-data-source-select-label"
          id="demo-data-source-select"
          value={dataSource}
          label={t("campaign")}
          onChange={handleChange}
        >
          <MenuItem value={"start_monitoring_campaign"}>
            {t("start_monitoring_campaign")}
          </MenuItem>
          <MenuItem value={"start_leak_detetion_campaign"}>
            {t("start_leak_detetion_campaign")}
          </MenuItem>
          <MenuItem value={"start_ota_campaign"}>
            {t("start_ota_campaign")}
          </MenuItem>
        </Select>
      </FormControl>
      <Button
        onClick={() => setShowSnak(true)}
        startIcon={<PlayArrowIcon />}
        disabled={dataSource == ""}
      >
        {t("start")}
      </Button>
      {showSnak ? (
        <IwdSnackBar
          severity="success"
          isOpen={showSnak}
          message={t("command_sent_with_success")}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DeviceDistrictCommand;
