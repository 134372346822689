import { Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

const ManufacturerData = ({ result }) => {
  const { t } = useTranslation();

  const UnitValue = (value) => {
    if (!value || Object?.keys(value)?.length == 0) {
      return "--";
    } else {
      return `${value?.value} ${value?.unit}`;
    }
  };

  const fields = result?.data?.data?.attributes?.fields;
  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems="center"
          rowSpacing={5}
          direction="row"
          spacing={5}
          xs={12}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("diameter")} :</Typography>
            <Typography variant="subtitle1">
              {fields.diameter ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Q1 :</Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q1)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Q2:</Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q2)}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Q3 :</Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q3)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Q4 : </Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q4)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">R : </Typography>
            <Typography variant="subtitle1">{fields.r ?? "--"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("temperature_class")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields.temperature_class ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("head_losses")} : </Typography>
            <Typography variant="subtitle1">
              {fields.head_loss ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("nominal_pressure")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields.nominal_pressure ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("indicative_weight")} :
            </Typography>
            <Typography variant="subtitle1">
              {UnitValue(fields.weight)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("flow_sensitivity")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields.flow_profile_sensitivity_classes ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("waterproof_class")} :
            </Typography>
            <Typography variant="subtitle1">
              {fields.ip_class ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t("battery_capacity")} :
            </Typography>
            <Typography variant="subtitle1">
              {UnitValue(fields?.battery)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("manufacturer")} :</Typography>
            <Typography variant="subtitle1">{fields.manufacturer}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{t("name")} :</Typography>
            <Typography variant="subtitle1">{fields.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ManufacturerData;
