import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCustomerEndpoint } from "./CustomerEndpointProvider";

import LoadingButton from "@mui/lab/LoadingButton";
import IwdSnackBar from "../IwdSnackBar";
import ApiError from "../ApiError";
import InputText from "../form/InputText";
import { JsonForms } from "@jsonforms/react";
import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";

const schameJs = {
  type: "object",
  properties: {
    name: {
      type: "string",
      minLength: 3,
      label: "Enter the name",
    },
    api_version: {
      type: "string",
      enum: ["v1", "v2"],
    },
    endpoint: {
      type: "string",
    },
    postalCode: {
      type: "string",
      maxLength: 5,
    },
    description: {
      type: "string",
    },
  },
  required: ["name"],
};

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/name",
    },
    {
      type: "Control",
      scope: "#/properties/api_version",
    },
    {
      type: "Control",
      scope: "#/properties/description",
      options: {
        multi: true,
      },
    },
    {
      type: "Control",
      scope: "#/properties/endpoint",
    },
  ],
};

const data = {};

const CustomerEndpointForm = ({ data: prevData, buttonLabel, action }) => {
  const { t } = useTranslation();
  const { createCustomerEndpoint } = useCustomerEndpoint();
  const [formData, setFormData] = useState();

  if (prevData) {
    debugger;
  }

  return (
    <Box sx={{}}>
      <JsonForms
        schema={schameJs}
        uischema={uiSchema}
        data={prevData ? prevData : data}
        renderers={materialRenderers}
        cells={materialCells}
        onChange={({ data, _errors }) => setFormData(data)}
      />
      <Button onClick={() => action(formData)}>{buttonLabel}</Button>
    </Box>
  );
};

// CustomerEndpointForm.propTypes = {
//   /** Categories of DigitalTwin, is a map */
//   categories: PropTypes.isRequired,
// };
export default CustomerEndpointForm;
