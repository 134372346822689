import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import theme from "../../theme";
import ListItemText from "@mui/material/ListItemText";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { Box, Button, Typography, Grid } from "@mui/material";
import { usePostFileMutation } from "../../redux/slices/uploadFileApi";
import ModalDialog from "../meters/ModalDialog";

import ErrorsAccordion from "./ErrorsAccordion";
import { useEffect, useState } from "react";
const UploadedFileShow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const operationId = sessionStorage.getItem("uuid");
  const [modal, setModal] = useState(false);
  const [postFile, { isSuccess }] = usePostFileMutation();
  const statistic = useSelector((state) => state?.uploadFileSlice?.statistics);
  const errors = useSelector((state) => state?.uploadFileSlice?.errors);

  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const statisticColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "file_name",
      headerName: t("file_name"),
      type: "number",
      width: 90,
      sortable: false,
      editable: true,
      flex: 1,
    },
    {
      field: "in_error_rows",
      headerName: t("in_error_rows"),
      type: "number",
      sortable: false,
      width: 90,

      editable: true,
      flex: 2,
    },

    {
      field: "operation_id",
      headerName: t("operation_id"),
      type: "number",
      width: 90,
      sortable: false,
      editable: false,
      flex: 5,
    },
    {
      field: "total_rows",
      headerName: t("total_rows"),
      type: "number",
      sortable: false,
      width: 90,
      editable: false,
      flex: 3,
    },
    {
      field: "valid_rows",
      headerName: t("valid_rows"),
      type: "number",
      sortable: false,
      width: 90,
      editable: false,
      flex: 3,
    },
  ];

  /*   const statisticRows = statistic?.map((item, i) => ({
    id: i,
    file_name: item.file_name,
    in_error_rows: item.in_error_rows,
    operation_id: item.operation_id,
    total_rows: item.total_rows,
    valid_rows: item.valid_rows,
  })); */
  const statisticRows = [
    {
      id: 1,
      file_name: statistic?.file_name,
      in_error_rows: statistic?.in_error_rows,
      operation_id: statistic?.operation_id,
      total_rows: statistic?.total_rows,
      valid_rows: statistic?.valid_rows,
    },
  ];
  const errorsColumn = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "errors",
      headerName: "errors",
      width: 90,
      sortable: false,
      flex: 1,
    },
  ];
  const errorsRows = errors?.errors?.map((item, i) => ({
    id: i,
    errors: item,
  }));
  useEffect(() => {
    console.log("wrfbro", statistic);
  }, [statistic]);
  const onImportClick = () => {
    if (errors.errors.length == 0) {
      const attributes = {
        operation_id: operationId,
      };
      postFile(attributes);
    } else {
      openModal();
    }
  };
  const modalSubmit = () => {
    const attributes = {
      operation_id: operationId,
    };
    postFile(attributes);
    closeModal();
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage("Imported successfully"));
    }
  }, [isSuccess]);
  return (
    <>
      <ModalDialog open={modal} close={closeModal} title={"Warning"}>
        <Typography sx={{ color: theme.palette.primary.main }}>
          {t("file_errors")}
        </Typography>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ mt: 2 }}
        >
          <Button onClick={closeModal}>{t("no")}</Button>
          <Button onClick={modalSubmit}>{t("yes")}</Button>
        </Box>
      </ModalDialog>
      <Typography sx={{ color: theme.palette.primary.main }} variant="h4">
        {t("import_details")}
      </Typography>
      {statistic && errors && (
        <>
          <Box sx={{ height: "35vh", mt: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {t("file_name")}: {statistic.file_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {t("total_rows")}: {statistic.total_rows}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {t("valid_rows")}: {statistic.valid_rows}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {t("invalid_rows")}: {statistic.in_error_rows}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <ErrorsAccordion>
            <Box sx={{ height: "35vh" }}>
              {errorsRows.length > 0 && (
                <List>
                  {errorsRows.map((item) => (
                    <ListItem>
                      <ListItemText
                        sx={{ color: theme.palette.primary.main }}
                        primary={item.errors}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
              {errorsRows.length < 1 && (
                <Typography sx={{ color: theme.palette.primary.main }}>
                  No Errors
                </Typography>
              )}
            </Box>
          </ErrorsAccordion>
          <Button onClick={onImportClick} sx={{ mt: 3 }} variant="contained">
            {t("import")}
          </Button>
        </>
      )}
    </>
  );
};
export default UploadedFileShow;
