import { useParams } from "react-router-dom";
import {
  useLazyGetDigitalTwinShowQuery,
  useLazyGetDigitalTwinsQuery,
} from "../../redux/slices/digitalTwinApi";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Chip } from "@material-ui/core";
import theme from "../../theme";
import { setDigitalTwinAttributes } from "../../redux/slices/digitalTwinSlice";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
const DigitalTwinShow = () => {
  const dispatch = useDispatch();
  const digitalTwinShowAttributes = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwinShowAttributes
  );
  const digitalTwins = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwins
  );
  const twinName = digitalTwinShowAttributes?.name;
  const twinVersion = digitalTwinShowAttributes?.version;
  const { t } = useTranslation();
  const [refreshedPage, setRefreshedPage] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const digitalTwin = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwinShow
  );
  const { id } = useParams();
  const [getDigitalTwinShow] = useLazyGetDigitalTwinShowQuery();
  const [getDigitalTwins] = useLazyGetDigitalTwinsQuery();
  useEffect(() => {
    if (id) {
      getDigitalTwinShow(id);
    }
  }, [id]);
  const delteUnderscore = (str) => {
    const newStr = str.replace(/_/g, " ");
    return newStr;
  };
  useEffect(() => {
    if (!digitalTwins) {
      getDigitalTwins();
      setRefreshedPage(true);
    }
  }, [digitalTwins]);
  useEffect(() => {
    if (refreshedPage && digitalTwins) {
      debugger;
      const showAttributes = digitalTwins?.filter((item) => item.id === id);
      dispatch(setDigitalTwinAttributes(showAttributes[0].attributes));
      setRefreshedPage(false);
    }
  }, [refreshedPage, digitalTwins]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 3,
      hide: true,
    },
    {
      field: "name",
      headerName: t("name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 4,
    },
    {
      field: "access",
      headerName: t("access"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 4,
    },

    {
      field: "type",
      headerName: t("type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        if (params.value === undefined) {
          return <>--</>;
        } else {
          return (
            <>
              <Chip
                /*     style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }} */
                label={params.value}
              />
            </>
          );
        }
      },
    },
  ];
  const tableRows = digitalTwin?.map((item) => ({
    id: item.id ? delteUnderscore(item.id) : "--",
    access: t(item?.attributes?.access) ?? "--",
    name: item?.attributes?.name ?? "--",
    type: item?.attributes?.type,
  }));
  return (
    <>
      {digitalTwinShowAttributes && (
        <>
          <Typography
            sx={{ color: theme.palette.primary.main }}
            variant="h4"
          >{`Digital Twin: ${twinName} (${t(
            "version"
          )}: ${twinVersion}) `}</Typography>
          <Box sx={{ height: "65vh", width: "99%", mt: 2 }}>
            {tableRows && (
              <DataGrid
                disableColumnMenu
                rows={tableRows}
                columns={columns}
                /*   rowCount={response?.data?.meta?.count} */
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                // loading={isLoadingTable}

                /*   getRowHeight={() => "auto"} */
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default DigitalTwinShow;
