import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: null,
  autoLoading: null,
};

const loadingSlice = createSlice({
  name: "loadingSlice",
  initialState,
  reducers: {
    loadingOn(state) {
      state.loading = true;
    },
    loadingOff(state) {
      state.loading = false;
    },

    autoloadingOn(state) {
      state.autoLoading = true;
    },
    autoloadingOff(state) {
      state.autoLoading = false;
    },
  },
});
export const { loadingOn, loadingOff, autoloadingOn, autoloadingOff } =
  loadingSlice.actions;
export default loadingSlice.reducer;
