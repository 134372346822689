import { api } from "./api";
import { prepareBodyWithoutId } from "../../utils/api_params";
import { prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { method } from "lodash";
const type = "plugin";

export const statisticApi = api.injectEndpoints({
  endpoints: (build) => ({
    createStatistic: build.mutation({
      query: (attributes) => ({
        url: "v1/plugin/statistics",
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, type)),
      }),
    }),
    getStatistics: build.query({
      query: (params) => {
        if (params != undefined && Object.keys(params).length > 0) {
          return `v1/plugin/statistics?${prepareParams(params)}`;
        } else {
          return `v1/plugin/statistics`;
        }
      },
    }),
    getStatistic: build.query({
      query: (id) => {
        return `v1/plugin/statistics/${id}`;
      },
    }),
    updateStatistic: build.mutation({
      query: ({ attributes, id }) => ({
        url: `v1/plugin/statistics/${id}`,
        method: "PUT",
        body: JSON.stringify(prepareBody(attributes, id.toString(), type)),
      }),
    }),
    deleteStatistic: build.mutation({
      query: (id) => ({
        url: `v1/plugin/statistics/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateStatisticMutation,
  useLazyGetStatisticsQuery,
  useLazyGetStatisticQuery,
  useUpdateStatisticMutation,
  useDeleteStatisticMutation,
} = statisticApi;
