import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import SimpleInputBoolean from "./SimpleInputBoolean";
import { Box } from "@mui/material";

const InputNumber = ({ item, control }) => {
	const { t } = useTranslation();
	return (
		<Controller
			name={item.attributes.name}
			control={control}
			render={({ field }) => (
				<>
					<Box sx={{ textAlign: "center" }}>
						<FormControlLabel
							value="top"
							control={<SimpleInputBoolean item={item} {...field} />}
							label={t(item.attributes.name)}
							labelPlacement="top"
						/>
					</Box>
				</>
			)}
		/>
	);
};

export default InputNumber;
