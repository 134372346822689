import React from "react";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import DeviceDistrictProvider from "../components/DeviceDistrictProvider";
import DeviceDistrictTable from "../components/deviceDistrict/DeviceDistrictTable";
import DeviceDistrictSearchBar from "../components/deviceDistrict/DeviceDistrictSearchBar";

const DeviceDistricts = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <DeviceDistrictProvider>
        <h1> {t("districts")}</h1>
        <Box sx={{ marginBottom: 5 }}>
          <DeviceDistrictSearchBar />
        </Box>
        <DeviceDistrictTable />
      </DeviceDistrictProvider>
    </Box>
  );
};

export default DeviceDistricts;
