import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const WidgetsAutocomplete = (props) => {
  const [value, setValue] = React.useState(null);
  const data = props?.schema?.data;
  React.useEffect(() => {
    if (value) {
      props.onChange(value.value);
    }
  }, [value]);
  React.useEffect(() => {
    console.log("ppprops", props);
    if (props?.formData) {
      debugger;
      const filteredData = data.filter(
        (item) => item.value === props?.formData
      );
      console.log("fd", filteredData);
      setValue(filteredData[0]);
    }
  }, [props?.formData]);
  React.useEffect(() => {
    console.log("propsix", props);
  }, [props]);
  return (
    <Autocomplete
      disablePortal
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      id="combo-box-demo"
      options={data}
      renderInput={(params) => (
        <TextField
          error={props?.rawErrors?.length > 0}
          {...params}
          label={props?.schema?.title}
        />
      )}
    />
  );
};
export default WidgetsAutocomplete;
