import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerAppBarOpen: false,
};
const utilsSlice = createSlice({
  name: "utilsSlice",
  initialState,
  reducers: {
    setHeaderAppBar(state) {
      state.headerAppBarOpen = !state.headerAppBarOpen;
    },
  },
});
export const { setHeaderAppBar } = utilsSlice.actions;

export default utilsSlice.reducer;
