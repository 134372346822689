import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";

import Loader from "../components/Loader";
import CustomerEndpointProvider from "../components/customer_endpoint/CustomerEndpointProvider";

import { useParams } from "react-router-dom";
import CustomerEndpointUpdateComponent from "../components/customer_endpoint/CustomerEndpointUpdateComponent";

const CustomerEndpointUpdate = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <Box>
      <h2> {t("update")} </h2>
      <Box sx={{ width: "100%" }}>
        <CustomerEndpointProvider>
          <CustomerEndpointUpdateComponent
            customerEndpointId={id}
          ></CustomerEndpointUpdateComponent>
        </CustomerEndpointProvider>
      </Box>
    </Box>
  );
};

export default CustomerEndpointUpdate;
