import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";

const SimpleInputBoolean = ({ item, onChange }) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(false);

	const handleChange = (event) => {
		setValue(event.target.checked);
		onChange(event.target.checked);
	};
	return (
		<>
			<Switch
				checked={value}
				onChange={handleChange}
				inputProps={{ "aria-label": "controlled" }}
			/>
		</>
	);
};

export default SimpleInputBoolean;
