import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { Button, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DashboardModal from "./DasboardModal";
import { useSetPreferenceMutation } from "../../redux/slices/userPreferenceApi";
import ErrorsHandler from "../../utils/ErrorsHandler";
import { useNavigate } from "react-router-dom";
import { useLazyGetDashboardListQuery } from "../../redux/slices/dashboardApi";
import { useLazyGetPreferenceQuery } from "../../redux/slices/userPreferenceApi";
import { addWidgetId, addWidget } from "../../redux/slices/dashboardSlice";
import { Box } from "@mui/system";
import { setPreferenceDashboardId } from "../../redux/slices/userPreferenceSlice";
import { selectType } from "./widgets/utils/widgetConfigurations";
import { useLazyGetShowWidgetsQuery } from "../../redux/slices/widgetsApi";
import { useCreateNewDashboardMutation } from "../../redux/slices/dashboardApi";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import GlodablLoading from "../../utils/GlobalLoading";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element, index) => {
        if (index !== 3) {
          return (
            <Grid key={element.id} alignItems="center" item xs={4}>
              {element.content}
            </Grid>
          );
        }
        if (index == 3) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
      })}
    </Grid>
  );
};
const ResponsiveGridLayout = WidthProvider(Responsive);
const DashboardCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createDashboard, { isSuccess }] = useCreateNewDashboardMutation();
  const [getPreference] = useLazyGetPreferenceQuery();
  const [firstDashId, setFirstDashId] = useState(null);
  const [setPreference, { isSuccess: preferenceSuccess }] =
    useSetPreferenceMutation();

  const [getDashboardList] = useLazyGetDashboardListQuery();
  const dispatch = useDispatch();
  const [getShowWidget, resultShowWidfet] = useLazyGetShowWidgetsQuery();
  const dashboardWidgets = useSelector(
    (state) => state.dashboardSlice?.widgets
  );
  const dashboardList = useSelector(
    (state) => state?.dashboardSlice?.dashboards
  );
  const firstPreferenceRender = useSelector(
    (state) => state?.userPreferenceSlice?.firstRender
  );

  const preferenceDashboardId = useSelector(
    (state) => state?.userPreferenceSlice?.dashboardId
  );
  const preferenceUpdating = useSelector(
    (state) => state?.userPreferenceSlice?.updating
  );
  const preference = useSelector(
    (state) => state?.userPreferenceSlice?.preference
  );
  const [filteredWidgets, setFilteredWidgets] = useState([]);
  const widgetIds = useSelector((state) => state.dashboardSlice?.widget_ids);
  const [layout, setLayout] = useState([]);
  const [cols, setColls] = useState({ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 });
  const [breakpoints, setBreakpoints] = useState({
    lg: 1600,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  });
  const [trashId, setTrashId] = useState();
  const [creationPhase] = useState(true);
  const [trashModal, setTrashModal] = useState();
  /*   useEffect(() => {
    console.log("resultCreateDash", result);
  }, [result]); */
  const [edit] = useState(true);
  const [formData, setFormData] = useState({});
  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });
  useEffect(() => {
    if (firstPreferenceRender) {
      getPreference();
    }
  }, []);

  const onLayoutChange = (layout) => {
    /*  debugger; */
    if (layout.length > 0) {
      let temp = layout.map((item, i) => ({
        w: 2,
        h: 1.1,
        x: item.x,
        y: item.y,
        i: item.i,
      }));
      console.log("TEMP", temp);
      setLayout(temp);
    }
  };
  const onBreakpiontsChange = (breakpoint, cols) => {
    setBreakpoints(breakpoint);
    setColls(cols);
  };
  const widgetShow = useSelector(
    (state) => state?.widgetsSlice?.widgetShow?.attributes
  );
  const dashboardWidgetIds = useSelector(
    (state) => state.dashboardSlice?.widget_ids
  );

  useEffect(() => {
    console.log("LAYOUT", layout);
  }, [layout]);
  useEffect(() => {
    console.log("FD", formData?.widget);
  }, [formData]);
  useEffect(() => {
    if (formData?.widget && formData?.widget?.length !== 0) {
      dispatch(addWidgetId(formData?.widget));
      getShowWidget({ id: formData?.widget });
    }
  }, [formData?.widget]);
  useEffect(() => {
    if (widgetShow !== null) {
      dispatch(addWidget(widgetShow));
    }
  }, [widgetShow]);

  useEffect(() => {
    console.log("PREV LAy", layout);
  }, [layout]);
  const onSubmitClick = () => {
    const body = {
      data: {
        attributes: {
          description: formData?.description,
          groups: ["/admin"],
          name: formData?.name,
          refresh_rate: formData.refresh_rate * 1000,
          widget_ids: widgetIds,
          layout: layout,
        },
        type: "plugin",
      },
    };
    /* console.log("body", body); */
    createDashboard(body)
      .unwrap()
      .then((data) => setFirstDashId(data?.data?.attributes?.id));

    // navigate("/metrics");
  };
  useEffect(() => {
    console.log("firstDashId", firstDashId);
  }, [firstDashId]);
  /*   useEffect(() => {
    if (isSuccess && !preferenceDashboardId) {
      getDashboardList();
    }
  }, [isSuccess]); */
  useEffect(() => {
    if (!preferenceDashboardId && firstDashId) {
      debugger;
      dispatch(setPreferenceDashboardId(firstDashId));
    }
  }, [firstDashId, preferenceDashboardId]);
  useEffect(() => {
    if (isSuccess && preferenceDashboardId) {
      window.location.href = "/metrics";
    }
  }, [isSuccess, dashboardList]);
  useEffect(() => {
    if (isSuccess && preferenceSuccess) {
      window.location.href = "/metrics";
    }
  }, [isSuccess, preferenceSuccess]);

  useEffect(() => {
    if (preferenceUpdating) {
      setPreference(preference);
    }
  }, [preferenceUpdating]);
  const schema = {
    title: "New widget",
    type: "object",
    properties: {
      widget: {
        id: "1",
        type: "number",
        title: "widget",
        url: "plugin/widgets?page=1",
        widgetSelect: true,
        filteredWidgets: dashboardWidgetIds,
      },
      name: {
        id: "2",
        type: "string",
        title: t("name"),
      },
      refresh_rate: {
        id: "3",
        type: "number",
        title: t("refresh_rate"),
      },
      description: {
        id: "4",
        type: "string",
        title: t("description"),
      },
    },
    required: ["widget", "name", "refresh_rate", "description"],
  };
  const fields = {
    serverSearch: SearchOnServer,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    widget: {
      "ui:field": "serverSearch",
    },
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={() => onSubmitClick()}
          uiSchema={uiSchema}
          validator={validator}
          showErrorList={false}
          noHtml5Validate
        >
          <Button sx={{ mt: 2 }} type="submit" variant="contained">
            {t("create")}
          </Button>
          <Button
            onClick={() => (window.location.href = "/metrics")}
            sx={{ mt: 2, ml: 2 }}
            variant="contained"
          >
            {t("cancel")}
          </Button>
        </Form>
      </Box>
      <Box>
        <ResponsiveGridLayout
          style={{ minHeight: 900, marginTop: 30 }}
          className="layout"
          layouts={{
            lg: layout,
            md: layout,
            sm: layout,
            xs: layout,
            xxs: layout,
          }}
          autoSize={true}
          isResizable={false}
          breakpoints={breakpoints}
          cols={cols}
          rowHeight={260}
          onLayoutChange={onLayoutChange}
          /*  onBreakpointChange={onBreakpiontsChange} */
          isDraggable={edit}
          isBounded={true}
          horizontalCompact
        >
          {dashboardWidgets?.map((widget) =>
            selectType(widget, edit, setTrashModal, setTrashId, creationPhase)
          )}
        </ResponsiveGridLayout>
      </Box>
    </>
  );
};

export default DashboardCreate;
