import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import InputSelect from "./form/InputSelect";
import DeviceCommandSubForm from "./DeviceCommandSubForm";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDeviceCommand } from "./DeviceCommandProvider";
import ApiError from "./ApiError";
import LoadingButton from "@mui/lab/LoadingButton";
import IwdSnackBar from "./IwdSnackBar";
/**
 * Show the command form
 */
const DeviceCommandForm = ({ instance, categories }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [commandType, setCommandType] = useState();
  const { sendCommand, error, loading, response } = useDeviceCommand();

  const onCommandChanged = ({
    target: {
      value: {
        value: { type },
      },
    },
  }) => {
    setCommandType(type);
  };

  useEffect(() => {
    if (response && response.ok) {
      setShowSuccessMessage(true);
    } else {
      setShowSuccessMessage(false);
    }
  }, [response, loading]);

  return (
    <Box sx={{ height: "69vh" }}>
      {error ? <ApiError error={error} /> : ""}
      {response && response.ok ? (
        <>
          {"cane "}
          <IwdSnackBar
            isOpen={showSuccessMessage}
            severity="success"
            message={t("message_sent")}
            callback={setShowSuccessMessage}
          />
        </>
      ) : (
        <></>
      )}
      <form
        style={{ height: "100%" }}
        onSubmit={handleSubmit(
          (
            {
              command: {
                value: { type, name },
              },
              params,
            },
            event
          ) => {
            switch (type) {
              case "map":
                sendCommand({
                  commandName: name,
                  digitalTwinId: instance.data.attributes.digital_twin_id,
                  instanceId: instance.data.id,
                  body: params ? params.jsObject : {},
                });

                break;

              case "void":
                sendCommand({
                  commandName: name,
                  digitalTwinId: instance.data.attributes.digital_twin_id,
                  instanceId: instance.data.id,
                  body: { values: {} },
                });

                break;

              default:
                console.log("none");
            }
          }
        )}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box sx={{ flexGrow: 1 }}>
            <InputSelect
              options={(categories["command"] ? categories["command"] : []).map(
                (c) => {
                  return { label: c.attributes.name, value: c.attributes };
                }
              )}
              control={control}
              onChange={onCommandChanged}
              item={{ attributes: { name: "command" } }}
            />
          </Box>
          <Box>
            <LoadingButton variant="contained" loading={loading} type="submit">
              {" "}
              {t("send")}
            </LoadingButton>
          </Box>
        </Box>
        <Box sx={{ height: "100%" }}>
          <DeviceCommandSubForm commandType={commandType} control={control} />
        </Box>
      </form>
    </Box>
  );
};

DeviceCommandForm.propTypes = {
  /** Categories of DigitalTwin, is a map */
  categories: PropTypes.isRequired,
};
export default DeviceCommandForm;
