import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";

const RenderItem = ({ item, control }) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={item.attributes.name}
      control={control}
      onChange={(e) => {
        console.log(e);
      }}
      render={({ field }) => (
        <>
          <TextField
            fullWidth={true}
            label={t(item.attributes.name)}
            variant="outlined"
            onChange={(e) => {
              debugger;
              field.onChange(e.targe.value.split(","));
            }}
            {...field}
          />
        </>
      )}
    />
  );
};

const RenderSimpleInput = ({
  name,
  value,
  control,
  onChange,
  rules,
  ...attrs
}) => {
  const parentOnChange = onChange;
  const parentValue = value;
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={parentValue}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <>
            <TextField
              fullWidth={true}
              label={t(name)}
              variant="outlined"
              onChange={(e) => {
                onChange(e);
                if (parentOnChange) {
                  debugger;
                  parentOnChange(e);
                }
              }}
              onBlur={onBlur}
              inputRef={ref}
              value={value}
              {...attrs}
            />
          </>
        );
      }}
    />
  );
};

const InputTags = ({ item, control, name, value, error, ...attrs }) => {
  if (item) {
    return <RenderItem item={item} control={control} {...attrs} />;
  } else {
    return (
      <>
        <RenderSimpleInput
          name={name}
          control={control}
          value={value}
          {...attrs}
          error={error}
        />
        {error ? (
          <FormHelperText error>
            {error.type} {error.message}
          </FormHelperText>
        ) : (
          <></>
        )}
      </>
    );
  }
};

export default InputTags;
