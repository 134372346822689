import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Box,
  List,
  ListItem,
  BoxProps,
  makeStyles,
  TextField,
  Button,
} from "@mui/material";
import { BlurOn, BoltRounded } from "@mui/icons-material";
import theme from "../../theme";
import { red } from "@mui/material/colors";
import {
  Polygon,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  CircleMarker,
  Circle,
  useMapEvents,
  useMap,
} from "react-leaflet";
import { circle } from "@turf/circle";
import { faWeight } from "@fortawesome/free-solid-svg-icons";
import useFetch from "use-http";
import { useState } from "react";
import L from "leaflet";
import { useRef } from "react";
import DeviceSvg from "../../statics/icons/device.svg";
import DeviceOrangeSvg from "../../statics/icons/device-orange.svg";
import DeviceGatewaySvg from "../../statics/icons/device-gateway.svg";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import SendIcon from "@mui/icons-material/Send";
import IwdSnackBar from "../IwdSnackBar";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { geoJsonPolygonSwapCoordinates } from "../../utils/geo_json";
import MarkerClusterGroup from "react-leaflet-cluster";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GatewayCoverage = ({ device }) => {
  const { t } = useTranslation();

  const position = [
    device.data.attributes.coordinates.coordinates[1],
    device.data.attributes.coordinates.coordinates[0],
  ];
  const fillBlueOptions = { fillColor: "blue" };
  var radius = 1; //kilometers

  const { loading, error, get } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );

  var turf = require("@turf/turf");
  var options = { steps: 10, units: "kilometers", properties: { foo: "bar" } };
  //   turf.circle(position, radius, options);

  const deviceIconBlue = () =>
    new L.icon({
      iconUrl: DeviceSvg,
      iconSize: 20,
    });

  const deviceIconOrange = () =>
    new L.icon({
      iconUrl: DeviceOrangeSvg,
      iconSize: 20,
    });

  const gatewayIcon = () =>
    new L.icon({
      iconUrl: DeviceGatewaySvg,
      iconSize: 20,
    });

  const [circle, setCircle] = useState(
    turf.circle(
      position,
      device.data.attributes.fields.range
        ? device.data.attributes.fields.range
        : radius,
      options
    )
  );
  const [meters, setMeters] = useState([]);

  var classes = process.env.REACT_APP_WATER_METER_CLASSES.split(" ");

  const theme = useTheme();
  const [deviceClass, setDeviceClass] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDeviceClass(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getMeters = async () => {
    //translate and probably change the way the message is showing

    const deviceId = device.data.id;
    const to = new Date();
    const from = new Date(to);
    from.setDate(from.getDate() - 1);

    const newCircle = geoJsonPolygonSwapCoordinates(circle.geometry);

    if (deviceClass == "") {
      const result = await get(
        `/api/v1/altior/device?gateway_meters[polygon_json]=${JSON.stringify(
          newCircle
        )}&gateway_meters[gateway_id]=${deviceId}&gateway_meters[from]=${from.toISOString()}&gateway_meters[to]=${to.toISOString()}`
      );
      const meters = result.data;
      setMeters(meters);
    } else {
      var classes = deviceClass.join();
      const result = await get(
        `/api/v1/altior/device?gateway_meters[polygon_json]=${JSON.stringify(
          newCircle
        )}&gateway_meters[gateway_id]=${deviceId}&gateway_meters[from]=${from.toISOString()}&gateway_meters[to]=${to.toISOString()}&classes=${classes}`
      );
      const meters = result.data;
      debugger;
      setMeters(meters);
    }
  };

  const exportData = async () => {
    //translate and probably change the way the message is showing

    const deviceId = device.data.id;
    const to = new Date();
    const from = new Date(to);
    from.setDate(from.getDate() - 1);

    let blob = null;

    const newCircle = geoJsonPolygonSwapCoordinates(circle.geometry);

    if (deviceClass == "") {
      blob = await get(
        `/api/v1/altior/device/export?gateway_meters[polygon_json]=${JSON.stringify(
          newCircle
        )}&gateway_meters[gateway_id]=${deviceId}&gateway_meters[from]=${from.toISOString()}&gateway_meters[to]=${to.toISOString()}`
      );
    } else {
      blob = deviceClass.join();
      const result = await get(
        `/api/v1/altior/device/export?gateway_meters[polygon_json]=${JSON.stringify(
          newCircle
        )}&gateway_meters[gateway_id]=${deviceId}&gateway_meters[from]=${from.toISOString()}&gateway_meters[to]=${to.toISOString()}&classes=${classes}`
      );
    }

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `export.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const prepareMarker = (meters) => {
    meters?.map((meter) => {
      if (!meter.attributes.seen) {
        return (
          <Marker
            position={meter.attributes.coordinates.coordinates}
            icon={deviceIconOrange()}
          ></Marker>
        );
      } else {
        return (
          <Marker
            position={meter.attributes.coordinates.coordinates}
            icon={deviceIconBlue()}
          ></Marker>
        );
      }
    });
  };

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      {!loading && !error && meters.length > 0 ? (
        <IwdSnackBar
          severity="success"
          isOpen={!loading && !error && meters.length > 0}
          message={t("loaded_with_success")}
        />
      ) : (
        <></>
      )}
      <div style={{ display: "flex" }}>
        <FormControl sx={{ m: 1, flexGrow: 3 }}>
          <InputLabel id="demo-multiple-chip-label">Classes</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={deviceClass}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Classes" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {classes.map((clas) => (
              <MenuItem key={clas} value={clas}>
                {clas}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          sx={{
            p: 1,
            m: 1.5,
          }}
          onClick={getMeters}
        >
          {t("Apply")}
        </Button>

        <Button
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          sx={{
            p: 1,
            m: 1.5,
          }}
          onClick={exportData}
        >
          {t("Export")}
        </Button>
      </div>

      {
        <MapContainer
          center={position}
          zoom={14}
          style={{ minHeight: 600, height: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Circle
            center={position}
            pathOptions={fillBlueOptions}
            radius={
              device.data.attributes.fields.range
                ? device.data.attributes.fields.range * 1000
                : radius * 1000
            }
          >
            <Popup>{t("The covered area")}</Popup>
          </Circle>

          {/* {prepareMarker(meters)} */}
          <MarkerClusterGroup chunkedLoading maxClusterRadius={20}>
            {meters.map((meter) =>
              meter.attributes.seen == false ? (
                <Marker
                  position={[
                    meter.attributes.coordinates.coordinates[1],
                    meter.attributes.coordinates.coordinates[0],
                  ]}
                  icon={deviceIconOrange()}
                >
                  <Popup>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", m: 1 }}
                    >
                      <Box>
                        {" "}
                        <b>{t("Serial number")}:</b>{" "}
                        {meter.attributes.serial_number}{" "}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Box>
                          <b>{t("seen")}:</b>
                          {meter.attributes.seen ? t("yes") : t("no")}{" "}
                        </Box>
                        <Button
                          as={Link}
                          to={`/devices/${meter.id}`}
                          target="_blank"
                        >
                          <VisibilityIcon />
                        </Button>
                      </Box>
                    </Box>
                  </Popup>
                </Marker>
              ) : (
                <Marker
                  position={[
                    meter.attributes.coordinates.coordinates[1],
                    meter.attributes.coordinates.coordinates[0],
                  ]}
                  icon={deviceIconBlue()}
                >
                  <Popup>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", m: 1 }}
                    >
                      <Box>
                        {" "}
                        <b>{t("Serial number")}:</b>{" "}
                        {meter.attributes.serial_number}{" "}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Box>
                          <b>{t("seen")}: </b>
                          {meter.attributes.seen ? t("yes") : t("no")}
                        </Box>
                        <Button
                          as={Link}
                          to={`/devices/${meter.id}`}
                          target="_blank"
                        >
                          <VisibilityIcon />
                        </Button>
                      </Box>
                    </Box>
                  </Popup>
                </Marker>
              )
            )}
          </MarkerClusterGroup>

          <Marker position={position} icon={gatewayIcon()}>
            <Popup>
              {t("The Gateway")} <br />
            </Popup>
          </Marker>
        </MapContainer>
      }
    </Box>
  );
};

export default GatewayCoverage;
