import React, { useEffect, useState } from "react";
import { Polygon, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "@bopen/leaflet-area-selection/dist/index.css";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { useDeviceDistrict } from "../DeviceDistrictProvider";

import DeviceDistrictMapComponent from "./DeviceDistrictMapComponent";

const defaultLocation = [45.40667650588728, 11.879199098463717];
const DeviceDistrictMap = () => {
  const { addPolygon, drawPolygon, currentPolygon, setCurrentPolygon } =
    useDeviceDistrict();
  const { t } = useTranslation();
  const [location] = useState(defaultLocation);

  return (
    <Box sx={{ height: "100%" }}>
      <Box>
        <Tooltip title={t("addNewPolygon")} placement="top">
          <IconButton
            onClick={() => {
              debugger;
              drawPolygon.activate();
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={t("save")} placement="top">
          <IconButton
            onClick={() => {
              drawPolygon.deactivate();
              drawPolygon.clearPolygon();
              addPolygon(currentPolygon);
            }}
          >
            <BeenhereIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("remove")} placement="top">
          <IconButton
            onClick={() => {
              drawPolygon.deactivate();
              drawPolygon.clearPolygon();
              setCurrentPolygon(null);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <MapContainer
        center={location}
        zoom={13}
        style={{ minHeight: 600, height: "100%" }}
      >
        <DeviceDistrictMapComponent></DeviceDistrictMapComponent>
      </MapContainer>
    </Box>
  );
};

export default DeviceDistrictMap;
