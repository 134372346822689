import React from "react";
import InputJSON from "./form/InputJSON";
import InputNumber from "./form/InputNumber";
import InputText from "./form/InputText";
import InputTags from "./form/InputTags";
import InputBoolean from "./form/InputBoolean";
import InputDateTime from "./form/InputDateTime";
import InputCoordinates from "./form/InputCoordinates";
import NetwokrAdaptersSelect from "./NetwokrAdaptersSelect";
import ActiveNetworkAdaptersSelect from "./ActiveNetwokrAdaptersSelect";

const checkByName = (item, control, selectedDigitalTwin) => {
  switch (item.attributes.name) {
    case "coordinates":
      return <InputCoordinates item={item} control={control} />;

    case "network_adapters":
      return <NetwokrAdaptersSelect item={item} control={control} />;

    case "active_network_adapters":
      return <ActiveNetworkAdaptersSelect item={item} control={control} />;
    default:
      return checkByType(item, control, selectedDigitalTwin);
  }
};

const checkByType = (item, control, selectedDigitalTwin) => {
  const value =
    selectedDigitalTwin.attributes.digital_twin_fields[item.attributes.name];
  switch (item.attributes.type) {
    case "string":
      return <InputText item={item} control={control} defaultValue={value} />;
    case "map":
      return <InputJSON item={item} control={control} defaultValue={value} />;
    case "{array, map}":
      return <InputJSON item={item} control={control} defaultValue={value} />;
    case "{:array, :map}":
      return <InputJSON item={item} control={control} defaultValue={value} />;
    case "{array, string}":
      return <InputTags item={item} control={control} defaultValue={value} />;
    case "{:array, :string}":
      return <InputJSON item={item} control={control} defaultValue={value} />;
    case "integer":
      return <InputNumber item={item} control={control} defaultValue={value} />;
    case "float":
      return <InputText item={item} control={control} defaultValue={value} />;
    case "boolean":
      return (
        <InputBoolean item={item} control={control} defaultValue={value} />
      );
    case "utc_datetime":
      return (
        <InputDateTime item={item} control={control} defaultValue={value} />
      );

    default:
      return (
        <li key={item.id}>
          {" "}
          {item.id} - {item.attributes.type} access: {item.attributes.access}{" "}
        </li>
      );
  }
};

const DeviceFormItem = ({ item, control, selectedDigitalTwin }) => {
  return checkByName(item, control, selectedDigitalTwin);
};

export default DeviceFormItem;
