import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";

import { Avatar, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setDigitalTwinAttributes } from "../../redux/slices/digitalTwinSlice";
import { useLazyGetDigitalTwinsQuery } from "../../redux/slices/digitalTwinApi";
const DigitalTwins = () => {
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const [perPage, setPerPage] = React.useState(10);
  const digitalTwins = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwins
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getDigitalTwins] = useLazyGetDigitalTwinsQuery();
  useEffect(() => {
    getDigitalTwins();
  }, []);
  const onNavigateClick = (id, name) => {
    debugger;
    const showAttributes = digitalTwins.filter((item) => item.id === id);
    navigate(`/digitaltwins/${id}`);
    dispatch(setDigitalTwinAttributes(showAttributes[0].attributes));
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "name",
      headerName: t("name"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "version",
      headerName: t("version"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "identifier_type",
      headerName: t("identifier_type"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 3,
      renderCell: (params) => {
        if (!params.value) {
          return <>--</>;
        } else {
          return (
            <>
              <Chip
                /*     style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }} */
                label={params.value}
              />
            </>
          );
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 0,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => onNavigateClick(params.value.id, params.value.name)}
          >
            <VisibilityIcon
              sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
            />
          </IconButton>
        );
      },
    },
  ];
  const tableRows = digitalTwins?.map((item) => ({
    id: item.id ? item.id : "--",
    identifier_type:
      item?.attributes?.digital_twin_fields?.identifier_type ?? "--",
    name: item?.attributes?.name ?? "--",
    version: item?.attributes?.version ?? "--",
    actions: { id: item.id, name: item?.attributes?.name },
  }));
  return (
    <Box sx={{ height: "65vh" }}>
      {tableRows && (
        <DataGrid
          disableColumnMenu
          rows={tableRows}
          columns={columns}
          /*   rowCount={response?.data?.meta?.count} */
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          // loading={isLoadingTable}

          /*   getRowHeight={() => "auto"} */
        />
      )}
    </Box>
  );
};
export default DigitalTwins;
