import Tooltip from "@mui/material/Tooltip";
import PopoverDashboardCardError from "../Popover";
import {
  selectColor,
  selectTextColor,
  selectLabel,
} from "./utils/widgetConfigurations";
import Box from "@mui/material/Box";
import { Grid, Typography, useTheme, Paper, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import PopoverCardTitle from "../PopoverCardTitle";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import { deleteCreatedWidget } from "../../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import GroupIcon from "@mui/icons-material/Group";

const SingleValuewidget = ({
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase,
}) => {
  const dispatch = useDispatch();
  const previewMode = useSelector((state) => state?.widgetsSlice?.previewMode);
  const [linkUnderline, setLinkUnderline] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const onTrashClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };
  const onDelete = (id) => {
    dispatch(deleteCreatedWidget(id));
  };
  useEffect(() => {
    console.log("crashed", widget);
  }, [widget]);
  return (
    <Paper
      elevation={5}
      sx={{
        maxWidth: 400,
        height: 285,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ m: 1 }}>
        <PopoverCardTitle
          title={widget?.name}
          description={widget?.description}
        />
        <Divider />
        {edit && (
          <DeleteIcon
            onClick={
              creationPhase
                ? () => onDelete(widget?.id)
                : () => onTrashClick(widget?.id)
            }
            sx={{
              float: "right",
              fill: theme?.palette.error.main,
              cursor: "pointer",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Typography variant="h1" color={theme?.palette.primary.main}>
          {widget.link && (
            <a
              onMouseEnter={() => setLinkUnderline(true)}
              onMouseLeave={() => setLinkUnderline(false)}
              style={{
                textDecoration: linkUnderline ? "" : "none",
                color: theme?.palette.primary.main,
              }}
              href={widget?.link}
              target="_blank"
            >
              {previewMode && 0}
              {!previewMode && widget?.last_update?.data.length < 1 && "NA"}
              {!previewMode &&
                widget?.last_update?.data.length > 0 &&
                widget?.last_update?.data[0][
                  Object.keys(widget?.last_update?.data[0])[0]
                ]}

              {/* {previewMode
                ? 0
                : widget?.last_update?.data[0][
                    Object.keys(widget?.last_update?.data[0])[0]
                  ]} */}
            </a>
          )}
          {!widget.link && (
            <>
              {previewMode && 0}
              {!previewMode && widget?.last_update?.data.length < 1 && "NA"}
              {!previewMode &&
                widget?.last_update?.data.length > 0 &&
                widget?.last_update?.data[0][
                  Object.keys(widget?.last_update?.data[0])[0]
                ]}
            </>
          )}
        </Typography>
        {widget?.last_error?.date && !previewMode && (
          <PopoverDashboardCardError error={widget?.last_error} />
        )}
      </Box>
      <Box sx={{ m: 1 }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={1}
          direction="row"
          spacing={1}
          xs={12}
        >
          {widget?.link ? (
            <>
              <Grid item xs={2}>
                {widget?.tags?.map((item) => (
                  <Chip
                    style={{
                      backgroundColor:
                        /* selectColor(widget?.tags?.[0], theme), */ theme
                          .palette.primary.main,
                      color: selectTextColor(widget?.tags?.[0]),
                    }}
                    label={selectLabel(widget?.tags?.[0], theme)}
                  />
                ))}
              </Grid>
              <Grid item xs={2}>
                {widget?.link && (
                  <IconButton href={widget?.link} target="_blank">
                    <BookmarksIcon
                      sx={{ ml: 1, fill: theme?.palette.primary.main }}
                      /* sx={{ fill: selectColor(widget?.tags?.[0]) }} */
                    />
                  </IconButton>
                )}
              </Grid>
            </>
          ) : (
            <Grid item xs={4}>
              {widget?.tags?.map((item) => (
                <Chip
                  style={{
                    backgroundColor:
                      /* selectColor(widget?.tags?.[0], theme), */ theme.palette
                        .primary.main,
                    color: selectTextColor(widget?.tags?.[0], theme),
                  }}
                  label={selectLabel(widget?.tags?.[0], theme)}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={8}>
            <Tooltip title={t("last_update")}>
              <Typography
                textAlign="end"
                variant="body2"
                sx={{ fontSize: "0.75rem" }}
              >
                {t("dt", { val: new Date(widget?.last_update?.date) })}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SingleValuewidget;
