import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";

const InputNumber = ({ item, control }) => {
	const { t } = useTranslation();
	return (
		<Controller
			name={item.attributes.name}
			control={control}
			render={({ field }) => (
				<>
					<TextField
						fullWidth={true}
						label={t(item.attributes.name)}
						type="number"
						variant="outlined"
						{...field}
					/>
				</>
			)}
		/>
	);
};

export default InputNumber;
