import React, { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
/**
 * Component that render the coordinates.
 *
 * @param  props
 * @param {string} props.name - the name of elemente
 * @param props.onChange - function called when an element is changed
 */
const SimpleInputCoordinates = ({ item, onChange }) => {
  const { t } = useTranslation();
  const [coordinates, setCoordinates] = useState({
    coordinates: [0.0, 0.0],
    type: "Point",
  });
  useEffect(() => {
    if (coordinates.coordinates[0] === 0 && coordinates.coordinates[1] === 0) {
      if (onChange) {
        onChange(coordinates);
      }
    }
  }, [coordinates]);
  const handleChange = (event) => {
    let newCoordinates = {};
    Object.assign(newCoordinates, coordinates);
    let points = newCoordinates.coordinates;

    switch (event.target.name) {
      case "latitude":
        points[0] = parseFloat(event.target.value);
        break;
      case "longitude":
        points[1] = parseFloat(event.target.value);
        break;
      default:
        break;
    }
    setCoordinates(newCoordinates);
    onChange(newCoordinates);
  };
  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          id="input-latitude"
          type="number"
          name="latitude"
          label={t("latitude")}
          value={coordinates.coordinates[0]}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">@lat</InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <TextField
          id="input-longitude"
          type="number"
          name="longitude"
          label={t("longitude")}
          value={coordinates.coordinates[1]}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">@lng</InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>
    </>
  );
};
export default SimpleInputCoordinates;
