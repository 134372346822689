import React from "react";
import L from "leaflet";
import {
  TileLayer,
  Popup,
  MapContainer,
  ScaleControl,
  Marker,
} from "react-leaflet";
import DeviceSvg from "../statics/icons/device.svg";
import { Skeleton } from "@mui/material";

const DeviceMap = ({ device }) => {
  const deviceIcon = () =>
    new L.icon({
      iconUrl: DeviceSvg,
      iconSize: 20,
    });
  if (!device) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={300}
        animation="wave"
      />
    );
  }
  return (
    <MapContainer
      style={{ zIndex: 0 }}
      id="show-device-map"
      center={[
        device.attributes.coordinates.coordinates[1],
        device.attributes.coordinates.coordinates[0],
      ]}
      zoom={13}
      scrollWheelZoom={true}
    >
      <ScaleControl />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        icon={deviceIcon()}
        key={device.id}
        position={[
          device.attributes.coordinates.coordinates[1],
          device.attributes.coordinates.coordinates[0],
        ]}
      ></Marker>
    </MapContainer>
  );
};

export default DeviceMap;
