import { api } from "./api";
import { prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";

export const gatewayApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIndexGateway: build.query({
      query: (params) => {
        return `v1/altior/device?${prepareParams(params)}`;
      },
    }),
    getShowGateway: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/0/instance/${id}`;
      },
    }),
    createGateway: build.mutation({
      query: ({ id, attributes }) => ({
        url: `v1/altior/digital_twin/${id}/instance`,
        method: "POST",
        body: JSON.stringify(prepareBodyWithoutId(attributes, "devices")),
      }),
    }),
    getGatewayMessages: build.query({
      query: ({ twinId, instanceId, params }) => ({
        method: "GET",
        url: `v1/altior/digital_twin/${twinId}/instance/${instanceId}/message?${prepareParams(
          params
        )}`,
      }),
    }),
  }),
});
export const {
  useLazyGetIndexGatewayQuery,
  useLazyGetShowGatewayQuery,
  useCreateGatewayMutation,
  useLazyGetGatewayMessagesQuery,
} = gatewayApi;
