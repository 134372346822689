import { api } from "./api";
import { prepareBodyWithoutId } from "../../utils/api_params";
export const meterApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCommands: build.query({
      query: (id) => {
        return `v1/altior/digital_twin/${id}/attribute`;
      },
    }),
    postCommands: build.mutation({
      query: ({
        digitalTwinId,
        digitalTwinInstance,
        commandName,
        attributes,
      }) => ({
        method: "POST",
        url: `v1/altior/digital_twin/${digitalTwinId}/instance/${digitalTwinInstance}/command/${commandName}`,
        body: JSON.stringify(
          prepareBodyWithoutId(attributes, "device_commands")
        ),
      }),
    }),
  }),
});
export const { useLazyGetCommandsQuery, usePostCommandsMutation } = meterApi;
