import React from "react";
import logo from './logo.svg';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <Button>
          {t('test')}
        </Button>
      </header>
    </div>
  );
}

export default App;
