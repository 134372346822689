import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import FileUploading from "react-files-uploading";

const FileWidget = (props) => {
  /*  const [files, setFiles] = useState([]);

  const onChange = (fileList) => {
    setFiles(fileList);
  };

  useEffect(() => {
    console.log("FILE", files);
  }, [files]);

  return (
    <div className="App">
      <FileUploading multiple value={files} maxNumber={10} onChange={onChange}>
        {({
          fileList,
          errors,
          isDragging,
          onFileUpload,
          onFileRemoveAll,
          onFileUpdate,
          onFileRemove,
          dragProps,
        }) => {
          return (
            <div className="upload__file-wrapper">
              {errors && errors.maxNumber && (
                <span>Number of selected files exceed maxNumber</span>
              )}

              <button
                id="btn-upload"
                type="button"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onFileUpload}
                {...dragProps}
              >
                Click or Drop here
              </button>

              <button id="btn-remove" type="button" onClick={onFileRemoveAll}>
                Remove all files
              </button>

              <div id="list-files">
                {fileList.map((file, index) => (
                  <div key={`file-${index}`} className="file-item">
                    <p>{file.name}</p>
                    <div className="file-item__btn-wrapper">
                      <button
                        id={`update_${index}`}
                        type="button"
                        onClick={() => onFileUpdate(index)}
                      >
                        {`Update ${index}`}
                      </button>
                      <button
                        id={`remove_${index}`}
                        type="button"
                        onClick={() => onFileRemove(index)}
                      >
                        {`Remove ${index}`}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </FileUploading>
    </div>
  ); */
  const [fileName, setFileName] = useState("");
  const [fileContent, setFileConent] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      setFileName(file.name);
      setFileConent(reader.result);
    };
  };

  useEffect(() => {
    if (fileContent) {
      props.onChange(fileContent);
    }
  }, [fileContent]);

  return (
    <div>
      <h1>File Reader</h1>
      <input type="file" onChange={handleFileChange}></input>
      <p>{fileName}</p>
      {/*  <p>{fileContent}</p> */}
    </div>
  );
};
export default FileWidget;
