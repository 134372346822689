import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Box,
  Button,
  Typography,
  Tooltip,
  Divider,
} from "@mui/material";
import Loader from "../components/Loader";
import { convertParamsToString } from "../utils";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IwdTable, { useIwdTable } from "../components/IwdTable";
import DeleteDialog from "../components/DeleteDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useIwdBreadCrumb } from "../components/IwdBreadcrumbProvider";
import DeviceGroupSearchProvider, {
  DeviceGroupSearchConsumer,
} from "../components/deviceGroup/DeviceGroupSearchProvider";

const DeviceGroup = () => {
  const [selectedId, setSelectedId] = useState({});
  const { setLast } = useIwdBreadCrumb();
  const [showDeleteDevice, setShowDeleteDevice] = useState(false);
  const [callback, setCallback] = useState();
  const [group, setGroup] = useState();
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const { loading, error, get } = useFetch(
    `${process.env.REACT_APP_SWARM_BASEURL}`,
    (globalOptions) => {
      globalOptions.cachePolicy = "no-cache";
      return globalOptions;
    }
  );
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    page,
    pages,
    onChangePage,
    onPageNext,
    onPagePrev,
    setPage,
    setPages,
  } = useIwdTable();

  const [devices, setDevices] = useState();
  const [params, setParams] = useState({ per_page: 10, page: 1 });
  const getDevices = () => {
    get(
      `/api/v1/device_group/${group.id}/device?${convertParamsToString(params)}`
    ).then((value) => {
      setDevices(value);
    });
  };

  const columns = (removeDevice) => [
    {
      name: t("id"),
      selector: (row) => row.id,
    },
    {
      name: t("serialNumber"),
      selector: (row) => row.attributes.serial_number,
    },
    {
      name: t("class"),
      selector: (row) => row.attributes.class,
    },
    {
      name: t("inserted_at"),
      selector: (row) => row.attributes.inserted_at,
    },
    {
      name: t("actions"),
      selector: (row) => (
        <>
          <Stack direction="row">
            <Tooltip title={t("show_device")}>
              <Button as={Link} to={`/devices/${row.id}`}>
                <VisibilityIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("delete_associated_device")}>
              <Button
                onClick={() => {
                  setSelectedId({ groupId: id, deviceId: row.id });
                  setCallback(() => ({ groupId, deviceId }) => {
                    removeDevice(groupId, deviceId).then(() => getDevices());
                  });
                  setShowDeleteDevice(true);
                }}
                color="error"
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (params.page && group && Object.keys(params).length >= 2) {
      setLast(group.attributes.name);
      getDevices();
    }
  }, [params, group]);

  useEffect(() => {
    if (params.page && params.page !== page) {
      setParams({ ...params, page: page });
    }
  }, [page]);

  useEffect(() => {
    const fetchData = async (id) => {
      const result = await get(`/api/v1/device_group/${id}`);
      if (result) {
        setGroup(result.data);
      }
    };
    if (!group) {
      fetchData(id);
    }
  }, [group]);

  if (loading || !group || !devices) {
    return <Loader />;
  }

  return (
    <Box>
      <DeviceGroupSearchProvider>
        <DeviceGroupSearchConsumer>
          {({ remove, removeDevice }) => (
            <>
              {showDeleteDevice ? (
                <DeleteDialog
                  setShow={setShowDeleteDevice}
                  show={showDeleteDevice}
                  deleteId={selectedId}
                  deleteFunction={callback}
                />
              ) : (
                ""
              )}
              {showDelete ? (
                <DeleteDialog
                  setShow={setShowDelete}
                  show={showDelete}
                  deleteId={id}
                  deleteFunction={(id) => {
                    navigate("/groups", { replace: true });
                    remove(id);
                  }}
                />
              ) : (
                ""
              )}
              <Typography variant="h3" sx={{ display: "flex" }}>
                {t("group")}: {group.attributes.name}{" "}
                <Box sx={{ marginLeft: "auto" }}>
                  <Tooltip title={t("edit_group")}>
                    <Button
                      as={Link}
                      to={`/groups/${id}/edit`}
                      sx={{ verticalAlign: "middle" }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title={t("delete_group")}>
                    <Button
                      onClick={() => {
                        setShowDelete(true);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </Typography>
              <Typography sx={{ marginBottom: 2, marginTop: 2 }}>
                {group.attributes.description}
              </Typography>
              <Divider sx={{ marginBottom: 5 }} />
              <Typography variant="h5" sx={{ display: "flex" }}>
                {t("devices")}
                <Box sx={{ marginLeft: "auto" }}>
                  <Tooltip title={t("associate_new_devices")}>
                    <Button
                      as={Link}
                      to={`/groups/${id}/add_device`}
                      endIcon={<AddCircleOutlineIcon />}
                    >
                      {t("add_devices")}
                    </Button>
                  </Tooltip>
                </Box>
              </Typography>
              <Box sx={{ marginBottom: 3 }}>
                <IwdTable
                  columns={columns(removeDevice)}
                  loading={loading}
                  data={devices}
                  onPagePrev={onPagePrev}
                  onPageNext={onPageNext}
                  onChangePage={onChangePage}
                  page={page}
                  pages={pages}
                  count={devices.meta.count}
                />
              </Box>
            </>
          )}
        </DeviceGroupSearchConsumer>
      </DeviceGroupSearchProvider>
    </Box>
  );
};

export default DeviceGroup;
