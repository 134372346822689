import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import { useDeviceDistrict } from "../DeviceDistrictProvider";
import List from "@mui/material/List";
import { actions } from "../../redux/slices/deviceDistrictSlice";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Loader from "../Loader";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteDialog from "../DeleteDialog";
import SpeedIcon from "@mui/icons-material/Speed";
import { Tooltip } from "@mui/material";
import { geoJsonPolygonSwapCoordinates } from "../../utils";
import theme from "../../theme";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useDownloadDevicesMutation } from "../../redux/slices/deviceDistrictApi";
import { useLazyGetPolygonDevicesQuery } from "../../redux/slices/deviceDistrictApi";
import DistrictDeviceDownloader from "./DistrictDeviceDownloader";
const squerCss = (item) => {
  return {
    width: 15,
    height: 15,
    backgroundColor: item.prev ? item.prev : item.pathOptions.color,
    marginLeft: 3,
    border: "2px solid rgba(0, 0, 0, 0.87)",
  };
};

const PolygonListText = ({ labelId, item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {labelId} <Box sx={squerCss(item)} />
    </Box>
  );
};
const colors = ["lime", "red", "purple", "yellow", "orange"];
const PolygonsList = ({ polygonFetching }) => {
  const dispatch = useDispatch();
  const [downloadDevices] = useDownloadDevicesMutation();
  const [freeColor, setFreecolor] = useState();
  const { t } = useTranslation();
  const [getPolygonDevices] = useLazyGetPolygonDevicesQuery();
  /*   const { polygons, removePolygon, setHighlight, getDevices, downloadDevices } =
    useDeviceDistrict(); */
  const polygons = useSelector((state) => state?.deviceDistrictSlice?.polygons);
  const newPolygonsInUpdate = useSelector(
    (state) => state?.deviceDistrictSlice?.newPolygonsInUpdate
  );
  const polygonsToRemove = useSelector(
    (state) => state?.deviceDistrictSlice?.polygonsToRemove
  );

  useEffect(() => {
    const busyColors = [
      ...polygons.map((item) => item.pathOptions.color),
      ...newPolygonsInUpdate.map((item) => item.pathOptions.color),
    ];
    const freeColors = colors.filter((item) => !busyColors.includes(item));

    /* const color = Math.floor(Math.random() * freeColors.length); */
    dispatch(actions.setFreeColor(freeColors[0]));
  }, [polygons, newPolygonsInUpdate]);
  useEffect(() => {
    console.log("freeColor", freeColor);
  }, [freeColor]);

  const [show, setShow] = useState(false);
  const [downloadData, setDownloadData] = useState();
  const [currentItem, setCurrentItem] = useState();

  const allPolygons = [...polygons, ...newPolygonsInUpdate];
  const askForDeletePolygon = (item) => {
    setCurrentItem(item);
    setShow(true);
  };

  const deletePolygon = (item) => {
    dispatch(actions.removePolygon(item));
  };

  const setHighlight = (pol) => {
    const tmp = [...polygons].filter((item) => item !== pol);
    const newPol = { ...pol, pathOptions: { color: "gray" } };
    const newPolygons = [newPol, ...tmp];
    console.log("pol", pol);
    console.log("tmp", tmp);
    console.log("newPol", newPol);
    console.log("newPolygons", newPolygons);
    dispatch(actions.setHigLight(newPolygons));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DistrictDeviceDownloader />
      {show ? (
        <DeleteDialog
          show={show}
          setShow={setShow}
          deleteId={currentItem}
          deleteFunction={deletePolygon}
        />
      ) : (
        <></>
      )}
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {!polygonFetching ? (
          allPolygons.map((item, index) => {
            const labelId = `${t("polygon")}-${index + 1}`;

            return (
              <Box display={"flex"} alignItems="center">
                <ListItem
                  key={index}
                  disabled={polygonsToRemove.includes(item)}
                  secondaryAction={
                    <>
                      {item.polygon.geom && (
                        <Tooltip title={t("download_devices")}>
                          <IconButton
                            disabled={polygonsToRemove.includes(item)}
                            edge="end"
                            aria-label="comments"
                            onClick={() => {
                              dispatch(
                                actions.setDevicesToDownload(
                                  geoJsonPolygonSwapCoordinates(
                                    item.polygon.geom
                                  )
                                )
                              );
                            }}
                          >
                            <CloudDownloadIcon
                              color={item.active ? "primary" : "action"}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {item.polygon.geom && (
                        <Tooltip title={t("show_devices")}>
                          <IconButton
                            disabled={polygonsToRemove.includes(item)}
                            edge="end"
                            aria-label="comments"
                            onClick={() => {
                              const params = geoJsonPolygonSwapCoordinates(
                                item.polygon.geom
                              );
                              dispatch(actions.setPolygonName(labelId));
                              getPolygonDevices(params);
                            }}
                          >
                            <SpeedIcon
                              sx={{
                                fill: theme.palette.primary.main,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {item.polygon.geom && (
                        <Tooltip title={t("go_to_polygon")}>
                          <IconButton
                            edge="end"
                            disabled={polygonsToRemove.includes(item)}
                            aria-label="comments"
                            onClick={() =>
                              dispatch(
                                actions.setPoligonCoordinates(item.positions)
                              )
                            }
                          >
                            <VisibilityIcon
                              color={item.active ? "primary" : "action"}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title={t("delete_polygon")}>
                        <IconButton
                          disabled={polygonsToRemove.includes(item)}
                          edge="end"
                          aria-label="comments"
                          onClick={() =>
                            dispatch(
                              actions.removePolygon({
                                index: index,
                                item: item,
                              })
                            )
                          }
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} dense>
                    <ListItemText
                      id={labelId}
                      primary={
                        <PolygonListText labelId={labelId} item={item} />
                      }
                    ></ListItemText>
                  </ListItemButton>
                </ListItem>
                {polygonsToRemove.includes(item) && (
                  <IconButton
                    onClick={() => dispatch(actions.discardRemoving(item))}
                  >
                    <NotInterestedIcon color="error" />
                  </IconButton>
                )}
              </Box>
            );
          })
        ) : (
          <Box display={"flex"} justifyContent="center" sx={{ mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </List>
    </Box>
  );
};

export default React.memo(PolygonsList);
