import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DeviceGroupForm from "../../pages/device_groups/DeviceGroupForm";
import { Button } from "@mui/material";
import { useDeviceGroup } from "../DeviceGroupProvider";
import Loader from "../Loader";
import { useDeviceGroupSearch } from "./DeviceGroupSearchProvider";

const DeviceGroupEditForm = ({ groupId }) => {
	const { group: formGroup, submit, setGroup } = useDeviceGroup();
	const { group, loading, error, getGroup, update } = useDeviceGroupSearch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const handleUpdate = async (submitForm) => {
		const result = await update(groupId, submitForm);
		setGroup(result.data.attributes);
		navigate(`/groups/${groupId}`);
	};

	useEffect(() => {
		if (!group) {
			getGroup(groupId);
		} else {
			setGroup(group.data.attributes);
		}
	}, [group]);
	if (loading || !group || !formGroup.name) {
		return <Loader />;
	}
	return (
		<Box>
			<DeviceGroupForm onSubmit={handleUpdate} />
			<Button onClick={submit}> {t("update")}</Button>
		</Box>
	);
};

export default DeviceGroupEditForm;
