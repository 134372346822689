import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Switch,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";

const WaterMeterShow = ({ device, attributes, categories }) => {
  const { t } = useTranslation();

  if (
    device == null ||
    categories == null ||
    categories === "" ||
    categories["base"] == null
  ) {
    return <Box>{t("Loading...")}</Box>;
  }

  var cardStyle = {
    display: "block",
    minWidth: "10vw",
    transitionDuration: "0.3s",
    height: "9vw",
    backgroundColor: "#e8eaf6",
  };

  const deviceFields = device["data"].attributes.fields;
  const deviceAttributes = attributes;

  const AlarmItemIcon = ({ value, ...attrs }) => {
    if (value == 0) {
      return <DoneIcon {...attrs} color="success"></DoneIcon>;
    }
    return <WarningAmberIcon {...attrs} color="warning"></WarningAmberIcon>;
  };

  const renderEntries = (name, object) => {
    if (name == "alarm_register") {
      return (
        <Grid item>
          <Card sx={cardStyle}>
            <CardContent>
              <Typography variant="body2" align="center" color="text.secondary">
                {t(name)}
              </Typography>
            </CardContent>
            <CardContent sx={{ height: "80%", overflow: "scroll" }}>
              <ListItem>
                <ListItemAvatar>
                  <AlarmItemIcon value={deviceFields[name].backflow_is} />
                </ListItemAvatar>
                <ListItemText
                  primary="backflow"
                  // secondary={deviceFields[name].backflow_is}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <AlarmItemIcon value={deviceFields[name].magnetic_fraud_is} />
                </ListItemAvatar>
                <ListItemText
                  primary="magnetic fraud"
                  // secondary={deviceFields[name].magnetic_fraud_is}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <AlarmItemIcon
                    value={deviceFields[name].mechanical_fraud_is}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="mechanical fraud"
                  // secondary={deviceFields[name].mechanical_fraud_is}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <AlarmItemIcon value={deviceFields[name].meter_reversed_is} />
                </ListItemAvatar>
                <ListItemText
                  primary="meter reversed"
                  // secondary={deviceFields[name].meter_reversed_is}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <AlarmItemIcon value={deviceFields[name].no_consumption_is} />
                </ListItemAvatar>
                <ListItemText
                  primary="no consumption"
                  // secondary={deviceFields[name].no_consumption_is}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <AlarmItemIcon
                    value={deviceFields[name].suspected_leakage_is}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="leak"
                  // secondary={deviceFields[name].suspected_leakage_is}
                />
              </ListItem>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    if (name == "coordinates") {
      return (
        <Grid item>
          <Card sx={cardStyle}>
            <CardContent>
              <Typography variant="body2" align="center" color="text.secondary">
                {t(name)}
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" align="center">
                {" "}
                lat: {object.coordinates[1].toFixed(5)}{" "}
              </Typography>
              <Typography variant="h5" align="center">
                {" "}
                lon: {object.coordinates[0].toFixed(5)}{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    if (name == "time_zone") {
      return (
        <Grid item>
          <Card sx={cardStyle}>
            <CardContent>
              <Typography variant="body2" align="center" color="text.secondary">
                {t(name)}
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h4" align="center">
                {" "}
                UTC + 2{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    if (object && object.date != null && object.value != null) {
      const unit = object.unit ? object.unit : "m3";
      const value = object.value ? object.value.toFixed(2) : "-";
      return (
        <Grid item>
          <Card sx={cardStyle}>
            <CardContent gutterBottom="false">
              <Typography variant="body2" align="center" color="text.secondary">
                {t(name)}
              </Typography>
            </CardContent>
            <CardContent gutterBottom="false">
              <Typography variant="h4" align="center">
                {" "}
                {value} {unit}
              </Typography>
            </CardContent>
            <CardContent gutterBottom="false">
              <Typography align="right" variant="body2" color="text.secondary">
                {" "}
                {object.date}{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    if (deviceFields[name]) {
      return (
        <Grid item>
          <Card sx={cardStyle}>
            <CardContent>
              <Typography variant="body2" align="center" color="text.secondary">
                {t(name)}
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h4" align="center">
                {" "}
                {deviceFields[name]}{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }

    return (
      <Grid item>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography variant="body2" align="center" color="text.secondary">
              {t(name)}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h4" align="center" color="text.secondary">
              {" "}
              -{" "}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderSwitch = ({ name, category, type }) => {
    if (category !== "base") return;
    if (name === "device_id") return;
    if (name === "serial_number") return;
    if (name === "key") return;

    switch (type) {
      case "string":
      case "map":
        return renderEntries(name, deviceFields[name]);
      case "boolean":
        // const custom = deviceFields[name]
        //   ? Object.assign({}, cardStyle, { bgcolor: "warning.main" })
        //   : cardStyle;

        return (
          <Grid item>
            <Card sx={cardStyle}>
              <CardContent>
                <Typography
                  variant="body2"
                  align="center"
                  color="text.secondary"
                >
                  {t(name)}
                </Typography>
              </CardContent>
              <CardContent align="center">
                <AlarmItemIcon
                  sx={{ fontSize: 50 }}
                  value={deviceFields[name]}
                />

                {/* <Switch disabled={true} checked={deviceFields[name]} /> */}
              </CardContent>
            </Card>
          </Grid>
        );
      default:
        return (
          <Grid item>
            <Card style={cardStyle}>
              <CardContent>
                <Typography variant="body2">{name}</Typography>
                <Typography variant="h7" component="div">
                  {deviceFields[name]
                    ? JSON.stringify(deviceFields[name])
                    : "-"}
                </Typography>
              </CardContent>
              <CardContent gutterBottom="false">
                <Typography
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  {" "}
                  -{" "}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
    }
  };

  const meterData = deviceAttributes?.map((element) => {
    return renderSwitch(element.attributes);
  });

  return (
    <Box sx={{ flexGrow: 1, flexWrap: "wrap" }}>
      <Grid container alignItems="stretch" spacing={2}>
        {meterData}
      </Grid>
    </Box>
  );
};

export default WaterMeterShow;
