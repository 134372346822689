import { createSlice } from "@reduxjs/toolkit";
import { digitalTwinApi } from "./digitalTwinApi";
const initialState = {
  digitalTwin: null,
  digitalTwins: null,
  digitalTwinShow: null,
  digitalTwinShowAttributes: null,
};

const digitalTwinSlice = createSlice({
  name: "digitalTwinSlice",
  initialState,
  reducers: {
    setDigitalTwin(state, action) {
      state.digitalTwin = action.payload;
    },

    unSetDigitalTwin(state) {
      state.digitalTwin = null;
    },
    setDigitalTwinAttributes(state, action) {
      debugger;
      state.digitalTwinShowAttributes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      digitalTwinApi.endpoints.getDigitalTwins.matchFulfilled,
      (state, action) => {
        state.digitalTwins = action.payload.data;
      }
    );
    builder.addMatcher(
      digitalTwinApi.endpoints.getDigitalTwinShow.matchFulfilled,
      (state, action) => {
        state.digitalTwinShow = action.payload.data;
      }
    );
  },
});

export const { setDigitalTwin, unSetDigitalTwin, setDigitalTwinAttributes } =
  digitalTwinSlice.actions;
export default digitalTwinSlice.reducer;
